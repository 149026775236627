import React, { useState, useEffect } from 'react';
import styles from './PageListEdit.module.scss';
import PageDialog from 'components/dialogs/PageDialog';
import PageAmountInput from 'components/inputs/PageAmountInput';
import { useTranslation } from 'react-i18next';

interface Props {  
  label?: string;
  amount?: any; 
  show: boolean;
  onCommit?: any;
  onCancel?: any;
  item?: any;
}
/**
 * UI for making modification to the PageList item's amount value. The user can enter new amout value for item selected.
 * On item modification following object is returned
 * {
 *  item: original item
 *  value: {
 *      value: new amount in cents entered by user
 *      text: textual representation of the new amount entered by user
 *    }
 * }
 * @param props.label Label presented on top of the edit input when item's edit is requested 
 * @param props.amount Initial amount value presented when edit input is shown
 * @param props.item Initial/Original item which will be edited
 * @param props.show when true then edit controlls are presented
 * @param props.onCommit callback triggered when user requested to save item modifications, following dto is passed on with callback {item: original item object, value: { text: textual representation of new value, value: number representation of new value in cents}}
 * @param props.onCancel callback triggered when user requested to cancel item modifications
 */
const PageListEdit = (props: Props) => {
  const { t } = useTranslation();
  const { amount, show, label, onCommit, onCancel, item} = props;      
  const [value, setValue] = useState();
    
  const onChange = (value: any) => {    
    // console.log('Got', value.text, value.value);
    setValue(value);       
  };
  /**
   * Calls callback on change save
   */
  const commitChange = () => {    
    // console.log('Commiting', value);
    var dto = {
      item: item,
      value: value
    }
    if(onCommit)
      onCommit(dto);
  };

  const cancel = () => {    
    // console.log('Cancelling', value);

    if(onCancel)
      onCancel(value);
  };

  return (<>
    <PageDialog open={show} actionA={t('settle.save')} intro={t('settle.editIntro')} actionB={t('settle.cancel')} onActionB={cancel} onActionA={commitChange} title={t('settle.editTitle')}>
      <PageAmountInput label={label} onChange={onChange} initial={amount}></PageAmountInput>
    </PageDialog>
    </>
  );
};

export default PageListEdit;
