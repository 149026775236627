import React, {useState} from 'react';
import styles from './PageInputTitle.module.scss';


interface Props {
  title?: string;
  firstOnPage?: boolean;  
  subtitle?: string;
}

/**
 * PayTip styled input title component, used as a label for paytip input compontents
 */
const PageInputTitle = (props: Props) => {  
  
  const {title, firstOnPage, subtitle} = props;  
    
  return (
    <>
    {title ? firstOnPage ?  <h1 className="title title__marginTitle mt-30">{title}</h1> :  <h1 className="title mt-30">{title}</h1> : null} 
    {subtitle ? <p className={`${styles.PageInputTitle_subTitle} mt-0`}>{subtitle}</p> : null}
    </>
  );
};

export default PageInputTitle;
