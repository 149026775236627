import { withStyles } from '@material-ui/core/styles';
import { ExtendButtonBase } from '@material-ui/core/ButtonBase';

export const ButtonCircleStyle = (component: ExtendButtonBase<any>) => withStyles({
  root: {
    borderRadius: '50%',
    width: '68px',
    height: '68px',
    background: 'linear-gradient(130deg, #84949C, #064789)',
    boxShadow: 'none',
  },
})(component);
