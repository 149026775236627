import React, { useState, useEffect, useContext, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import UserConsumer from 'context/user/User';
import { useTranslation } from 'react-i18next';
import styles from './PassChange.module.scss';
import SpinnerSmall from 'components/layout/spinner/SpinnerSmall';
import PageTitle from 'components/layout/pagetitle/PageTitle';
import PageInputTitle from 'components/layout/cta/PageInputTitle'
import PageInputPassword from 'components/layout/cta/PageInputPassword';
import PageButton from 'components/layout/cta/PageButton';
import NotificationReopenable from 'components/notification/NotificationReopenable'
import Loading from 'components/loading/Loading';
import { BackendApp } from 'libs/App';

// Initialize backend methods
const backendLib = BackendApp();

interface ContextProps {
  currentUser: {
    userId: string,
    as: string,
    tipBoxName: string,
    singleModule: boolean,
  };
}

const PassChange = (props: RouteComponentProps) => {
  const userConsumer = useContext<Partial<ContextProps>>(UserConsumer);
  const { currentUser: { userId, as, singleModule, tipBoxName } }: any = userConsumer;
  const { t } = useTranslation();

  const [newPassword, setNewPassword] = useState<string>('');
  const [newPassword2, setNewPassword2] = useState<string>('');
  const [oldPassword, setOldPassword] = useState<string>('');

  const [newPasswordValid, setNewPasswordValid] = useState<boolean>(false);
  const [newPassword2Valid, setNewPassword2Valid] = useState<boolean>(false);
  const [oldPasswordValid, setOldPasswordValid] = useState<boolean>(false);
  

  const [changeSuccess, setChangeSuccess] = useState<boolean>(false);
  const [dataEntered, setDataEntered] = useState<boolean>(false);
  const [passMismatch, setPassMismatch] = useState<boolean>(false);
  const [updatePassError, setUpdatePassError] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);

  const passEntered1 = (pass:string) => {
    setNewPassword(pass);    
  };
  const passEntered2 = (pass:string) => {
    setNewPassword2(pass);    
  };

  const passOldEntered = (pass:string) => {
    setOldPassword(pass);    
  };
  
  const validityChanged = (validationResult:boolean, name:string) =>{
    if(name == 'pass1' ){
      setNewPasswordValid(validationResult)
    }else if (name == 'pass2' ){
      setNewPassword2Valid(validationResult)
    }else{
      setOldPasswordValid(validationResult)
    }  
  };

  const changePassword = () =>{
    setProcessing(true); 
    backendLib.userChangePassword(oldPassword, newPassword)
    .then(()=>{         
      setChangeSuccess(true);
      setNewPassword('');
      setNewPasswordValid(false);
      setNewPassword2('');
      setNewPassword2Valid(false);
      setOldPassword('');
      setOldPasswordValid(false);

    })
    .catch(()=>{
      setUpdatePassError(true);
      setOldPassword('');
      setOldPasswordValid(false);
    })
    .finally(()=>{
      setProcessing(false); 
    })
  };

  useEffect(() => {
    if(newPasswordValid&&newPassword2Valid && oldPasswordValid && newPassword == newPassword2){
      setPassMismatch(false)
      setDataEntered(true)
    }      
    else  
      setDataEntered(false)

    if(!changeSuccess && newPasswordValid&&newPassword2Valid && newPassword != newPassword2)
      setPassMismatch(true)    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPassword, newPassword2, newPasswordValid, newPassword2Valid, oldPasswordValid]);



  return (
    <div className={`${styles.PassChange_wrapper}`}>

      <div className="container">
        <PageTitle title={t('profile.changePass.title')} subTitle={t('profile.changePass.subtitle')}></PageTitle>
        <PageInputTitle title={t('profile.changePass.oldPassTitle')}></PageInputTitle>
        <PageInputPassword value={oldPassword} placeholder={t('profile.changePass.oldPassPlaceholder')} required={true} label={t('profile.changePass.passOldLabel')} minLength={1} onValueChanged={passOldEntered} onValidityChanged={validityChanged} name='oldpass'></PageInputPassword>
        <PageInputTitle title={t('profile.changePass.newPassTitle')}></PageInputTitle>
        <PageInputPassword value={newPassword} placeholder={t('profile.changePass.placeholder')} required={true} label={t('profile.changePass.pass1Label')} minLength={8} onValueChanged={passEntered1} onValidityChanged={validityChanged} name='pass1'></PageInputPassword>
        <PageInputPassword value={newPassword2} placeholder={t('profile.changePass.placeholder')} required={true} label={t('profile.changePass.pass2Label')} minLength={8} onValueChanged={passEntered2} onValidityChanged={validityChanged} name='pass2'></PageInputPassword>                
        <PageButton disabled={!dataEntered} label={t('profile.changePass.ctaLabel')} onClick={changePassword}></PageButton>
        <NotificationReopenable htmlMsg={t('profile.changePass.saveOK')} show={changeSuccess} type="save" autoHideDuration={6000} onClose={()=>{setChangeSuccess(false)}}></NotificationReopenable>
        <NotificationReopenable htmlMsg={t('profile.changePass.passMismatch')} show={passMismatch} type="error" autoHideDuration={6000} onClose={()=>{setPassMismatch(false)}}></NotificationReopenable>
        <NotificationReopenable htmlMsg={t('profile.changePass.changeError')} show={updatePassError} type="error" autoHideDuration={6000} onClose={()=>{setUpdatePassError(false)}}></NotificationReopenable>
      </div>
      {processing && <Loading />}
    </div>
  );
};

export default PassChange;
