import React from 'react';
import SpinnerLarge from 'components/layout/spinner/SpinnerLarge';
import styles from './LoadingBlik.module.scss';

interface Props {
  msg: string
}
/**
 * @deprecated Use components/blik/BLIKSpinner instead
 * @param props 
 */
const LoadingBlik = (props: Props) => {

  const { msg } = props;

  return (
    <div className={styles.loadingBlik}>
      <div className={styles.loadingBlik_wrapper}>
      <div className={styles.loadingBlik_blikInfoBox}>{msg}</div>
        <SpinnerLarge />
      </div>
    </div>
  );
};

export default LoadingBlik;
