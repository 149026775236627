import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import promoTipLogo from 'assets/promotip_logo.png';
import facebookLogo from 'assets/paytip_fb.png';
import ytLogo from 'assets/paytip_YT.png';
import linkedInLogo from 'assets/paytip_In.png';
import instaLogo from 'assets/paytip_insta.png';
import styles from './PromoTip.module.scss';
import { innerHTMLTranslation } from 'utils/utils';

const PromoTip = () => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState<any>([false, false, false]);

  const handleExpander = (item: number) => {
    setExpanded(() => {
      expanded[item] = !expanded[item];
      return [...expanded];
    });
  };

  return (
    <div className="background_gradient background_gradient__gray">
      <div className={`container ${styles.promoTip}`}>
        <div className="title_log">
          {t('welcome.title')}
        </div>
        {/* <img
          className={styles.promoTip_logo}
          src={promoTipLogo}
          alt="PromoTip logo"/
          > */}
        <img className={`${styles.promoTip_logo}`} src={require(`assets/flame_transparent.svg`)} alt=""/>
        <div className={styles.promoTip_description}>
          <div className={styles.promoTip_smallTitle}>{t('howToUse.getPackage')}</div>
          <div 
            className={styles.promoTip_content}
            dangerouslySetInnerHTML={innerHTMLTranslation(expanded[0] ? t('howToUse.getPackageText') : t('howToUse.getPackageIntro'))}
          />
          <div
            className={styles.promoTip_expander}
            onClick={() => handleExpander(0)}
          >
            {expanded[0] ? t('less') : t('more')}
          </div>
        </div>
        <div className={styles.promoTip_description}>
          <div className={styles.promoTip_smallTitle}>{t('howToUse.distributePackage')}</div>
          <div
            className={styles.promoTip_content}
            dangerouslySetInnerHTML={innerHTMLTranslation(expanded[1] ? t('howToUse.distributePackageText') : t('howToUse.distributePackageIntro'))}
          />
          <div
            className={styles.promoTip_expander}
            onClick={() => handleExpander(1)}
          >
            {expanded[1] ? t('less') : t('more')}
          </div>
        </div>
        <div className={styles.promoTip_description}>
          <div className={styles.promoTip_smallTitle}>{t('howToUse.share')}</div>
          <div
            className={styles.promoTip_content}
            dangerouslySetInnerHTML={innerHTMLTranslation(expanded[2] ? t('howToUse.shareIntroText') : t('howToUse.shareIntro'))}
          />
          <div
            className={styles.promoTip_expander}
            onClick={() => handleExpander(2)}
          >
            {expanded[2] ? t('less') : t('more')}
          </div>
        </div>
        {/* <div className={styles.promoTip_socialLinks}>
          <a
            className={styles.promoTip_socialLinks__link}
            href="https://www.facebook.com/paytipapp"
          >
            <img 
              className={styles.promoTip_socialLinks__image}
              src={facebookLogo}
              alt="PayTip Facebook"
            />
          </a>
          <a
            className={styles.promoTip_socialLinks__link}
            href="https://www.youtube.com/channel/UCsLnWdUu4vDIkFE8TwHS5xA"
          >
            <img
              className={styles.promoTip_socialLinks__image}
              src={ytLogo}
              alt="PayTip YouTube"
            />
          </a>
          <a
            className={styles.promoTip_socialLinks__link}
            href="https://www.linkedin.com/company/paytipapp/"
          >
            <img
              className={styles.promoTip_socialLinks__image}
              src={linkedInLogo}
              alt="PayTip LinkedIn"
            />
          </a>
          <a
            className={styles.promoTip_socialLinks__link}
            href="https://www.instagram.com/paytipapp/"
          >
            <img
              className={styles.promoTip_socialLinks__image}
              src={instaLogo}
              alt="PayTip Instagram"
            />
          </a>
        </div> */}
      </div>
    </div>
  );
};

export default PromoTip;
