import React, { memo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'qrcode';
import styles from './Invite.module.scss';
import Select from '@material-ui/core/Select';
import { SelectStyle } from 'components/override_styles/SelectField';

import * as firebase from 'firebase/app';
import 'firebase/database';
import SpinnerSmall from 'components/layout/spinner/SpinnerSmall';

// Overridden material styles
const CssSelect = SelectStyle(Select);

interface Props {
  userId: string;
}

const Invite = (props: Props) => {
  const { userId } = props;
  const { t } = useTranslation();

  const [list, setList] = useState<object[] | undefined>([]);
  const [qrCode, setQrCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const getTipboxList = () => {
    firebase.database().ref(`/userShared/${userId}`).orderByChild('st').on('value', (snapshot: any) => {
      const resultArray: object[] = [];
      if (snapshot.val()) {
        snapshot.forEach((child: any) => {
          resultArray.push(child.val());
        });
      }
      setList(resultArray);
    });
  };

  const generateQRCode = (e: React.ChangeEvent<{ value: unknown }>) => {
    const tipBoxId = e.target.value;
    if (tipBoxId) {
      let url = '';
      if (process.env.NODE_ENV === 'development') {
        url = `localhost:3000/tipbox-invite?invitationCode=${tipBoxId}`;
      } else {
        url = `https://${window.location.hostname}/tipbox-invite?invitationCode=${tipBoxId}`;
      }
      console.log('URL:', url);

      setLoading(true);
      QRCode.toDataURL(url)
        .then((code: string) => {
          setLoading(false);
          setQrCode(code);
        })
        .catch(() => {
          setLoading(false);
          setError(true);
        });
    } else {
      setQrCode('');
      setLoading(false);
    }
  };

  useEffect(() => {
    getTipboxList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.invite_wrapper}>
      <h2 className="title title__small mb-10">{t('selectTipBox')}</h2>
      <CssSelect
        native
        fullWidth
        variant="outlined"
        onChange={generateQRCode}
      >
        <option value="">{t('empty')}</option>
        {
          list && list.map((el: any) => (
            <option key={el.s} value={el.s}>{el.l}</option>
          ))
        }
      </CssSelect>
      <div className="mt-15 mb-15">
        {t('tipBoxInviteInfo')}
      </div>
      <div className={`${styles.invite_qrCodeWrapper}`}>
        {
          loading ? <SpinnerSmall /> : !error ? <img className={`${styles.invite_qrcode}`} src={qrCode} alt=""/> : <h1 className="title title__err">{t('errMessage')}</h1>
        }
      </div>
    </div>
  );
};

export default memo(Invite);
