import React, { useState, useEffect, useContext } from 'react';
import UserConsumer from 'context/user/User';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { ButtonBase } from '@material-ui/core';
import styles from './TipCode.module.scss';
import { useTranslation } from 'react-i18next';
import { BackendApp } from 'libs/App';
import SpinnerSmall from 'components/layout/spinner/SpinnerSmall';
import PrintFullPage from 'views/print_fullpage/Print_fullpage';
import PrintMultiCards from 'views/print_multicards/Print_multicards';
import {innerHTMLTranslation} from 'utils/utils'
import CopyToClipboard from 'react-copy-to-clipboard';

import NotificationReopenable from 'components/notification/NotificationReopenable';

import Button from '@material-ui/core/Button';
import { ButtonStyle } from 'components/override_styles/Button';

// Initialize backend methods
const backendLib = BackendApp();

const CssButton = ButtonStyle(Button);

type ContextProps = {
  currentUser: {
    receiverName: null | string,
  },
};

const TipCode = () => {
  const userConsumer = useContext<Partial<ContextProps>>(UserConsumer);
  const { currentUser: { receiverName, userId } }: any = userConsumer;
  const { t } = useTranslation();

  const [qrCode, setQrCode] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [pdfGenerationDelay, setPdfGenerationDelay] = useState(false);
  const [qrURL, setQrURL] = useState<string>('');  
  const [urlCopied, setUrlCopied] = useState<boolean>(false);

  useEffect(() => {
    generateQRCode();
  }, []);

  useEffect(() => {
    // Prevent generating PDFDownloadLink in the same time
    const delayTimeout = setTimeout(() => setPdfGenerationDelay(true), 3000);
    return () => {
      clearTimeout(delayTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qrCode]);

  const generateQRCode = () => {
    let hostname = '';
    if (process.env.NODE_ENV === 'development') {
      hostname = `localhost:3000/donatio`;      
      setQrURL(hostname+'?r='+userId)
    } else {
      hostname = `https://${window.location.hostname}/donatio`;      
      setQrURL(hostname+'?r='+userId)
    }

    backendLib.generateQRCode(hostname, null, null, null)
      .then(({ u }: any) => {
        setLoading(false);
        setQrCode(u); 
               
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  };

  const onNotificationClose = () => {
    setUrlCopied(false)      
  };

  return (
    <div className={`${styles.tipCode_wrapper}`}>
      <div className={`container ${styles.tipCode_container}`}>
        <h1 className={`title ${styles.tipCode_title}`}>{t('tipCode')}</h1>

        <div>
          <p className={`${styles.tipCode_subTitle}`} dangerouslySetInnerHTML={innerHTMLTranslation(t('tipCodeIndividualText'))}></p>          
          <div className={`${styles.tipCode_qrCodeWrapper}`}>
            {
              loading ? <SpinnerSmall /> : !error ? <><img src={qrCode} width="115" alt=""/>                             
              </>: <h1 className="title title__err">{t('errMessage')}</h1>
            }
          </div>
          <div>
          <h2 className="title mt-30">{`${t('startingPackage.materials')}`}</h2>
            <p className={`${styles.tipCode_subTitle}`}>{t('startingPackage.instruction')}</p>
            <CssButton
                fullWidth
                className='card__active'
                disabled={false}
                size="large"
                variant="outlined"
                type="button"
                onClick={null}
              >
                {t('startingPackage.download')}
              </CssButton>
          </div>
          <div>
          <h2 className="title mt-30">{`${t('startingPackage.URL')}`}</h2>
            <p className={`${styles.tipCode_subTitle}`}>{t('startingPackage.urlInfo')}</p>
            <CopyToClipboard text={qrURL}
              onCopy={() => setUrlCopied(true)}>              
              <CssButton
                fullWidth
                className='card__active'
                disabled={false}
                size="large"
                variant="outlined"
                type="button"
                onClick={null}
              >
                {t('startingPackage.copy')}
              </CssButton>
            </CopyToClipboard>
            
          </div>
          <NotificationReopenable autoHideDuration={7000} htmlMsg={t('startingPackage.copyConfirm')} type="save" show={urlCopied} onClose={onNotificationClose}/>
        </div>        
      </div>
    </div>
  );
};

export default TipCode;
