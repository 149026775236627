import React, { useState } from 'react';
import styles from './InlineNotification.module.scss';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import {ReactComponent as IconInfo} from 'assets/icon_info.svg';
import withStyles from '@material-ui/styles/withStyles';
import { innerHTMLTranslation } from 'utils/utils';

// TODO: Add conditional types
interface Props {
  msg?: string;
  htmlMsg?: string;
  type: 'update' | 'error' | 'info' | 'save';
  title: string;
}

const InlineNotification = (props: Props) => {
  const { msg, htmlMsg, type, title } = props;
  let typeColor: string;
  let Icon: JSX.Element;

  switch (type) {
    case 'update': {
      typeColor = '#AA4399';
      Icon = <IconInfo />;
      break;
    }
    case 'error': {
      typeColor = '#E9649B';
      Icon = <ErrorIcon />;
      break;
    }
    case 'info': {
      typeColor = '#AA4399';
      Icon = <IconInfo />;
      break;
    }
    case 'save': {
      typeColor = '#2B0096';
      Icon = <CheckCircleIcon />;
      break;
    }
    default: {
      typeColor = '#AA4399';
      Icon = <IconInfo />;
      break;
    }
  }

  const CssIconButton = withStyles({
    root: {
      'padding': '1rem',
      '& svg': {
        fontSize: '2rem',
      },
    },
  })(IconButton);

  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {open && (
        <div className={styles.inlineNotification_wrapper} style={{ borderColor: typeColor}}>
          <div className={styles.inlineNotification_icon} style={{ color: typeColor}}>{Icon}</div>
          <div className={styles.inlineNotification_title} style={{ color: typeColor}}>{title}</div>
          <CssIconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
            style={{color: typeColor}}
          >
            <CloseIcon />
          </CssIconButton>
          {htmlMsg ?
            <div className={styles.inlineNotification_text} dangerouslySetInnerHTML={innerHTMLTranslation(htmlMsg)} />
            :
            <div className={styles.inlineNotification_text}>{msg}</div>
          }
        </div>
      )}
    </>
  );
};

export default InlineNotification;
