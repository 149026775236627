import React, { useState, useEffect } from 'react';
import * as firebase from 'firebase/app';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import LockOutlined from '@material-ui/icons/LockOutlined';
import { InputFieldStyle } from 'components/override_styles/TextField';
import { ButtonStyle } from 'components/override_styles/Button';
import styles from './Reset_password_firebase.module.scss';
import Loading from 'components/loading/Loading';
import { innerHTMLTranslation } from 'utils/utils';

// Overridden material styles
const CssTextField = InputFieldStyle(TextField);
const CssButton = ButtonStyle(Button);

interface Props {
  history: {
    push: (path: string) => void,
  };
}

const ResetPasswordFirebase = (props: Props) => {
  const { t } = useTranslation();
  const { history } = props;
  const [newPassword, setNewPassword] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [loading, setLoading] = useState(true);
  const [resetMsg, setResetMsg] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [resetError, setResetError] = useState(false);

  const url = new URL(window.location.href);
  const mode = url.searchParams.get('mode') || '';
  const oobCode = url.searchParams.get('oobCode') || '';

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNewPassword(event.target.value);
  };

  const handleResetPasswordNew = () => {
    const isCorrectPasswordLength = newPassword.length >= 8;

    setPasswordError(true);
    if (isCorrectPasswordLength) {
      setLoading(true);
      setPasswordError(false);

      firebase.auth().confirmPasswordReset(oobCode, newPassword)
      .then((response) => {
          console.log('reset', response);
          setResetMsg(t('change_password_success'));
      })
      .catch((error) => {
        console.log('Password reset error:', error);
        setResetMsg(error);
        setResetError(true);
      })
      .finally(() => setLoading(false));
    }
  };

  const handleLogin = () => {
    history.push('/login');
  };

  useEffect(() => {
    if (mode === 'resetPassword' && oobCode) {
      setLoading(true);
      firebase.auth().verifyPasswordResetCode(oobCode)
      .then((email) => {
        setUserEmail(email);
      })
      .catch((error) => console.log('error', error))
      .finally(() => setLoading(false));
    } else {
      setLoading(false);
      console.log('No params');
    }
  }, [mode, oobCode]);

  // TODO: Change conditions logic
  return (
    <div className={`${styles.resetPassword_wrapper} background_gradient background_gradient__purpure`}>
      <div className="title_log">
        {t('reset_password_title')}
      </div>
      {
        resetMsg
        ?
        (
          <div className="container">
            <div
              className={styles.resetPassword_info}
              dangerouslySetInnerHTML={innerHTMLTranslation(resetMsg)}
            />
            {
              !resetError && (
                <CssButton
                  fullWidth
                  size="large"
                  variant="outlined"
                  type="button"
                  onClick={handleLogin}
                >
                  {t('login')}
                </CssButton>
              )
            }
          </div>
        )
        :
        (
          <>
          {
          userEmail
          ?
          (
            <form className={`container ${styles.resetPassword_form}`}>
              <div className={styles.resetPassword_info}>
                {userEmail}
              </div>
              <CssTextField
                fullWidth
                autoComplete="off"
                name="password"
                label={t('new_password')}
                helperText={passwordError ? t('error_password') : ''}
                margin="normal"
                variant="outlined"
                type="password"
                InputLabelProps={{shrink: true}}
                InputProps={{startAdornment: (<InputAdornment position="start"><LockOutlined /></InputAdornment>)}}
                onChange={handleChange}
                error={passwordError}
              />
              <CssButton
                fullWidth
                size="large"
                variant="outlined"
                type="button"
                onClick={handleResetPasswordNew}
              >
                {t('save')}
              </CssButton>
            </form>
          )
          :
          (
            <>
            {
              !loading && (
                <div
                  className={`container ${styles.resetPassword_info}`}
                  dangerouslySetInnerHTML={innerHTMLTranslation(t('reset_password_not_valid_link'))}
                />
              )
            }
            </>
          )
          }
          </>
        )
      }
      {loading && <Loading />}
    </div >
  );
};

export default ResetPasswordFirebase;
