import React, { useState, useLayoutEffect, useEffect, useRef, useContext } from 'react';
import UserConsumer from 'context/user/User';
import { useTranslation } from 'react-i18next';
import { calcSummaryInvoiceByPercent, scrollTo } from 'utils/calculates';
import { BackendApp } from 'libs/App';
import styles from './New_tip.module.scss';
import { RouteComponentProps } from 'react-router-dom';
import { amount, ALLOWED_SIGN } from 'utils/validators';
import { PriceInput } from 'components/priceInputFake/PriceInputFake';

import TextField from '@material-ui/core/TextField';
import { InputFieldStyle } from 'components/override_styles/TextField';
import { InputFieldBlikStyle } from 'components/override_styles/TextFieldBlik';
import InputMask from 'react-input-mask';

import Button from '@material-ui/core/Button';
import { ButtonStyle } from 'components/override_styles/Button';

import SingleCard from 'components/SingleCard';

import SpinnerSmall from 'components/layout/spinner/SpinnerSmall';
import ThankYou from 'components/thankYou/ThankYou';

import Fab from '@material-ui/core/Fab';
import { ButtonCircleStyle } from 'components/override_styles/ButtonCircle';
import AddIcon from '@material-ui/icons/Add';
import { checkVersion } from 'utils/utils';
import firebase from 'firebase/app';
import InlineNotification from 'components/inline_notification/InlineNotification';
import LoadingBlik from 'components/loading_blik/LoadingBlik';

import { useVibrating } from 'utils/nativeFunctions';

// Initialize backend methods
const backendLib = BackendApp();

// Overridden material styles
const CssTextField = InputFieldStyle(TextField);
const CssTextFieldBlik = InputFieldBlikStyle(TextField);
const CssButton = ButtonStyle(Button);
const CssButtonCircle = ButtonCircleStyle(Fab);

const iOSversion = checkVersion();

// Declare percent value for cards
const percentOptions = [5, 10, 15];
const heightOfIconCards = 67;

interface ContextProps {
  currentUser: {
    userId: string,
    isLoggedIn: boolean,
    iOS: boolean,
  };
  configuration: {
    tipReceiptMaxValue: number,
    tipReceiptMinValue: number,
  };
}

const NewTip: React.FC<RouteComponentProps> = (props) => {
  const userConsumer = useContext<Partial<ContextProps>>(UserConsumer);
  const {
    currentUser: { userId, iOS },
    configuration: { tipReceiptMaxValue, tipReceiptMinValue},
  }: any = userConsumer;
  const { t } = useTranslation();

  const [summaryInvoice, setSummary] = useState();
  const [tipHeight, setTipHeight] = useState();
  const [tipHeightSection, setTipHeightSection] = useState(false);
  const [activeCard, setActiveCard] = useState('');
  const [blikCode, setBlikCode] = useState();
  const [activePaymentButton, setActivePaymentButton] = useState(false);
  const [thankYouPage, setThankYouPage] = useState(false);

  const [qrCode, setQrCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingBlik, setLoadingBlik] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(t('errMessage'));

  // Reference to DOM object
  const inputValue = useRef<HTMLInputElement>();
  const divRef = useRef<HTMLDivElement>(null);
  const paymentButton = useRef<HTMLButtonElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const blikCodeRef = useRef<HTMLDivElement>(null);
  const [blikProcessFirstStep, setBlikProcessFirstStep] = useState(false);

  // Custom summary invoice input data
  const [checkingPrice, stopCheckingPrice] = useState(false);

  // Init vibrations when user gave a tip
  useVibrating(thankYouPage);

  useLayoutEffect(() => {
    if (paymentButton.current) {
      scrollTo(paymentButton.current.offsetTop);
    }
    if (formRef.current) {
      scrollTo(formRef.current.offsetTop);
    }
  }, [activePaymentButton, qrCode]);

  // Clear blikCode value, when payment method will be changed
  useEffect(() => {
    if (blikCodeRef.current) {
      // @ts-ignore
      blikCodeRef.current.value = '';
    }
  }, [qrCode]);

  useEffect(() => {
    if (summaryInvoice) {
      const checkValue = Math.round(summaryInvoice.toString().replace(',', '.') * 100);
      if (checkValue > tipReceiptMaxValue) {
        setErrorMsg(`${t('errMessage_maxReceipt')} ${tipReceiptMaxValue / 100} zł`);
        setError(true);
      } else if (checkValue < tipReceiptMinValue) {
        setErrorMsg(t(`${t('errMessage_minReceipt')} ${tipReceiptMinValue / 100} zł`));
        setError(true);
      } else {
        setErrorMsg(t('errMessage'));
        setError(false);
      }

      // Validate of input price only if it's a string
      if (typeof summaryInvoice === 'string') {

        // Find the latest character passed in input
        const lastChar = summaryInvoice.charAt(summaryInvoice.length - 1);

        // Replace dot on comma '15.22' -> '15,22'
        setSummary(summaryInvoice.replace(/\./g, ','));

        // Check if character exist in allowed sign
        const existLastCharInAllowedSign = ALLOWED_SIGN.filter((char) => lastChar === char);
        const removeLastChar = summaryInvoice.substr(0, summaryInvoice.length - 1);

        const validateRestValue = () => {
          const rest = summaryInvoice.split(',')[1];
          if (rest && rest.length >= 3) {
            setSummary(removeLastChar);
          }
        };

        // If comma is existing two times, remove last one
        if (summaryInvoice.split(',').length - 1 >= 2) {
          setSummary(removeLastChar);
        }

        if (!existLastCharInAllowedSign.length || summaryInvoice.length === 8) {
          setSummary(removeLastChar);
        }

        // Allow to pass only 2 decimals rest value if price including rest
        if (summaryInvoice.includes(',') || (summaryInvoice.length === 5 && summaryInvoice.includes('.'))) {
          validateRestValue();
        } else if (summaryInvoice.length === 5 && !summaryInvoice.includes(',')) {
          setSummary(removeLastChar);
        }
      }
    }
  }, [summaryInvoice, t, tipReceiptMaxValue, tipReceiptMinValue]);

  // Refresh page on navigate
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => addNewTip(), [props]);

  // Clear input value on Invoice Summary, when user remove price
  const clearInputPrice = () => setSummary('');

  // Dont display section "Tip Height", only 'approved' value is displaying content
  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    setTipHeightSection(false);
    setSummary(e.currentTarget.value);

    if (!e.currentTarget.value.length) {
      clearInputPrice();
    }
  };

  const handleChangeBlik = (e: React.FormEvent<HTMLInputElement>) => {
    const clearValue = e.currentTarget.value.replace(/\s|_/g, '');

    if (clearValue.length === 6) {
      setBlikCode(clearValue);
      setActivePaymentButton(true);
    } else {
      setActivePaymentButton(false);
    }
  };

  // Submit form with currentValue and display next step
  const handleSubmit = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();

    if (typeof summaryInvoice !== 'undefined' && summaryInvoice.length && summaryInvoice.match(amount)) {
      setTipHeightSection(true);
      setSummary(+summaryInvoice.replace(',', '.'));
      stopCheckingPrice(true);

      // Remove style from Invoice Summary input
      if (divRef.current) {
        divRef.current.removeAttribute('style');
      }

    } else {
      setTipHeightSection(false);
    }
  };

  const selectTipCard = (index: number, summaryInv: string, opt: number) => {
    setActiveCard(`option_${index}`);
    setActivePaymentButton(false);
    setBlikCode(undefined);
    setLoading(true);
    setError(false);

    const calcAmount = calcSummaryInvoiceByPercent(summaryInv, opt);
    setTipHeight(calcAmount);

    let hostname = '';
    if (process.env.NODE_ENV === 'development') {
      hostname = `localhost:3000/give`;
    } else {
      hostname = `https://${window.location.hostname}/give`;
    }
    // name,
    // isAmountFixed

    backendLib.generateQRCode(hostname, calcAmount, null, null)
      .then(({ u, m }) => {
        setLoading(false);
        setTipHeightSection(true);
        setQrCode(u);
        console.log('m', m);
      })
      .catch(() => {
        setLoading(false);
        setErrorMsg(t('errMessage'));
        setError(true);
      });
  };

  const walletAccept = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    if (blikCode && blikCode.toString().length === 6) {
      setLoadingBlik(true);
      const tipHeightCent = Math.round(tipHeight * 100);

      if (iOSversion && iOSversion < 1350) {
        console.log('iOS version is less then 13.5.0');
        backendLib.registerPaymentAsync(tipHeightCent, blikCode, userId, null)
        .then((response: any) => {
          console.log('response', response);
          setBlikProcessFirstStep(true);
          const checkPayment = () => {
            firebase.database().ref(`/asyncQ/${response.i}`)
            .on('value', (snapshot: any) => {
              if (snapshot.val()) {
                console.log('snapshot.val()', snapshot.val());
                if (snapshot.val().d.s === 'A') {
                  setLoadingBlik(false);
                  setThankYouPage(true);
                }
                if (snapshot.val().d.s === 'D') {
                  setLoadingBlik(false);
                  setErrorMsg(t('errMessage_blik'));
                  setError(true);
                }
              }
            });
          };

          checkPayment();
        })
        .catch((err: any) => console.log(`Error: ${err}`));
      } else {
        setBlikProcessFirstStep(true);
        // TODO: if provider is B (BLIK), there is error, so temporary passing is null
        backendLib.registerPayment(tipHeightCent, blikCode, userId, null)
        .then(() => {
          setLoadingBlik(false);
          setThankYouPage(true);
        })
        .catch(() => {
          setLoadingBlik(false);
          setErrorMsg(t('errMessage_blik'));
          setError(true);
        });
      }
    }
  };

  const redirectToMyBalance = () => props.history.push('/balance');

  const addNewTip = () => {
    setBlikCode(null);
    setErrorMsg(t('errMessage'));
    setError(false);
    setQrCode('');
    setTipHeightSection(false);
    setThankYouPage(false);
    setActiveCard('');
    stopCheckingPrice(false);
    clearInputPrice();
    setSummary('');
    if (inputValue.current) {
      inputValue.current.value = '';
    }
  };

  const inputSummaryInvoiceHandler = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    if (!tipHeightSection) {
      e.currentTarget.style.border = '1px solid #2B0096';
      if (inputValue.current) {
        inputValue.current.focus();
      }
    }
  };

  // Its remove 'active class' from summary invoice on Blur methods
  const activeClassHandler = (e: { stopPropagation: () => void; }) => {
    e.stopPropagation();
    if (divRef.current && divRef.current.getAttribute('style') !== null) { divRef.current.removeAttribute('style'); }
  };

  const buttonSubmitTip = (tip: boolean, active: boolean) => {
    if (!error && tip && !active) {
      return `${styles.newTip_paymentSectionButton__active}`;
    } else {
      return `${styles.newTip_paymentSectionButton__disabled}`;
    }
  };

  return (
    <div className={`${styles.newTip_wrapper}`} onClick={activeClassHandler}>

      {/* Render thankyou page, when payment was successed */}
      <div style={{ display: thankYouPage ? 'grid' : 'none' }}>
        <ThankYou
          img="jam_jar.svg"
          background="jam_jar_bg.svg"
          text={t('tipWentToYourAccount')}
          clicked={redirectToMyBalance}
          link={t('my_balance')}
        />

        <CssButtonCircle
          color="primary"
          aria-label="add"
          style={{ position: 'absolute', bottom: '3rem', right: '3rem' }}
          onClick={addNewTip}
        >
          <AddIcon style={{ fontSize: '3.5rem' }} />
        </CssButtonCircle>
      </div>

      <div
        className="container"
        style={{
          display: !thankYouPage ? 'grid' : 'none',
          position: 'relative',
        }}
      >
        <h2 className="title title__small">{t('newTip')}</h2>
        <form onSubmit={handleSubmit} className={`${styles.newTip_formTip}`}>
          <CssTextField
            name="invoiceAmount"
            label={t('invoice_amount')}
            placeholder={t('tipPlaceholder')}
            onChange={(event: React.FormEvent<HTMLElement | any>): void => { handleChange(event); }}
            fullWidth
            autoComplete="off"
            margin="normal"
            variant="outlined"
            disabled={tipHeightSection}
            InputLabelProps={{ shrink: true }}
            inputRef={inputValue}
            inputProps={{
              inputMode: iOS ? 'decimal' : 'tel',
              value: summaryInvoice || '',
              type: 'tel',
            }}
          />
        </form>

        <h1 className="title title__marginTitle">{t('invoice_amount')}</h1>

        {/* This field is presenting current Summary Invoice with custom styles */}
        <PriceInput clicked={inputSummaryInvoiceHandler} tipHeight={summaryInvoice} checkingPrice={checkingPrice} reference={divRef} />
        {
          activeCard &&
          <div className={styles.newTip_blikInfo}>
            <InlineNotification
              title={t('information')}
              type="info"
              htmlMsg={t('blik_info_new_tip1')}
            />
          </div>
        }
        {/* Display Button when tip height is not confirm */}
        {
          !tipHeightSection && (
            <CssButton
              fullWidth
              size="large"
              variant="outlined"
              type="button"
              onClick={handleSubmit}
              className={buttonSubmitTip(summaryInvoice, tipHeightSection)}
              disabled={!summaryInvoice || tipHeightSection || error}
            >
              {t('entry')}
            </CssButton>
          )
        }

        { tipHeightSection && (
          <div>
            <h1 className="title">{t('tip_height')}</h1>
            <div className={`${styles.newTip_cardBoxes}`}>
              { percentOptions.map((opt, i) => (
                <SingleCard
                  key={opt}
                  option={`option_${i}`}
                  activeClass={activeCard}
                  icon={`plant_${i + 1}`}
                  height={heightOfIconCards / (percentOptions.length - i)}
                  percentage={opt}
                  cost={summaryInvoice && calcSummaryInvoiceByPercent(summaryInvoice, opt)}
                  clicked={() => selectTipCard(i, summaryInvoice, opt)}
                  disableCard={loading}
                />
              )) }
            </div>
          </div>
        ) }

        {/* User select new value by clicking card, and then is generating new TipCode */}
        <div className={`${styles.newTip_payment}`}>
          {
            loading ? <div className={`${styles.newTip_spinner__small} mt-30`}><SpinnerSmall /></div> :
              qrCode !== '' && !error && <div className={`${styles.newTip_paymentSection}`}>

                <header>
                  <h1 className="title">{t('paymentType')}</h1>
                  <p className={`${styles.newTip_subTitle}`}>{t('writeBlickCodeOrTikCode')}</p>
                </header>

                <form ref={formRef} onSubmit={walletAccept} className={`${styles.newTip_paymentSectionForm}`} noValidate>
                  <div className={`${styles.newTip_paymentSectionBlikCode}`}>
                    <h1 className="title title__smallBold">{t('blikCode')}</h1>
                    <InputMask
                      mask="999 999"
                      onChange={handleChangeBlik}
                    >
                      {
                        () => (
                          <CssTextFieldBlik
                            fullWidth
                            ref={blikCodeRef}
                            autoComplete="off"
                            name="blikCode"
                            placeholder={t('blikCodePlaceholder')}
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            inputProps={{
                              inputMode: iOS ? 'decimal' : 'tel',
                              type: 'tel',
                            }}
                          />
                        )
                      }
                    </InputMask>
                  </div>
                  <div className={`${styles.newTip_paymentSectionTipCode}`}>
                    <h1 className="title title__smallBold">{t('tipCodeNewTip')}</h1>
                    <div className={`${styles.newTip_paymentSectionTipCodeWrapper}`}>
                      <img className={`${styles.newTip_paymentSectionTipCodeWrapperImg}`} src={qrCode} alt=""/>
                    </div>
                  </div>
                  {
                  activePaymentButton &&
                  (
                    <CssButton
                      ref={paymentButton}
                      fullWidth
                      className={`${styles.newTip_paymentSectionButton}`}
                      size="large"
                      variant="outlined"
                      type="submit"
                    >
                      {t('pay')}
                    </CssButton>
                  )
                }
                </form>
              </div>
          }
          {
            error && <h1 className="title title__err">{errorMsg}</h1>
          }
        </div>
      </div>
      {loadingBlik && <LoadingBlik msg={blikProcessFirstStep ? t('blik_info_new_tip2') : t('blik_info_first_step')} />}
    </div>
  );
};

export default NewTip;
