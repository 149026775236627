export const innerHTMLTranslation = (translation: string) => {
  return { __html: translation };
};

export const removeParam = (key: string, sourceURL: string) => {
  let rtn = sourceURL.split('?')[0],
      param,
      params_arr = [],
      queryString = (sourceURL.indexOf('?') !== -1) ? sourceURL.split('?')[1] : '';
  if (queryString !== '') {
      params_arr = queryString.split('&');
      for (let i = params_arr.length - 1; i >= 0; i -= 1) {
          param = params_arr[i].split('=')[0];
          if (param === key) {
              params_arr.splice(i, 1);
          }
      }
      rtn = rtn + '?' + params_arr.join('&');
  }
  return rtn;
};

export const checkVersion = () => {
  const agent = window.navigator.userAgent;
  const start = agent.indexOf('OS ');
  if ((agent.indexOf('iPhone') > -1 || agent.indexOf('iPad') > -1) && start > -1) {
      return window.Number(agent.substr(start + 3, 3).replace('_', ''));
  }
  return 0;
};
