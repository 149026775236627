import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UserConsumer from 'context/user/User';
import { BackendApp } from 'libs/App';

import AccountDataStepper from 'components/account_data_stepper/AccountDataStepper';
import styles from './Account_data.module.scss';
import SpinnerSmall from 'components/layout/spinner/SpinnerSmall';
import { RouteComponentProps } from 'react-router-dom';

// Initialize backend methods
const backendLib = BackendApp();

interface IContextProps {
  currentUser: {
    userId: string,
    email: string,
  };
}

const AccountData = (props: RouteComponentProps) => {
  const { t } = useTranslation();
  const userConsumer = useContext<Partial<IContextProps>>(UserConsumer);
  const { currentUser: { userId, email } }: any = userConsumer;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    backendLib.backendLoadUser(userId).then((userData: any) => {
      setLoading(false);
    });
  }, [userId]);

  return (
    <div className={styles.accountData_stepperWrapper}>
      {loading ? <SpinnerSmall /> : <AccountDataStepper {...props} />}
      <div className={styles.accountData_readOnlyData}>
        <div className={styles.accountData_readOnlyData__label}>{t('email')}</div>
        <div className={styles.accountData_readOnlyData__text}>{email}</div>
      </div>
    </div>
  );
};

export default AccountData;
