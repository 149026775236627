export const en = {
  application_name: 'ofiara.org',
  intro_text: 'Share good with others',
  login_title: 'Login',
  email: 'E-mail',
  password: 'Password',
  email_placeholder: 'Type e-mail',
  password_placeholder: 'Type password',
  login: 'Login',
  forgot_password: 'Don\'t remember password',
  wrong_data: 'Wrong data',
  login_facebook: 'Login via facebook',
  dont_have_account: 'Haven\'t registered on ofiara.org?',
  sign_up: 'Sign up',
  sign_in: 'Sign in',
  reset_password_title: 'Reset password',
  reset_password_help: 'Please enter your email address and we\'ll send you instructions on how to reset your password.',
  reset_password_info: 'Your password reset link has been sent. Please check your mailbox.',
  reset_password: 'Reset password',
  sign_up_title: 'Sign Up',
  sign_up_info: 'Acctivation link has been sent. Please check your mailbox.',
  create_account: 'Create account',
  hello: 'Hello',
  im: 'I\'m',
  terms_accept_text1: 'I hereby accept',
  terms_accept_text2: 'and grant approval to my personal data being processed.',
  terms_accept_link: 'ofiara.org regulations and privacy policy',
  error_email: 'Incorrect email address',
  error_password: 'Min characters length: 8',
  already_have_account: 'Already have an account?',
  logged_out: 'Logged out',
  login_again: 'Log in again',
  newTip: 'How to use?',
  newTipBox: 'New TipBox',
  tip: 'Contribution',
  invoice_amount: 'Invoice amount',
  received_tips: 'Received tips',
  tips_history: 'Donations\' history',
  history: 'History',
  my_balance: 'My balance',
  my_tips: 'Donations\' history',
  day: 'Today',
  week: 'Last week',
  month: 'Month',
  tipCode: 'Starting Package',  
  profile: {
    menu: 'Profile',
    changePass: {
      menu: 'Manage account',
      title: 'Edit account',
      subtitle: 'Change password',
      newPassTitle: 'New password',
      pass1Label: 'Enter password',
      pass2Label: 'Reenter password',
      placeholder: 'min. 8 chars',
      ctaLabel: 'Save',
      saveOK: 'Password saved successfully',
      changeError: 'Error changing password. Provide valid current password and try again.',
      passMismatch: 'New password mismatch. Make sure that reentered password matches new password.',
      oldPassTitle: 'Current password',
      passOldLabel: 'Enter current password',
      oldPassPlaceholder: 'Your current application password'
    }
  },
  editProfile: 'Edit Profile',
  about_payTip: 'About ofiara.org',
  logout: 'Logout',
  tip_height: 'Tip amount',
  currency: 'PLN',
  paymentType: 'Payment',
  writeBlickCodeOrTikCode: 'Enter your BLIK code or scant the TipCode.',
  blikCode: 'BLIK code',
  tipCodeNewTip: 'TipCode',
  blikCodePlaceholder: ' 6 digits',
  tipPlaceholder: 'i.e.: 29.00',
  pay: 'Yes, leave the tip',
  commission: 'Commision',
  commission_promoTip: 'Service maintenance 24/7/365',
  tipWentToYourAccount: 'The tip has been received on your account',
  errMessage: 'Ups, something went wrong. Please try again',
  errMessage_blik: 'Ups, please check if BLIK code was entered correctly and try again',
  errMessage_maxReceipt: 'The maximum bill amount is',
  errMessage_minReceipt: 'The minimum bill amount is',
  errMessage_maxTip: 'The maximum tip ammount is',
  errMessage_minTip: 'The minimum tip amount is',
  errMessage_Payment: 'Ups, transaction rejected. Please verify your limits and <br /><br />try again',
  choosePaymentType: 'Choose payment method',
  giveTip: 'Leave the tip',
  writeBlikCode: 'Enter BLIK code',
  thankYouFortTIP: 'Thank you for your gratitude',
  thankYou: 'Thank You',
  thankYouPaymentRegister: 'Your request is accepted and is being processed.',
  version: 'Version',
  whats_new_in_app: 'What\'s new',
  system_requirements: 'System requirements',
  system_requirements_list: `
    <p>Following requirements must be met in order to use ofiara.org</p>
    <ol style="padding-left: 16px;">
      <li style="margin: 10px 0;">Mobile device with Android or iOS operating system (recommended Android 9.0 and above or iOS 12.2 and above)</li>
      <li style="margin: 10px 0;">Device with up to date mobile browser (Chrome or Safari), accepting SSL secured communication</li>
      <li style="margin: 10px 0;">Secure, wireless internet connection</li>
      <li style="margin: 10px 0;">Photo camera capable of QR scanning or third party application to scan QR codes installed on device</li>
      <li style="margin: 10px 0;">Email account for ofiara.org system messages and/or Facebook account that can be used to access ofiara.org application</li>
      <li style="margin: 10px 0;">Bank account registered in bank or SKOK that operates in the territory of the Republic of Poland</li>
      <li style="margin: 10px 0;">Antivirus software installed on device just in case might be good idea</li>
    </ol>
  `,
  terms: 'Regulations and rules',
  terms_and_rules: 'ofiara.org legal forms and documents',
  terms_text: `
    <div style="margin-bottom: 20px;">
      <div style="display: inline-block; width: 68%;">
        <a style="color: black" href="https://parafia.ofiara.org/documents/Regulamin_OfiaraOrg_-_4.11.2020.pdf">ofiara.org Terms of use</a><br />
        <span style="font-size: 0.9rem">15 czerwca 2020 roku</span>
      </div>
      <div style="display: inline-block; width: 30%; text-align: right;">
        <img height="50" style="vertical-align: sub" src="https://parafia.ofiara.org/img/Adobe-PDF-File-Icon-01.png" />
      </div>
    </div>
    <div style="margin-bottom: 20px;">
      <div style="display: inline-block; width: 68%;">
        <a style="color: black" href="https://parafia.ofiara.org/documents/Polityka_prywatnosci_OfiaraOrg_-_4.11.2020.pdf">Privacy policy</a><br />
        <span style="font-size: 0.9rem">15 czerwca 2020 roku</span>
      </div>
      <div style="display: inline-block; width: 30%; text-align: right;">
        <img height="50" style="vertical-align: sub" src="https://parafia.ofiara.org/img/Adobe-PDF-File-Icon-01.png" />
      </div>
    </div>    
  `,
  tipCodeIndividualText: 'This is your personal QR code. You may download it and share it with anybody to accept the donation.',
  startingPackage: {
    materials: 'Starting package (download)',
    download: 'Send to my email',
    URL: 'Donation acceptance tip',
    copy: 'Copy link to clipboard',
    urlInfo: 'Using this tip any person can leave a donation.'
  },
  tipCodeContent: 'Starting package to download. Contains unique QR code, donation acceptance link and HTML/CSS button ready to used on digital media.',
  tipCodeContentThankyou: 'Thanks in advance. You are always welcome!',
  new_update: 'New version is avaliable! Please reload the Application.',
  reload: 'Reload',
  tipCodeContentGive: 'Dear Guest! Hope you had a good time! You may show your gratitude by leaving me a tip. Thanks in advance and you are always welcome!',
  giveTipButton: 'Leave the tip',
  valueOfTipHeight: 'Tip amount',
  entry: 'Continue',
  save: 'Save',
  account_data: 'Your account',
  user_id: 'User ID',
  payback_data: 'Personal data',
  bank_account_number: 'Your bank account number',
  account_number: 'Account number',
  receiver_name: 'First name and surname',
  pesel: 'PESEL',
  name_and_surname: 'First name and surname',
  street: 'Street',
  building_number: 'Building number',
  zip_code: 'Zip code',
  city: 'City',
  wrong_account_number: 'Invalid account number',
  required: 'This field is mandatory',
  type_name_and_surname: 'Type name and surename',
  individualMeasures: 'Personal funds',
  walletOwnerTip: 'To settle TipBox funds you must be the creator of the TipBox',
  walletMoreThanValue: 'Minimum balance must be met in order to withdraw funds',
  walletFillUserData: 'Financial and personal data must be provided in order to withdraw funds',
  walletSubmit: 'Funds will be transfered to the provided bank account number',
  walletSubmitTipBox: 'Funds will be distributed among participants of this TipBox',
  walletTransfer: 'Request funds withdrawal',
  payout: 'Withdrawal',
  transferFunds: 'In order to distribute TipBox funds following minimum balance must be met',
  save_error: 'Ups, something went wrong. Data is not saved.',
  save_success: 'Changes saved.',
  no_data: 'No Data',
  stepperStatus: 'Your Account status',
  information: 'Notice',
  noActiveTipbox: 'No TipBox is active at the moment. Please activate one of them by selecting on the provided list.',
  goFurther: 'Continue',
  tipBox: 'TipBox',
  tipBoxes: 'TipBoxes',
  invite: 'Invite participants',
  createNew: 'Create new',
  namePlaceholder: 'Name',
  newTipBoxName: 'TipBox name must contain at least 3 characters',
  create: 'Create',
  cumulateTips: 'Your tips are collected:',
  selectTipBox: 'Select TipBox to invite to:',
  tipBoxInviteInfo: 'Use your camera to scan the QR code and join the TipBox. You must be a logged in ofiara.org user in order to join.',
  tipboxReceiveIndividual: 'On my personal balance',
  empty: 'Empty',
  members: 'Participants',
  leader: 'Owner',
  toTipbox: 'Into TipBox',
  fromTipbox: 'From TipBox',
  sharedTip: 'Shared tip',
  withdrawTipbox: 'Distributed from TipBox',
  verificationTip: 'Verification tip',
  print_and_download_tipcodes: 'Download and print TipCodes',
  set: 'Cards',
  set_info: 'Insert TipCode card with every receipt or delivery',
  badge: 'Badge',
  badge_info: 'Badge to place as a stand on the bar or table',
  do_you_like_it: 'Hope you anjoyed !',
  leave_tip: 'Leave the tip',
  print_info_text: 'If you are willing to show your gratitude please use your camera to scan QR code and follow the instructions',
  scan_me: 'Scan me',
  inviting_tipBox: 'Hold on line. Your are joining the TipBox...',
  congratulations: 'Well done!',
  added_to_tipBox: 'You are now TipBox participant.',
  please_choose: 'Please choose',
  where_collect: 'Where do you collect tips',
  document_type: 'Document type',
  document_number: 'Document serial number',
  nationality: 'Nationality',
  balance_warning: 'You\'ve reached maximum account balance of 6k PLN. Please consider the withdrawal.<br />Due to KNF regulations you are not allowed to hold more than 8 tys. zł on your account',
  accept: 'I accept',
  cookies_msg: 'We may store and access personal data such as cookies, device identifiers or other similar technologies on your device and process such data to personalise content and analyse our traffic. You can read more about this and how to object by Privacy Policy.',
  no_account_data: 'No data provided',
  data_provided: 'Data provided',
  in_verification: 'Under verification',
  approved: 'Approved',
  accountData_step1: 'Your account is not fully verified. You are allowed to accept donations, however you will not be able to withdraw funds. In order to withdraw funds please fill in personal and financial data.',
  accountData_step2: 'Great, thank you for data provided!<br />Now we make run the verification process.<br />You may freely accept donations while we are doing the verification.',
  accountData_step3: 'Your data is under verification. Just a moment and you will be fully verified.',
  accountData_step4: 'Unfortunatelly, there are some discrepancies in data. You are free to accept donations, however you are not allowed to withdraw funds.<br />In order to sort out this issue please reach out to our support team.',
  accountData_step4_success: 'Congratulations ! You are positively verified and you are free to withdraw your funds at any time.',
  is_blocked: 'On Hold',
  blocked: 'Blocked',
  make_payment: 'Make verification payment',
  repeat_payment: 'Repeat verification payment',
  payment_why: 'Why you are requesting verification payment ?',
  payment_info: `[EN]
                  <p>Przelew weryfikacyjny pozwoli nam zweryfikować podane dane. Mamy wtedy pewność, że twoje napiwki trafią na właściwe konto.</p>
                  <p><span style="color: black; font-weight: 700;">Regulacje prawne</span><br />Ze względu na regulacje KNF konieczna jest pełna weryfikacja osoby korzystającej z aplikacji przed wykonaniem pierwszej wypłaty. Regulacje te mają na celu przeciwdziałanie praniu brudnych pieniędzy oraz finansowaniu terroryzmu.</p>
                  <p>Ponieważ za pomocą ofiara.org otrzymujesz realne pieniądze, jako instytucja finansowa musimy respektować te przepisy i w zgodzie z nimi w bezpieczny sposób przelać pieniądze na Twoje konto.</p>
                  <p><span style="color: black; font-weight: 700;">Jak wygląda weryfikacja?</span><br />Weryfikacja składa się z kilku kroków: Aby zweryfikować Twoje dane prosimy Cię o przelew na niewielką kwotę - taki sam przelew jakbyś robił zakupy w dowolnym sklepie internetowym. Otrzymując przelew pozyskamy z banku również dane osoby zlecającej przelew czyli Twoje dane. Zweryfikujemy, czy dane otrzymane z Banku zgadzają się z danymi wprowadzonymi przez Ciebie do aplikacji ofiara.org (dlatego, jeżeli na ekranie Profil podałeś niepoprawne dane popraw je zanim rozpoczniesz procedurę weryfikacji). Po pozytywnej weryfikacji będziesz mógł wypłacić środki zebrane w ofiara.org. W razie weryfikacji negatywnej prosimy o kontakt.</p>
                  <p><span style="color: black; font-weight: 700;">Czy odzyskam wpłacone pieniądze?</span><br />Wpłacone w ramach weryfikacji 2,00 zł zostaną zarejestrowane jako napiwek na Twoim indywidualnym koncie ofiara.org tak jak każdy inny otrzymany napiwek.</p>
                `,
  payback_info: 'In order to withdraw funds you are kindly asked to verify your bank account.',
  address: 'Address',
  assigned_to_bank_account: 'Assigned to bank account',
  please_verify_email: 'Please verify your email address',
  walletBlockInfo: 'We care about the safety of your funds. In order to enable funds withdrawal <a href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#105;&#110;&#102;&#111;&#64;&#112;&#97;&#121;&#116;&#105;&#112;&#46;&#105;&#111;">please contact</a> our customer support team.',
  payback_data_warning: `
    For an effortless verification please prepare following documents in advance:<br /><br />
    <strong>Bank account number</strong> - you shall be an owner of the account<br /><br />
    <strong>ID document</strong> - scan or picture of your id document (or passport)<br /><br />
    <strong>Bank Title Document</strong> - proving that the provided account number is truly yours account number.<br /><br />
    <strong>Your selfie</strong> - please make sure that your whole face is visible and not disturbed by any external factors.
  `,
  warning: 'IMPORTANT NOTICE',
  send_to_verification: 'Request verification',
  add_photo_or_scan_of_document: 'Attach document picture or scan',
  add_front_of_document: 'Attach front page of the ID document',
  add_back_of_document: 'Attach reverse page of the ID document',
  allowed_files: 'Allowed files',
  max_file_weight: 'Max file size',
  add_selfie: 'Attach your selfie',
  add_passport_document: 'Attach your passport page',
  delete: 'Remove',
  file: 'File',
  new_password: 'New password',
  reset_password_not_valid_link: 'Unfortunatelly the reset link is not valid any more :(.<br />Please try resetting the password again.',
  change_password_success: 'Password changed successfully.<br />You may now sign in.',
  verification: 'Verification',
  email_verification_success: 'You email has been verified.<br />You may now sign in.',
  email_verification_fail: 'Email verification failed. Please try again or contact our support <a href="mailto:info@ofiara.org">info@ofiara.org</a>.',
  wrong_url_no_params: 'Invalid parameters. Please contact our support <a href="mailto:info@ofiara.org">info@ofiara.org</a>',
  no_parameters: 'No parameters',
  add_bank_document: 'Add bank document',
  tip_went_to_your_account: '[EN] Napiwek trafił na Twoje konto.',
  actual_balance: '[EN] Aktualne saldo',
  awaiting_info: '[EN] Adres e-mail jest niezbędny do rejestracji i odbioru napiwku.',
  awaiting_info_logged: '[EN] Adres e-mail jest niezbędny do odbioru napiwku.',
  receive_tip: '[EN] Odbierz napiwek',
  type_email_address: '[EN] Podaj adres e-mail',
  type_correct_email: '[EN] Podaj prawidłowy adres',
  tip_was_send: '[EN] Napiwek został wysłany',
  check_your_mailbox_and_receive_tip: '[EN] Sprawdź skrzynkę mailową i odbierz swój napiwek!',
  error: 'Error',
  no_required_parameters: 'Missing required parameters.',
  transfer: 'Transfer',
  tipBox_transfer: 'Settle TipBox funds',
  promoTip: 'PromoTip',
  welcome: {
    title: 'How to use?'
  },
  what_is_promotip: 'What\'s the PromoTip?',
  our_works_promotip: 'PromoTip actions and activities',
  profits_promotip: 'PromoTip benefits',
  what_is_promotip_content: `
    PromoTip is a set of activites to promote and enhance gratitude related attitudes and actions.
    Everyday there are waiters, barbers, bartenders and many others of the hospitality profession who put their heart and soul into customer service.
    Some guests know how to show gratitude, however there are still some, who don't find it appropriate to give tips.
    We would like to encourage people that showing gratitude is a good practice that shall be followed.
    That is why the ofiara.org was created. In the world of diminishing cash usage we would like to positively increase income of the hospitality people.
  `,
  our_works_promotip_content: `
    <ul style="padding-left: 16px;">
      <li style="margin: 10px 0;">Promotional and educational campaing that encourage gratitude attitudes</li>
      <li style="margin: 10px 0;">Modern and secure tool to handle tips</li>
      <li style="margin: 10px 0;">Explain positive results of tipping for guests</li>
      <li style="margin: 10px 0;">Most efficient mean of accepting cashless tips</li>
      <li style="margin: 10px 0;">It encourages sound and honest tip handling practices especially when tips are gathered in a shared mode</li>
    </ul>
  `,
  profits_promotip_content: `
    <ul style="padding-left: 16px;">
      <li style="margin: 10px 0;">Your tips will increase</li>
      <li style="margin: 10px 0;">Tips are being democratized</li>
      <li style="margin: 10px 0;">Increase satisfaction of customers</li>
      <li style="margin: 10px 0;">Never miss a tip, especially when the guest does not have cash</li>
      <li style="margin: 10px 0;">Direct tipping, there is no middleman :)</li>
    </ul>
  `,
  what_is_promotip_intro: 'PromoTip is a set of activities that encourage tipping attitudes...',
  our_works_promotip_intro: 'Promotional and educational campaigns in the area of hospitality and gratitude...',
  profits_promotip_intro: 'We increase average tip income. Morever we would like to democratize tip giving...',
  more: 'More',
  less: 'Less',
  blik_info1: 'Now open your bank application please, and generate the BLIK code',
  blik_info2: 'Now switch to the bank application and please confirm BLIK code',
  blik_info_first_step: 'Buckle up your seatbelts, the tip is coming. Please stand by...',
  blik_info_new_tip1: 'BLIK code is required to be generated in bank application<br /><br />Ask the guest for the BLIK code from his bank application.',
  blik_info_new_tip2: 'Ask the guest for accepting transaction in bank application.',
  desktop_dialog_title: 'Please switch to a mobile device',
  desktop_dialog_content: 'This is an application optimized for a mobile device. Use your camera smartphone to scan QR code to enter mobile page.',
  sameDay_subTitle1: 'Hope you enjoyed ! You may show your',
  sameDay_subTitle2: 'gratitude',
  sameDay_subTitle3: 'using this QR code.',
  dearSir: 'Dear Sirs',
  thankyou: 'Thank you',
  scanQrCode: 'scan<br />qr code',
  typeTipAmount: 'enter tip<br />amount',
  authorizeTransaction: 'authorize<br />transaction',
  tipsDirectlyOnAccount: 'tips are transfered directly to the receiving party',
  transactionSafety: 'safety of transactions',
  version_whats_new: 'What\'s new',
  version_whats_new_list: `
    <p>Stability and performance improvents accompanied by:</p>
    <ol style="padding-left: 16px;">
      <li style="margin: 10px 0;">Enhanced TipBox settlement - even mode and amount based mode</li>
      <li style="margin: 10px 0;">Guest may use Apple Pay to give the tip</li>
      <li style="margin: 10px 0;">Improved user experience when accepting tip from the main application screen</li>      
    </ol>
  `,
  navigation: {
    settle: 'Settle TipBox'
  },
  settle: {
    title: 'Settlement',
    editTitle: 'Amount',
    editIntro: 'Enter amount for:',
    subTitle: 'TipBox',
    go: 'Settle TipBox',
    listTitle: ' ',
    remainingTitle: ' ',
    remainingLabel: 'Will remain in TipBox',
    info1: 'Money will be settled among individual accounts.',
    info2: 'You may change individual amount by clicking the list above.',    
    error1: 'You are allowed to settle amount that doesn\'t exceed TipBox balance.',
    error2: 'Tap on a list above to make valid settlement.',    
    save: 'Save',
    cancel: 'Cancel',
    thankYouTitle: 'Well done !',
    thankYou: 'TipBox settlement was successfull.',

  },
  opus: {
    hello: 'God Bless',
    intro: 'this donation will be used to support our church. <br/> Thank you for your care.',
    cta: 'Leave donation',
    title: 'Donation for our church maintenance',
    amount: 'Donation amount',
    thankYou: 'God bless you',
    thankYou2: 'donations are used solely for our church maintenance.',
    pay: 'Leave donation',
    desktop_dialog_title: 'Site optimized for mobile devices',
    desktop_dialog_content: 'Please switch to mobile device for best possible experience using this site',
    desktop_dialog_content_link: 'Link:',
    errMessage_maxTip: 'Max donation amount',
    errMessage_minTip: 'Min donation amount',
    proceedToPaymentTypes: 'Proceed',
    blik_info2: 'Please use your banking application to accept BLIK code',
    blik_info_first_step: 'Please hold on line and do not switch off the application. The donation is being placed...',    
    blikButtonAlt: 'BLIK Payment'
  }
};
