import React from 'react';
import styles from './PageButton.module.scss';
import { ButtonStyle } from 'components/override_styles/Button';
import Button from '@material-ui/core/Button';
const CssButton = ButtonStyle(Button);

interface Props {
  label?: string;
  name?: string;
  onClick?: any;
  disabled?: boolean;  
}

/**
 */
const PageButton = (props: Props) => {    
  const {label, name, onClick, disabled} = props

  const handleClick = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    if(onClick){
      onClick(e, name);
    }
  };
  
  return (
    <CssButton
      fullWidth
      size="large"
      variant="outlined"
      type="button"
      onClick={handleClick}
      className={disabled ? `${styles.PageButton__disabled}` : `${styles.PageButton__active}`}
      disabled={disabled}
    >
    {label}
    </CssButton>    
  );
};

export default PageButton;
