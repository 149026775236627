/* eslint-disable */
/* tslint:disable */

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';
import moment from 'moment';

export function BackendApp(opts) {
  if (!(this instanceof BackendApp)) { return new BackendApp(opts); }

  for (var key in opts) {
    if (opts.hasOwnProperty(key)) { this.opts[key] = opts[key]; }
  }

  this.instance = Math.round(Math.random() * 2000);
  //this.initialize();
  // console.log('Version: ' + this.version);
  return this;
}

BackendApp.prototype = {
  version: '1.3.6',
  updated: '2020/01/02',
  opts: {
  },

  VERIFICATION_STATUS: {
    ACCEPTED: 'A',
    BLOCKED: 'B',
    DATA_PROVIDED: 'D',
    PENDING: 'P',
    UNKNOWN: 'U'
  },

  model: {
    user: {},
    userData: {},// additional data of the logged in user
    empire: {}, //currently logged in user empire
    thirdparty: {},
    maps: [],
    scenarios: [],
    scenarioHolder: {},  // stores scenario object during create and scenario configuration    
    isDeploymentConfigured: false,
    isVictoryConditionsConfigured: false,
    userMarkedAsInactive: false
  },
  view: {
    tagsInputs: {}
  },

  timeout: 8000,
  //inactivityTimeMs: 5*60000, // 15 minutes
  inactivityTimeMs: 60000, // 60 seconds

  QUERY_LIMIT: 2000,


  initialized: function () {
    var that = this;

    return firebase.auth().getRedirectResult()
      .then(function (result) {
        if (result.credential) {
          // This gives you a Facebook Access Token. You can use it to access the Facebook API.
          var token = result.credential.accessToken;
          that.model.thirdparty.apiToken = token;
        } else {
          // console.log('Result', result);
        }



        // The signed-in user info.
        var user = result.user;
      })
      .then(() => {
        return new Promise(function (resolve, reject) {
          firebase.auth().onAuthStateChanged(function (user) {
            if (user) {
              that.model.user = user;
              //console.log('Zalogowany', user, user.providerData, user.getIdToken().claims);
              firebase.auth().currentUser.getIdTokenResult()
                .then((idTokenResult) => {
                  // Confirm the user is an Admin.
                  if (!!idTokenResult.claims) {
                    //console.log('User claims', idTokenResult.claims);
                  } else {                    
                  }
                })
                .catch((error) => {
                  console.log(error);
                });

              if (sessionStorage.getItem('redirectURL')) {
                var redirectURL = sessionStorage.getItem('redirectURL');
                sessionStorage.removeItem('redirectURL');
                window.location.replace(redirectURL);
              }
              // 
              if (that._getUrlParameter("claimsForceReload")) {
                // to reload claims we will sign tje user out as on sign out user roles are rebuilt
                that.userSignOut(null, that);
              }
            }
            resolve(that.model.user);
          });
        });

      });
  },

  /**
   * Loads wallet for currently logged in user
   * @module paytip-core
   * @returns  Promise that resolves to single item array with wallet data at result[0]
   */
  loadUserWallet: function () {
    return this.loadUserEntitySingle(this.model.user.uid, 'wallet/users');
  },

  /**
   * Retrieves configuration
   * @param {*} configKey key of the configuration to retrieve   
   * @returns  Promise that resolves to single item array with value of the configuration key at result[0]
   * @module paytip-core
   */
  loadConfig: function (configKey) {
    var resultArray = this.loadUserEntitySingle(configKey, 'paytip/config');
    return resultArray;
  },

  /**
   * 
   * @param {*} handler 
   * @module paytip-core
   */
  observeReceiveables: function (handler) {
    this.observeUserEntityWithHandler('userReceiveables', 'st', handler);
  },

  /**
   * Observes user shared wallets
   * @param {*} handler 
   * @module paytip-core
   */
  observeUserShared: function (handler) {
    this.observeUserEntityWithHandler('userShared', null, handler);
  },

  /**
   * Returns list of shared elements that current user is participant of.
   * The list is sorted alphabetically using shared label (l) field.
   * @param {*} handler 
   * @module paytip-core
   */
  observeShared: function (handler) {
    this.observeUserEntityWithHandler('userShared', 'l', handler);
  },

    /**
   * Returns user id/ wallet id of the individual that is accepting tip in given place.
   * This method either creates such a person (when this is a place that has not received any tips) or
   * retrieves matching person.
   * @param {*} placeId provider specific id of the place
   * @param {*} providerId places provider id (G - google maps)
   * @param {*} name name of the place as returned by places provider
   * @param {*} address address of the place as returned by places provider
   * @returns {Object} Beneficiary user id/wallet id is returned accompanied with internal paytip place id
   * @returns {Object}.r Beneficiary user id/wallet id 
   * @returns {Object}.p Id of the place in PayTip dictionary
   */
  beneficiaryForPlace: function(placeId, providerId, name, address, categories){
    var callable = firebase.functions().httpsCallable('beneficiaryForPlace');
    var dto = {     
      p: providerId || 'U',
      i: placeId || 'Unknown',
      n: name || '',
      a: address || '',
      c: categories || []
    };

    var request = {
      dto: dto,
      userId: undefined
    };

    return callable(request)
    .then(response => {      
      return response.data;
    })
    .catch(error => {
      console.error(error);
      throw new Error('Error getting beneficiary for place');
    })
  },

  loadPublicBinary: function(userId, binaryId){
    var callable = firebase.functions().httpsCallable('userLoadBinary');    
    var dto = {
      i: binaryId // should start with 'pi*'
    };

    var request = {
      dto: dto,
      userId: userId
    }; 

    return callable(request)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.error(error);
      throw new Error('Error loading public binary');
    })
  },

  /**
   * Creates new message and binds it with the tip transaction and place.
   * @param {*} beneficiaryId Id of the person that received the tip
   * @param {*} transactionId Id of the tip transaction to which message is created
   * @param {*} message Message body
   */
  leaveMessage: function(placeId, providerId, beneficiaryId, transactionId, message){
    var callable = firebase.functions().httpsCallable('placeLeaveInteraction');    
    var dto = {    
      it: 'M', // M - message       
      i: placeId,
      p: providerId,
      b: beneficiaryId,
      t: transactionId,
      m: message || ''
    };

    var request = {
      dto: dto,
      userId: undefined
    };

    return callable(request)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.error(error);
      throw new Error('Error leaving message');
    })
  },
  /**
   * Scores place and leaves review of the place from given transaction
   * @param {*} placeId Id of the place to review (from places provider)
   * @param {*} providerId Id of the places provider
   * @param {*} beneficiaryId Id of the person that received the tip
   * @param {*} transactionId Tip transaction id
   * @param {*} rating Rating of the place
   * @param {*} review Review of the place
   */
  leaveReview: function(placeId, providerId, beneficiaryId, transactionId, rating, review){
    var callable = firebase.functions().httpsCallable('placeLeaveInteraction');    
    var dto = {
      it: 'R', // R - review           
      p: providerId,
      i: placeId,
      b: beneficiaryId,      
      t: transactionId,
      s: rating || 5,
      m: review || ''
    };

    var request = {
      dto: dto,
      userId: undefined
    };

    return callable(request)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.error(error);
      throw new Error('Error leaving review');
    })
  },


  /**
   * Generates QRcode png image in base64 encoded format
   * @param {*} hostname for the action that will be linked to the qrcode
   * @param {*} amount base amount to be used for tip
   * @param {*} name name of the person that will receive the tip, used for presentation purposes
   * @param {*} isAmountFixed when true then the value given in amount will be presented for the user, otherwise 3 values on top of this value will be presented
   * @returns base 64 encoded image that can be used as img src attribute
   * @module paytip-qrcode
   */
  generateQRCode: function (hostname, amount, name, isAmountFixed) {
    var that = this;
    return new Promise(function (resolve, reject) {
      var callable = firebase.functions().httpsCallable('qrcodeGenerate');
      var dto = {
        hostname: hostname || 'https://www.execon.pl',
        amount: amount || 10,
        name: name || 'Paytip',
        amountFixed: isAmountFixed || false
      };

      callable(dto)
        .then(response => {
          // data {u: _url_, m: _link_}
          resolve(response.data);
        })
        .catch(error => {
          console.error(error);
          throw new Error('Error generating qrcode');
        })
    });
  },

  checkSharedOwnership: function (sharedId, userId) {
    var that = this;
    return new Promise(function (resolve, reject) {

      var callable = firebase.functions().httpsCallable('sharedIsOwner');

      var dto = {
        s: sharedId
      };

      var request = {
        dto: dto,
        userId: userId
      };

      callable(request).then(function (result) {
        console.log('Shared isOwner', result.data);
        resolve(result.data);
      })
        .catch(error => {
          reject(error);
        });
    });
  },

  joinShared: function (sharedId, userId) {
    var that = this;
    return new Promise(function (resolve, reject) {

      var callable = firebase.functions().httpsCallable('sharedJoin');

      var dto = {
        s: sharedId
      };

      var request = {
        dto: dto,
        userId: userId
      };

      callable(request).then(function (result) {
        console.log('Shared joined', result);
        resolve(result);
      })
        .catch(error => {
          console.log('Error joining shared', error);
          reject(error);
        });
    });
  },

  /**
   * Changes user password
   * @param {*} oldPassword 
   * @param {*} newPassword 
   * @returns Promise that resolves on success
   */
  userChangePassword(oldPassword, newPassword){
    const cred = firebase.auth.EmailAuthProvider.credential(
      firebase.auth().currentUser.email, oldPassword);
    return firebase.auth().currentUser.reauthenticateWithCredential(cred)
      .then(() => {
        return firebase.auth().currentUser.updatePassword(newPassword);
      })      
  },

  /**
   * Activates user email using provided code
   * @param {*} email Email to activate
   * @param {*} code Code to activate
   * @returns Promise that resolves 
   */
  userActivate(email, code){
    var that = this;
    var dto = {
      e: email,
      c: code
    }
    console.log(dto);
    return that._callAPI('activateUserEmail',dto);
  },
  /**
   * Retrieves participants of the currently active shared element of logged in user.
   * @returns array of participants, each participant is an object: {n: participant display name, o: true when given participant is an owner of the TipBox, i: user id of the participant}   
   */
  loadActiveSharedParticipants: function () {
    return this._callAPI('sharedParticipants')
      .then(participantsArray => {
        // console.log('Received participants', participantsArray);
        return participantsArray.data;
      })
      .catch(error => {
        console.log('Error receiving participants', error);
        return [];
      })
  },

  _reporting: function(paramsDto){
    return this._callAPI('mgrfunc',paramsDto);
  },

  /**
   * Invokes provided operation using BackOffice API
   * @param {*} requestDto Object that contains name of the operation and input arguments for the operation {n: operation name, d: input object}. 
   * For input object specification and available operations please refer to documentation.
   * @returns Promise that resolves on success and may contain result data (depends on the operation that was requested - see detailed 
   * operations documentation)
   */
  callBackOffice: function(requestDto){
    return this._callAPI('boAPI', requestDto);
  },

  _callAPI: function (operation, dto, userId) {
    var that = this;
    return new Promise(function (resolve, reject) {

      var callable = firebase.functions().httpsCallable(operation);

      var request = {
        dto: dto,
        userId: userId
      };

      callable(request).then(function (result) {
        console.log('API call success', result);
        resolve(result);
      })
        .catch(error => {
          console.log('API call error', error);
          reject(error);
        });
    });
  },

  /**
   * Creates new shared element with given label
   * @param {*} label human readable name of the shared element, required
   * @param {*} userId owner user of the shared, when not provided then currently logged in user will be used, optional
   */
  registerShared: function (label, userId) {
    var that = this;
    return new Promise(function (resolve, reject) {

      var callable = firebase.functions().httpsCallable('sharedCreate');

      var dto = {
        l: label
      };

      var request = {
        dto: dto,
        userId: userId
      };

      callable(request).then(function (result) {
        console.log('Shared created', result);
        resolve(result);
      })
        .catch(error => {
          console.log('Error creating shared', error);
          reject(error);
        });
    });
  },

  /**
   * Registers payment for currently logged in user
   * @param {*} amount in currency units (groszy)
   * @param {*} code payment authorisation code
   * @param {*} userId when provided payment will be for user with given id, when not provided then payment is done for the currently logged in user
   * @param {*} provider B for BLIK, G for Google Pay, A for Apple Pay
   * @param {*} transactionExtraInfo Additional object holding tip transaction info {p: place id, pp: place provider id, b: benefactor id the person that gives the tip}
   * @returns Transaction id (receivable id) or the whole wallet accept tip response when google pay was used {t: transactionId, p: payByLink form data, , r: 3dsverificationlink (optional) }
   * @module paytip-core
   */
  registerPayment: function (amount, code, userId, provider, urlToGpay = null, transactionExtraInfo = undefined) {
    var that = this;
    return new Promise(function (resolve, reject) {

      var callable = firebase.functions().httpsCallable('walletAcceptTip');

      var userReceivableDTO = {
        a: amount,
        c: code,
        p: provider
      };

      if (urlToGpay) {
        userReceivableDTO = {
          ...userReceivableDTO,
          l: urlToGpay
        }
      }

      console.log('UserReveivables: ', userReceivableDTO);

      var walletAcceptTipDTO = {
        userReceivable: userReceivableDTO,
        userId: userId
      };
      // if additional 3DS transaction info is provided 
      if(transactionExtraInfo){
        walletAcceptTipDTO['transactionExtraInfo'] = transactionExtraInfo
      }

      return callable(walletAcceptTipDTO).then(function (result) {
        console.log('Received in', result.data);
        
        const results = urlToGpay ? {
          ...result.data
        } : result.data.t;

        resolve(results);
      })
        .catch(error => {
          console.log('Error registering tip', error);
          reject(error);
        });
    });
  },

  /**
   * When requested stores additional data for redirected 3D Secure verification. That data when provided
   * is later on used when user is redirected back to the application (so additional query parameters are
   * added to the URL in order to recreate proper state)
   * @param {*} transactionId Id of the tip transaction 
    * @param {*} beneficiaryId Id of the user that receives the tip
    * @param {*} amount Tip amount (in cents)
    * @param {*} placeId (Optional) Id of the place where tip is left
    * @param {*} placeProviderId (Optional) Id of the place provider
    * @param {*} placeName (Optional) Name of the place where tip is left
    * @param {*} placeAddress (Optional) Address of the place where tip is left
    * @returns Promise that resolves with 3DS data {k: key, v: data}
   */
  store3DSecureExtraData(transactionId, beneficiaryId, amount, placeId=undefined, placeProviderId=undefined, placeName=undefined, placeAddress=undefined){
    var dto = {
      r: beneficiaryId,
      t: transactionId,
      a: amount,
      p: placeId,
      pp: placeProviderId,
      pn: placeName,
      pa: placeAddress      
    }
    return this._callAPI('store3DSData',dto);
  },

  /**
   * Registers payment in async mode by receiving awaiting id for async transaction processing.
   * @param {*} amount in currency units (groszy)
   * @param {*} code payment authorisation code
   * @param {*} userId when provided payment will be for user with given id, when not provided then payment is done for the currently logged in user
   * @param {*} provider B for BLIK, G for Google Pay, A for Apple Pay
   * @param {*} transactionExtraInfo Additional object holding tip transaction info {p: place id, pp: place provider id, b: benefactor id the person that gives the tip}
   */
  registerPaymentAsync: function (amount, code, userId, provider, transactionExtraInfo = undefined) {
    var that = this;
    return new Promise(function (resolve, reject) {

      var callable = firebase.functions().httpsCallable('walletAcceptTipAsync');

      var userReceivableDTO = {
        a: amount,
        c: code,
        p: provider
      };

      console.log('UserReveivables: ', userReceivableDTO);

      var walletAcceptTipDTO = {
        userReceivable: userReceivableDTO,
        userId: userId
      };

      // if additional 3DS transaction info is provided
      if(transactionExtraInfo){
        walletAcceptTipDTO['transactionExtraInfo'] = transactionExtraInfo
      }

      return callable(walletAcceptTipDTO).then(function (result) {
        console.log('Received in', result.data);

        resolve(result.data);
      })
        .catch(error => {
          console.log('Error registering tip', error);
          reject(error);
        });
    });
  },

  /**
   * Registers a KYC verification tip transaction for given amount and provides order form data
   * so the user can be redirected to providers PayByLink page.
   * 
   * @param {*} amount Tip amount in currency cents
   * @param {*} userId Beneficiary of the tip, the person that will receive this verification tip
   * @param {*} continueURL URL to which user will be redirected after success payment
   * @returns Object {t: transactionId, p: payByLink form data} that contains transaction info and pay by 
   * link form data (list of form fields along with target POST URL) - p.fields - array of {field, value} pair objects, p.postURL - 
   * target form URL for PayByLink payment
   */
  registerKYCPayment: function (amount, userId, continueURL) {
    var that = this;
    return new Promise(function (resolve, reject) {

      var callable = firebase.functions().httpsCallable('walletAcceptTip');

      var userReceivableDTO = {
        a: amount,
        p: 'P', // pay by link
        u: continueURL
      };

      var walletAcceptTipDTO = {
        userReceivable: userReceivableDTO,
        userId: userId
      };

      callable(walletAcceptTipDTO).then(function (result) {
        console.log('Received in', result);
        resolve(result);
      })
        .catch(error => {
          console.log('Error registering tip', error);
          reject(error);
        });
    });
  },

  /**
   * Returns MerchantSession which is an opaque message session object, received from the Apple Pay server
   * @param {*} url url retrieved from onvalidatemerchant function
   * @param {*} domain domain to which merchant is authorized
   */
  paymentAppleRequestPaymentSession: function (url, domain) {
    var that = this;
    return new Promise(function (resolve, reject) {

      var callable = firebase.functions().httpsCallable('paymentAppleRequestPaymentSession');
      var paymentSessionRequest = {
        v: url,
        d: domain
      };
      var paymentSessionRequestDTO = {
        request: paymentSessionRequest,
        userId: null
      };
      console.log('Going to request Apple Payment Session', paymentSessionRequestDTO);
      callable(paymentSessionRequestDTO).then(function (result) {
        console.log('Received Apple Payment Session', result);
        resolve(result);
      })
        .catch(error => {
          console.error('Error requesting apple pay payment session', error);
          reject(error);
        });
    });
  },

  /**
 * Creates withdrawal request. By default uses user's wallet, when shared id is provided then withdrawal from shared wallet is issued.
 * @param {*} amount in currency units (groszy)   
 * @param {*} sharedId optional, when provided the withdrawal will use currently user's active shared wallet (proven that the user has priviledge to do so). Otherwise, user's wallet will be used for withdrawal.
 * @param {*} withdrawalSpecification optional, when provided the withdrawal will use amounts from specification, when not then equal split will be used. This is an array: {u: user id, a: amount for user}.
 * It is also important to remember that sum of amounts in withdrawalSpecification must equal amount.
 * @module paytip-core
 */
  registerWithdrawal: function (amount, sharedId, withdrawalSpecification = undefined) {
    var that = this;

    return new Promise(function (resolve, reject) {
      var userWithdrawalDTO = {
        a: Math.floor(amount),
        s: sharedId,
        w: withdrawalSpecification
      };
      var walletWithdrawCallable = firebase.functions().httpsCallable('walletWithdraw');
      walletWithdrawCallable(userWithdrawalDTO).then(function (result) {
        resolve(result);
      }).catch(error=>{
        reject(error);
      })

    });


  },

  /**
   * Checks if the logged in user has provided financial data
   * @module paytip-core
   * @returns Promise that resolves true when the data is set, false otherwise
   */
  isUserFinancialDataSet: function () {
    var that = this;

    return new Promise(function (resolve, reject) {
      var result = false;

      that.backendLoadUser(that.model.user.uid).then((userData) => {
        result = userData.bn ? true : false;
        result = userData.dn ? result && true : false;
        result = userData.s ? result && true : false;
        result = userData.sno ? result && true : false;
        result = userData.z ? result && true : false;
        result = userData.c ? result && true : false;

        resolve(result);
      })
    });
  },

  
  /**
   * Updates user verification status
   * @param {*} newVerificationStatus
   * @returns Promise that resolves on success 
   * @module paytip-core
   */
  updateUserVerificationStatus: function (newVerificationStatus) {
    var that = this;
    var dateUtcOffset = moment().utcOffset(); 
    var transactionDateTs = moment().add(dateUtcOffset, 'm').valueOf();     
    var change = {      
      s: newVerificationStatus,
      st: transactionDateTs 
    };    
    return that.backendUpdateUserEntitySubfield('userData','v', that.model.user.uid, change);
  },

  updateUserDataField: function (fieldName, newValue) {
    var that = this;
    var dateUtcOffset = moment().utcOffset();
    var transactionDateTs = moment().add(dateUtcOffset, 'm').valueOf();
    var change = {};
    change[fieldName] = newValue;
    return that.backendUpdateUserEntity('userData', that.model.user.uid, change);
  },

/**
 * 
 
 * @module paytip-core
 * @returns Promise being the result of the financial data update
 */
  userUpdateFinancialData: function (bankNo, displayName, idNo1, idDocNo1, idDoc1Type, street, streetNo, zipCode, city, citizenship, extra1) {
    var that = this;
    var dateUtcOffset = moment().utcOffset();
    var transactionDateTs = moment().add(dateUtcOffset, 'm').valueOf();
    var dto = {
      dn: displayName,
      bn: bankNo,
      s: street,
      sno: streetNo,
      z: zipCode,
      c: city,
      cs: citizenship,
      v: {        
        i1: idNo1,
        id1: idDocNo1,
        idt1: idDoc1Type,
      },            
      mt: transactionDateTs
    }

    if(extra1)
      dto.e1 = extra1;

    return that.backendUpdateUserEntity('userData', that.model.user.uid, dto);
  },

  /**
   * 
   * @param {*} email 
   * @param {*} password 
   * @param {*} name 
   * @param {*} surname 
   * @param {*} id1 
   * @param {*} id2 
   * @param {*} street 
   * @param {*} streetNo 
   * @param {*} zipCode 
   * @param {*} city 
   * @module paytip-core
   */
  userJoin: function (email, password) {
    var that = this;
    var userCreated = undefined;
    var dto = {
      e: email,
      p: password      
    }

    return that._callAPI('userCreate', dto)
    .then(result=>{
      console.log('User created', result);
      userCreated = result.user;
    })
  },

  userRebuildClaims: function () {
    return this._callAPI("userRebuildClaims");
  },
  
  /**
   * Signs user in the application
   * @param {*} login 
   * @param {*} password 
   * @module paytip-core
   * @returns Promise that resolves with logged in user on success, rejects on error
   */
  userSignIn: function (login, password) {
    var that = this;
    return new Promise(function (resolve, reject) {
      firebase.auth().signInWithEmailAndPassword(login, password).then(function (data) {
        // todo dodac weryfikacje flagi, czy isEmailVerified
        if(data.user.emailVerified){
          resolve(data);
        }else{
          // user email is not verified so block access
          reject({code:-100 , message:'Please verify your email' })
        }        
      }).catch(function (error) {
        var errorCode = error.code;
        var errorMessage = error.message;
        reject(error);
      });
    });

  },

  userSignInWithFacebook: function () {
    var that = this;

    var provider = new firebase.auth.FacebookAuthProvider();
    provider.setCustomParameters({
      'display': 'touch'
    })
    provider.addScope('email');
    return firebase.auth().signInWithRedirect(provider);
  },

  /**
   * @module paytip-core
   */
  userSignOut: function (e, that) {
    // here we rebuild user claims (roles) so next time when user logs in 
    // he will have most up to date claims/roles
    return new Promise(function (resolve, reject) {
      BackendApp().userRebuildClaims()
        .then(() => {
          firebase.auth().signOut().then(function () {
            resolve();
          }).catch(function (error) {
            reject();
          });
        })
      });
  },



  showNotificationSuccess: function () {
    d3.select('.notification.operation-success').classed('is-hidden', null);

    setTimeout(function () {
      d3.select('.notification.operation-success').classed('is-hidden', true);
    }, this.timeout);
  },

  showNotificationError: function () {
    d3.select('.notification.operation-failure').classed('is-hidden', null);

    setTimeout(function () {
      d3.select('.notification.operation-failure').classed('is-hidden', true);
    }, this.timeout);
  },




  validateEmail: function (email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },

  backendGetLoggedUser: function () {
    var user = firebase.auth().currentUser;
    // todo add loading userExtension
    return user;
  },



  /**
   * Saves user entity into database.
   * Examples:   
   * A. 'malina','uid1',entity with uid
   * the entity will be saved into /malina/uid1/uid
   * B. 'malina','uid1',entity without uid
   * the entity will be saved directly into /malina/uid1   
   * @param {string} entityName - path to the user entity (excluding userid and entity id) , ie. 'userEmpire'
   * @param {string} userId - id of the user for which to add entity
   * @param {object} entity - entity object, if the object has uid property then the entity will be added to the tree with uid
   */
  backendSaveUserEntity: function (entityName, userId, entity) {
    var entityPath;

    if (entity.uid)
      entityPath = '/' + entityName + '/' + userId + '/' + entity.uid;
    else
      entityPath = '/' + entityName + '/' + userId;
    return new Promise(function (resolve, reject) {
      firebase.database().ref(entityPath).set(entity, function (error) {
        resolve();
      });
    });

  },

  backendUpdateUserEntity: function (entityName, userId, entity) {
    var entityPath;

    if (entity.uid)
      entityPath = '/' + entityName + '/' + userId + '/' + entity.uid;
    else
      entityPath = '/' + entityName + '/' + userId;
    return new Promise(function (resolve, reject) {
      firebase.database().ref(entityPath).update(entity, function (error) {
        resolve();
      });
    });
  },

  backendUpdateUserEntitySubfield: function (entityName, subfieldName, userId, entity) {
    var entityPath;    
    entityPath = '/' + entityName + '/' + userId+'/'+subfieldName;
    return new Promise(function (resolve, reject) {
      firebase.database().ref(entityPath).update(entity, function (error) {
        resolve();
      });
    });

  },

  backendGenerateUUID: function () {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    )
  },

  /**
   * Loads user entity that is expected to store a list of objects
   * @param {*} entityName 
   * @param {*} orderByField 
   */
  loadUserEntity: function (entityName, orderByField) {
    var resultArray = [];
    var that = this;
    var orderBy = orderByField || 'modifiedSort';

    return new Promise(function (resolve, reject) {
      var entityRef = firebase.database().ref('/' + entityName + '/' + that.model.user.uid).orderByChild(orderBy);

      entityRef.on('value', function (snapshot) {
        if (snapshot.val()) {
          snapshot.forEach(function (child) {
            resultArray.push(child.val());
          });
        } else { }
        return resolve(resultArray);
      });
    });
  },

  /**
   * Loads user entity that is expected to be a single object
   * @param {string} uid - uid of the user for which load the entity
   * @param {*} entityName 
   * @param {*} orderByField 
   * 
   * @returns single item array with entity data result[0]
   */
  loadUserEntitySingle: function (uid, entityName, orderByField) {
    var resultArray = [];
    var that = this;
    var orderBy = orderByField || 'modifiedSort';

    return new Promise(function (resolve, reject) {
      var entityRef = firebase.database().ref('/' + entityName + '/' + uid).orderByChild(orderBy);

      entityRef.on('value', function (snapshot) {
        if (snapshot.val()) {
          resultArray.push(snapshot.val());
        } else { }

        return resolve(resultArray);
      });
    });
  },


  observeUserEntity: function (entityName, orderByField, targetModelFieldName) {

    var that = this;
    var orderBy = orderByField || 'modifiedSort';

    var entityRef = firebase.database().ref('/' + entityName + '/' + that.model.user.uid).orderByChild(orderBy);

    entityRef.on('value', function (snapshot) {
      var resultArray = [];
      if (snapshot.val()) {
        snapshot.forEach(function (child) {
          resultArray.push(child.val());
        });
      } else { }
      console.log(resultArray);
      that.model[targetModelFieldName] = resultArray;
    });
  },



  observeEntityWithHandler: function (entityName, entityPath, orderByField, handler) {
    var that = this;
    var orderBy = orderByField;

    var entityRef = null;

    if (orderBy)
      entityRef = firebase.database().ref(entityPath).orderByChild(orderBy);
    else
      entityRef = firebase.database().ref(entityPath);
    
    entityRef = entityRef.limitToFirst(that.QUERY_LIMIT);

    entityRef.on('value', function (snapshot) {
      var resultArray = [];
      if (snapshot.val()) {
        snapshot.forEach(function (child) {
          resultArray.push({
            id: child.key,
            val: child.val()
          });
        });
      } else { }

      handler.hFunction.apply(handler.hObject, [entityName, resultArray]);
    });
  },

  observeUserEntityWithHandler: function (entityName, orderByField, handler) {
    var that = this;
    this.observeEntityWithHandler(entityName, '/' + entityName + '/' + that.model.user.uid, orderByField, handler);
  },

  /**
   * Loads user entity from data store by id
   * @param {*} uid 
   * @returns userData object
   */
  backendLoadUser: function (uid) {
    var that = this;

    return new Promise(function (resolve, reject) {
      that.loadUserEntitySingle(uid, 'userData').then(function (resultArray) {
        resolve(resultArray[0]);
      }).catch(function (error) {
        reject();
      })
    });
  },
  /**
   * Loads user empire entity from data store by id
   * @param {*} uid 
   * @returns userEmpire object
   */
  backendLoadUserEmpire: function (uid) {
    var that = this;

    return new Promise(function (resolve, reject) {
      that.loadUserEntitySingle(uid, 'userEmpire').then(function (resultArray) {
        resolve(resultArray[0]);
      }).catch(function (error) {
        reject();
      })
    });
  },

  _getUrlParameter: function (name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  },
};