export const pl = {
  application_name: 'ofiara.org',
  application_name_opus: 'ofiara.org',
  intro_text: 'Dzielmy się dobrem',
  login_title: 'Logowanie',
  email: 'E-mail',
  password: 'Hasło',
  email_placeholder: 'Podaj e-mail',
  password_placeholder: 'Podaj hasło',
  login: 'Zaloguj',
  forgot_password: 'Nie pamiętam hasła',
  wrong_data: 'Błędne dane',
  login_facebook: 'Zaloguj przez facebook',
  dont_have_account: 'Nie masz jeszcze konta ofiara.org?',
  sign_up: 'Zarejestruj się',
  sign_in: 'Zaloguj się',
  reset_password_title: 'Resetowanie hasła',
  reset_password_help: 'Jako instytucja finansowa zachowujemy najwyższe standardy bezpieczeństwa i poufności. <br/><br/>Aby zresetować hasło prześlij zapytanie na adres:',
  reset_password_info: 'Link do zmiany hasła został wysłany.',
  reset_password_info_mail: 'info@ofiara.org',
  reset_password_info_extra1: 'Twoje zgłoszenie zostanie zweryfikowane przez naszego konsultanta',
  reset_password_info_extra2: 'Zgłoszenie powinno być wysłane z adresu, który jest loginem do naszej platformy.',
  reset_password: 'Resetuj hasło',
  sign_up_title: 'Rejestracja',
  sign_up_info: 'Link aktywacyjny został wysłany na podany adres e-mail.',
  create_account: 'Załóż konto',
  hello: 'Witaj',
  im: 'jestem',
  terms_accept_text1: 'Akceptuję',
  terms_accept_text2: 'oraz wyrażam zgodę na przetwarzanie moich danych.',
  terms_accept_link: 'regulamin i politykę prywatności',
  error_email: 'Błędny adres email',
  error_password: 'Minimalna liczba znaków: 8',
  already_have_account: 'Posiadasz już konto?',
  logged_out: 'Wylogowano',
  login_again: 'Zaloguj ponownie',
  newTip: 'Jak korzystać?',
  newTipBox: 'Nowy TipBox',
  tip: 'Datek',
  invoice_amount: 'Kwota rachunku',
  received_tips: 'Przyjęte napiwki',
  tips_history: 'Otrzymane datki',
  history: 'Historia',
  my_balance: 'Moje saldo',
  my_tips: 'Otrzymane datki',
  day: 'Dzień',
  week: 'Tydzień',
  month: 'Miesiąc',
  tipCode: 'Zestaw startowy',
  profile: {
    menu: 'Profil',
    changePass: {
      menu: 'Zarządzaj kontem',
      title: 'Edycja konta',
      subtitle: 'Zmiana hasła',
      newPassTitle: 'Nowe hasło',
      pass1Label: 'Podaj hasło',
      pass2Label: 'Powtórz hasło',
      placeholder: 'min. 8 znaków',
      ctaLabel: 'Zapisz zmiany',
      saveOK: 'Hasło poprawnie zmienione',
      changeError: 'Zmiana hasła nie powiodła się. Sprawdź aktualne hasło i spróbuj ponownie.',
      passMismatch: 'Hasło i powtórzone hasło są różne, popraw dane',
      oldPassTitle: 'Obecne hasło',
      passOldLabel: 'Podaj obecne hasło',
      oldPassPlaceholder: 'Twoje obecne hasło do aplikacji'
    }
  },
  editProfile: 'Edytuj Profil',
  about_payTip: 'O nas',
  logout: 'Wyloguj',
  tip_height: 'Wysokość napiwku',
  currency: 'zł',
  paymentType: 'Płatność',
  writeBlickCodeOrTikCode: 'Podaj kod BLIK lub skorzystaj z TipKodu.',
  blikCode: 'Kod BLIK',
  tipCodeNewTip: 'TipKod',
  blikCodePlaceholder: ' 6 cyfr',
  tipPlaceholder: 'Np.: 29.00',
  pay: 'Wręczam',
  commission: 'Prowizja',
  commission_promoTip: 'Obsługa serwisu 24/7',
  tipWentToYourAccount: 'Napiwek trafił na Twoje konto',
  errMessage: 'Ups, coś poszło nie tak. Spróbuj ponownie',
  errMessage_blik: 'Ups, sprawdź czy wprowadziłeś poprawny kod BLIK i spróbuj ponownie',
  errMessage_maxReceipt: 'Maksymalna wysokość rachunku to',
  errMessage_minReceipt: 'Minimalna wysokość rachunku to',
  errMessage_maxTip: 'Maksymalna wysokość napiwku to',
  errMessage_minTip: 'Minimalna wysokość napiwku to',
  errMessage_Payment: 'Upss transakcja odrzucona, coś poszło nie tak.<br /><br />Spróbuj ponownie',
  choosePaymentType: 'Wybierz formę płatności',
  giveTip: 'Wręczenie napiwku',
  writeBlikCode: 'Podaj kod BLIK',
  thankYouFortTIP: 'Dziękuję za napiwek',
  thankYou: 'Dziękujemy',
  thankYouPaymentRegister: 'Zlecenie zostało przyjęte do realizacji.',
  version: 'Wersja',
  whats_new_in_app: 'Co nowego w aplikacji',
  system_requirements: 'Wymagania systemowe',
  system_requirements_list: `
    <p>Aby korzystać z ofiara.org należy zapewnić:</p>
    <ol style="padding-left: 16px;">
      <li style="margin: 10px 0;">Telefon komórkowy lub inne urządzenie mobilne z systemem Android lub iOS (najlepiej od wersji Android 9.0 lub iOS 12.2)</li>
      <li style="margin: 10px 0;">Zaktualizowaną przeglądarkę internetową zainstalowaną na urządzeniu mobilnym (Chrome lub Safari), obsługującą strony zabezpieczone wysokiej jakości certyfikatem SSL</li>
      <li style="margin: 10px 0;">Bezpieczny, bezprzewodowy dostęp do internetu</li>
      <li style="margin: 10px 0;">Aparat foto preinstalowany na urządzeniu mobilnym, obsługujący funkcję scanowania QR kodów lub inną aplikację obsługującą scanowanie QR kodów</li>
      <li style="margin: 10px 0;">Konto poczty elektronicznej email, umożliwiające przyjmowanie maili systemowych ofiara.org lub/i konto Facebook umożliwiające logowanie do ofiara.org</li>
      <li style="margin: 10px 0;">Rachunek bankowy założony w banku lub SKOK operującym na terytorium Rzeczypospolitej Polskiej</li>
      <li style="margin: 10px 0;">Zalecana jest również instalacja oprogramowania antywirusowego.</li>
    </ol>
  `,
  terms: 'Dokumenty prawne',
  terms_and_rules: 'Dokumenty prawne platformy ofiara.org',
  terms_text: `
    <div style="margin-bottom: 20px;">
      <div style="display: inline-block; width: 68%;">
        <a style="color: black" href="https://parafia.ofiara.org/documents/Regulamin_OfiaraOrg_-_4.11.2020.pdf">Regulamin ofiara.org</a><br />
        <span style="font-size: 0.9rem">4 listopada 2020 roku</span>
      </div>
      <div style="display: inline-block; width: 30%; text-align: right;">
        <img height="50" style="vertical-align: sub" src="https://parafia.ofiara.org/img/Adobe-PDF-File-Icon-01.png" />
      </div>
    </div>
    <div style="margin-bottom: 20px;">
      <div style="display: inline-block; width: 68%;">
        <a style="color: black" href="https://parafia.ofiara.org/documents/Polityka_prywatnosci_OfiaraOrg_-_4.11.2020.pdf">Polityka prywatności</a><br />
        <span style="font-size: 0.9rem">4 listopada 2020 roku</span>
      </div>
      <div style="display: inline-block; width: 30%; text-align: right;">
        <img height="50" style="vertical-align: sub" src="https://parafia.ofiara.org/img/Adobe-PDF-File-Icon-01.png" />
      </div>
    </div>
    
  `,  
  tipCodeIndividualText: 'Zestaw startowy, umożliwiający przyjmowanie datków, składa się z: <ol><li>indywidualny kod QR<li>unikalny link<li>spersonalizowany przycisk HTML/CSS</ol>',
  howToUse: {
    getPackage: '1. Pobierz zestaw startowy',
    getPackageIntro: 'Unikalny kod QR, link oraz przycisk umożliwiają przyjmowanie ofiar na odległość np. w czasie transmisji mszy świętej on-line...',
    getPackageText: 'Unikalny kod QR, link oraz przycisk umożliwiają przyjmowanie ofiar na odległość np. w czasie transmisji mszy świętej on-line lub w innym dowolnym momencie. Zestaw startowy przesłaliśmy w mailu tuż po założeniu konta na platformie ofiara.org. Można wysłać go ponownie poprzez kliknięcie właściwego przycisku w zakładce „Zestaw startowy"',
    distributePackage: '2.  Umieść kod QR i link w Twoich mediach',
    distributePackageIntro: 'Kod QR umieszczony w narożniku ekranu nie rozprasza uczestnika mszy świętej w internecie...',
    distributePackageText: 'Kod QR umieszczony w narożniku ekranu nie rozprasza uczestnika mszy świętej w internecie. Można również zamieścić go tylko na czas zbierania tacy. Po zakończeniu mszy świętej nie będzie przeszkodą nawet w większych rozmiarach. Natomiast link i przycisk HTML/CSS może być stale umieszczony na stronie internetowej parafii lub na mediach społecznościowych typu Facebook, YouTube etc.',
    share: '3.  Powiadom wiernych o możliwości eTacy',
    shareIntro: 'Jako wierni uczestniczący w mszach świętych on-line zdajemy sobie sprawę, że pandemia ograniczyła możliwości utrzymania kościoła...',
    shareIntroText: 'Jako wierni uczestniczący w mszach świętych on-line zdajemy sobie sprawę, że pandemia ograniczyła możliwości utrzymania kościoła wiążące się z dużymi kosztami. Koronawirus spowodował, że stajemy się coraz bardziej otwarci na wszelkie nowinki technologiczne ułatwiające normalne życie w tych trudnych i dziwnych czasach. Rzeczy, o których nawet nie myśleliśmy wczoraj stają się dziś elementem nowej normalności i prawdopodobnie zostaną z nami na dłużej',
  },
  startingPackage: {
    materials: 'Materiały do pobrania',
    download: 'Wyślij zestaw na email',
    URL: 'Link do przyjmowania ofiar',
    copy: 'Skopiuj link do schowka',
    urlInfo: 'Kliknij w przycisk aby skopiować link. Możesz wkleić go do maila lub SMS.',
    instruction: 'Aby otrzymać zestaw startowy mailem kliknij na przycisk poniżej.',
    qrintro: 'Indywidualny kod QR',
    copyConfirm: 'Link skopiowany'
  },
  tipCodeContent: 'Zestaw startowy do pobrania. Składa się z wygenerowanego QR Kodu, linka do przyjmowania ofiar oraz przycisku HTML umożliwiającego przyjęcie ofiary.',
  tipCodeContentThankyou: 'Z góry dziękuję i polecam się na przyszłość!',
  new_update: 'Dostępna jest nowa wersja! Uruchom ponownie aplikację.',
  reload: 'Reload',
  tipCodeContentGive: 'Jeżeli jesteś zadowolony/a z obsługi przyznaj mi napiwek. Z góry dziękuję i polecam się na przyszłość!',
  giveTipButton: 'Przekaż napiwek',
  valueOfTipHeight: 'Proszę podać kwotę napiwku',
  entry: 'Dalej',
  save: 'Zapisz',
  account_data: 'Dane konta',
  user_id: 'User ID',
  payback_data: 'Dane do wypłaty',
  bank_account_number: 'Rachunek bankowy parafii',
  account_number: 'Numer rachunku',
  receiver_name: 'Imię i nazwisko',
  pesel: 'PESEL',
  name_and_surname: 'Imię i nazwisko',
  street: 'Ulica',
  parishName: 'Nazwa parafii',
  parishRegon: 'REGON',
  parishNIP: 'NIP',  
  building_number: 'Numer budynku / lokalu',
  zip_code: 'Kod pocztowy',
  city: 'Miejscowość',
  wrong_account_number: 'Niepoprawny numer rachunku',
  required: 'Pole wymagane',
  type_name_and_surname: 'Podaj imię i nazwisko',
  walletOwnerTip: 'Aby rozliczyć TipBoxa musisz być jego leaderem.',
  individualMeasures: 'Zgromadzone ofiary',
  walletMoreThanValue: 'Aby zlecić wypłatę musisz zgromadzić minimum',
  walletFillUserData: 'Aby zlecić wypłatę środków uzupełnij dane finansowe w profilu',
  walletSubmit: 'Środki zostaną przelane na wskazany w danych profilowych rachunek bankowy.',
  walletSubmitTipBox: 'Środki zostaną przekazane na konta indywidualne osób podłączonych do tego TipBoxa.',
  walletTransfer: 'Zlecam wypłatę',
  transferFunds: 'Aby dokonać dystrybucji środków z TipBoxa trzeba zgromadzić co najmniej',
  payout: 'Wypłata',
  save_error: 'Coś poszło nie tak. Dane nie zapisane.',
  save_success: 'Dane zostały zapisane.',
  no_data: 'Brak danych do wyświetlenia',
  stepperStatus: 'Status konta',
  information: 'Informacja',
  noActiveTipbox: 'Nie masz aktywnego TipBoxa. Aby aktywować wybierz z listy TipBoxów.',
  goFurther: 'Przejdź do',
  tipBox: 'TipBox',
  tipBoxes: 'TipBoxy',
  invite: 'Zaproś',
  createNew: 'Nowy',
  namePlaceholder: 'Nazwa',
  newTipBoxName: 'Nazwa TipBox musi zawierać co najmniej 3 znaki',
  create: 'Utwórz',
  cumulateTips: 'Teraz zbierasz napiwki do TipBoxa:',
  selectTipBox: 'Wybierz TipBox do zaproszenia:',
  tipBoxInviteInfo: 'Zeskanuj QR kod, żeby dołączyć do TipBoxa. Aby dołączyć odbiorca musi posiadać konto i aplikację PayTip.',
  tipboxReceiveIndividual: 'Zbieram indywidualnie',
  empty: 'Brak',
  members: 'Uczestnicy',
  leader: 'Leader',
  toTipbox: 'Do TipBox',
  fromTipbox: 'Z TipBox',
  withdrawTipbox: 'Wypłata z tipbox',
  verificationTip: 'Weryfikacja',
  sharedTip: 'Shared tip',
  print_and_download_tipcodes: 'Pobierz i wydrukuj TipKody',
  set: 'Zestaw',
  set_info: 'Dodaj TipKod do każdego zamówienia',
  badge: 'Plakietka',
  badge_info: 'Informacja do zawieszenia w lokalu',
  do_you_like_it: 'Podoba Ci się?',
  leave_tip: 'Zostaw napiwek',
  print_info_text: 'Jeśli chcesz docenić nasz zespół zeskanuj QR kod i postępuj zgodnie z instrukcją.',
  scan_me: 'Zeskanuj mnie',
  inviting_tipBox: 'Trwa dołączanie do TipBoxa...',
  congratulations: 'Gratulacje',
  added_to_tipBox: 'Dołączyłeś/aś do TipBoxa',
  please_choose: 'Proszę wybrać',
  where_collect: 'Gdzie zbierasz datki',
  document_type: 'Typ dokumentu',
  document_number: 'Seria i numer dokumentu',
  nationality: 'Obywatelstwo',
  balance_warning: 'Uzbierałeś w PayTip kwotę 6 tys. zł - zastanów się nad wypłatą.<br />Z uwagi na regulacje KNF będziemy musieli przelać pieniądze na Twój rachunek bankowy zanim osiągniesz poziom 8 tys. zł',
  accept: 'Akceptuję',
  cookies_msg: 'Informujemy, iż w celu optymalizacji treści dostępnych w naszym serwisie, dostosowania ich do Państwa indywidualnych potrzeb korzystamy z informacji zapisanych za pomocą plików cookies na urządzeniach końcowych użytkowników. Dalsze korzystanie z naszego serwisu internetowego, bez zmiany ustawień przeglądarki internetowej oznacza, iż użytkownik akceptuje stosowanie plików cookies.',
  no_account_data: 'Brak danych',
  data_provided: 'Wprowadzono dane',
  in_verification: 'W trakcie weryfikacji',
  approved: 'Potwierdzony',
  accountData_step1: 'Twoje konto nie jest jeszcze w pełni aktywne. Możesz przyjmować ofiary, ale nie będziesz mógł ich wypłacić.<br />Uzupełnij dane do wypłaty.',
  accountData_step2: 'Dziękujemy, że podałeś dane!<br />Teraz musimy zweryfikować czy są poprawne.<br />Poczekaj cierpliwie na aktywację konta.',
  accountData_step3: 'Twoje dane są weryfikowane. Prosimy o chwilę cierpliwości.',
  accountData_step4: 'Wystąpił błąd danych. Nadal możesz otrzymywać ofiary, ale nie możesz ich wypłacić.<br />Skontaktuj się z nami, żeby poznać przyczynę.',
  accountData_step4_success: 'Konto zweryfikowane i aktywne! Od teraz możesz wypłacać zgromadzone datki.',
  is_blocked: 'Zablokowany',
  blocked: 'Blokada',
  make_payment: 'Wykonaj przelew',
  repeat_payment: 'Ponów przelew',
  payment_why: 'Dlaczego muszę wykonać przelew?',
  payment_info: `
                  <p>Przelew weryfikacyjny pozwoli nam zweryfikować podane dane. Mamy wtedy pewność, że twoje napiwki trafią na właściwe konto.</p>
                  <p><span style="color: black; font-weight: 700;">Regulacje prawne</span><br />Ze względu na regulacje KNF konieczna jest pełna weryfikacja osoby korzystającej z aplikacji przed wykonaniem pierwszej wypłaty. Regulacje te mają na celu przeciwdziałanie praniu brudnych pieniędzy oraz finansowaniu terroryzmu.</p>
                  <p>Ponieważ za pomocą PayTip otrzymujesz realne pieniądze, jako instytucja finansowa musimy respektować te przepisy i w zgodzie z nimi w bezpieczny sposób przelać pieniądze na Twoje konto.</p>
                  <p><span style="color: black; font-weight: 700;">Jak wygląda weryfikacja?</span><br />Weryfikacja składa się z kilku kroków: Aby zweryfikować Twoje dane prosimy Cię o przelew na niewielką kwotę - taki sam przelew jakbyś robił zakupy w dowolnym sklepie internetowym. Otrzymując przelew pozyskamy z banku również dane osoby zlecającej przelew czyli Twoje dane. Zweryfikujemy, czy dane otrzymane z Banku zgadzają się z danymi wprowadzonymi przez Ciebie do aplikacji PayTip (dlatego, jeżeli na ekranie Profil podałeś niepoprawne dane popraw je zanim rozpoczniesz procedurę weryfikacji). Po pozytywnej weryfikacji będziesz mógł wypłacić środki zebrane w PayTip. W razie weryfikacji negatywnej prosimy o kontakt.</p>
                  <p><span style="color: black; font-weight: 700;">Czy odzyskam wpłacone pieniądze?</span><br />Wpłacone w ramach weryfikacji 2,00 zł zostaną zarejestrowane jako napiwek na Twoim indywidualnym koncie PayTip tak jak każdy inny otrzymany napiwek.</p>
                `,
  payback_info: 'Aby wypłacić napiwki musisz zweryfikować konto bankowe, na które przelejemy środki.',
  address: 'Dane parafii',
  representant_data: 'Dane reprezentanta parafii',
  assigned_to_bank_account: 'z umowy bankowej',
  please_verify_email: 'Proszę zweryfikuj swój email',
  walletBlockInfo: 'Dbamy o bezpieczeństwo Twoich środków. Aby odblokować możliwość wypłaty <a href="&#x6D;&#x61;&#x69;&#x6C;&#x74;&#x6F;&#x3A;&#x69;&#x6E;&#x66;&#x6F;&#x40;&#x6F;&#x66;&#x69;&#x61;&#x72;&#x61;&#x2E;&#x6F;&#x72;&#x67;">skontaktuj</a> się z biurem klienta.',
  payback_data_warning: `
    Aby sprawnie przejść przez proces weryfikacji przygotuj wcześniej:<br /><br />
    <strong>Numer konta bankowego</strong> - koniecznie musi być to konto przypisane do parafii<br /><br />    
    <strong>Dokument bankowy</strong> - z którego wynika, że numer rachunku podany w formularzu należy do parafii, np. fragment umowy, fragment wyciągu bankowego lub inny dokument zawierający dane/nazwę parafii oraz numer konta; może to być również zrzut ekranu z aplikacji bankowej zawierający powyższe dane<br /><br />
    <strong>Dokument potwierdzający możliwość reprezentowania parafii</strong> - np. mianowanie lub nominacja na proboszcza parafii lub inny dokument, przedstawiany w banku w celu otwarcia rachunku<br /><br />
    <strong>Dowód tożsamości</strong> - scan lub zdjęcie Twojego dowodu osobistego lub paszportu<br /><br />
    <strong>Twoje selfie</strong> - najlepiej wykonane przed chwilą.
    
  `,
  warning: 'UWAGA',
  send_to_verification: 'Wyślij do weryfikacji',
  add_photo_or_scan_of_document: 'Załącz zdjęcie lub skan dokumentu',
  add_front_of_document: 'Załącz front dowodu osobistego',
  add_back_of_document: 'Załącz tył dowodu osobistego',
  allowed_files: 'Dozwolone pliki',
  max_file_weight: 'Maks. waga pliku',
  add_selfie: 'Załącz Twoje selfie',
  add_passport_document: 'Załącz stronę z danymi z paszportu',
  delete: 'Usuń',
  file: 'Plik',
  new_password: 'Nowe hasło',
  reset_password_not_valid_link: 'Link do resetowania hasła wygasł :(.<br />Aby zresetować hasło proszę użyj ponownie funkcji resetowania hasła w aplikacji.',
  change_password_success: 'Hasło zostało pomyślnie zmienione.<br />Możesz zalogować się do aplikacji.',
  verification: 'Weryfikacja',
  email_verification_success: 'Twój email został zweryfikowany.<br />Możesz zalogować się do aplikacji.',
  email_verification_fail: 'Prośba o weryfikację adresu email wygasła lub link został wykorzystany.',
  wrong_url_no_params: 'Błędny adres. Brak poprawnych parametrów.',
  no_parameters: 'Brak wymaganych parametrów',
  add_bank_document: 'Załącz dokument bankowy',
  add_parish_document: 'Załącz dokument mianowania',
  tip_went_to_your_account: 'Napiwek trafił na Twoje konto.',
  actual_balance: 'Aktualne saldo',
  awaiting_info: 'Adres e-mail jest niezbędny do rejestracji i odbioru napiwku.',
  awaiting_info_logged: 'Adres e-mail jest niezbędny do odbioru napiwku.',
  receive_tip: 'Odbierz napiwek',
  type_email_address: 'Podaj adres e-mail',
  type_correct_email: 'Podaj prawidłowy adres',
  tip_was_send: 'Napiwek został wysłany',
  check_your_mailbox_and_receive_tip: 'Sprawdź skrzynkę mailową i odbierz swój napiwek!',
  error: 'Błąd',
  no_required_parameters: 'Brak wymaganych parametrów',
  transfer: 'Transfer',
  tipBox_transfer: 'Rozlicz TipBox',
  promoTip: 'PromoTip',
  welcome: {
    title: 'Jak korzystać?'
  },
  what_is_promotip: 'Czym jest PromoTip?',
  our_works_promotip: 'Nasze działania PromoTip',
  profits_promotip: 'Korzyści z PromoTip',
  what_is_promotip_content: `
    PromoTip to idea mająca na celu promowanie pozytywnych zachowań napiwkowych.
    Każdego dnia widzimy kelnerów, fryzjerów, barmanów i innych przedstawicieli branży usługowej wspaniale obsługujących swoich klientów,
    także pod koniec męczącego wieczoru. Niektórzy umieją docenić wysoką jakość obsługi hojnym tipem, choć zdarzają się także otwarci przeciwnicy wręczania napiwków.
    Chcemy uświadamiać i edukować jak pozytywny efekt wywiera zwyczajowe przekazanie tipa, szczególnie gdy idzie on bezpośrednio do osoby docenionej.
    To dlatego właśnie stworzyliśmy platformę PayTip, bo widzimy, że w świecie zanikającej gotówki pracownicy usług tracą coraz większą część swoich dochodów.
  `,
  our_works_promotip_content: `
    <ul style="padding-left: 16px;">
      <li style="margin: 10px 0;">Prowadzimy kampanie promocyjne i edukacyjne w zakresie pozytywnych postaw napiwkowych</li>
      <li style="margin: 10px 0;">Dostarczamy nowoczesne i bezpieczne narzędzia umożliwiające wręczanie napiwków</li>
      <li style="margin: 10px 0;">Uświadamiamy klientom pozytywne efekty tipowania</li>
      <li style="margin: 10px 0;">Szukamy najkorzystniejszych rozwiązań prawno podatkowych dla rynku napiwkowego</li>
      <li style="margin: 10px 0;">Pomagamy właścicielom biznesów usługowych wykorzystać potencjał tkwiący w mądrym i sprawiedliwym systemie napiwkowym</li>
    </ul>
  `,
  profits_promotip_content: `
    <ul style="padding-left: 16px;">
      <li style="margin: 10px 0;">Zwiększamy Twoje wpływy z napiwków</li>
      <li style="margin: 10px 0;">Demokratyzujemy rynek tipów</li>
      <li style="margin: 10px 0;">Podwyższamy jakość usług dla klientów</li>
      <li style="margin: 10px 0;">Umożliwiamy bezpieczne przyjmowanie napiwków, nawet gdy klient nie ma przy sobie gotówki</li>
      <li style="margin: 10px 0;">Kierujemy tipy do ich prawdziwych adresatów :)</li>
    </ul>
  `,
  what_is_promotip_intro: 'PromoTip to idea za którą kryje się promocja i rozwijanie kultury napiwkowej...',
  our_works_promotip_intro: 'Kampanie promocyjne i edukacyjne w zakresie pozytywnych postaw...',
  profits_promotip_intro: 'Zwiększamy Twoje wpływy z napiwków. Demokratyzujemy rynek tipów...',
  more: 'Więcej>>',
  less: 'Zwiń',
  blik_info1: 'Przejdź do Twojej aplikacji bankowej i wygeneruj kod BLIK',
  blik_info2: 'Potwierdź kod BLIK w Twojej aplikacji bankowej.',
  blik_info_first_step: 'Prosimy o chwilę cierpliwości i nie wyłączanie aplikacji, napiwek już płynie...',
  blik_info_new_tip1: 'Operacja wymaga wygenerowania kodu BLIK w aplikacji bankowej.<br /><br />Poproś osobę wręczającą o podanie kodu.',
  blik_info_new_tip2: 'Poproś osobę wręczającą o zaakceptowanie kodu BLIK w aplikacji bankowej.',
  desktop_dialog_title: 'Przejdź na urządzenie mobilne',
  desktop_dialog_content: 'Aplikacja jest przeznaczona na urządzenia mobilne. Zeskanuj kod QR za pomocą swojego smartfona.',
  sameDay_subTitle1: 'Jeżeli jesteś zadowolony/a<br />z obsługi przyznaj mi',
  sameDay_subTitle2: 'napiwek',
  sameDay_subTitle3: '<br />za pomocą tego kodu QR.',
  dearSir: 'Szanowny Pan',
  thankyou: 'Dziękuję',
  scanQrCode: 'zeskanuj<br />kod qr',
  typeTipAmount: 'wpisz kwotę<br />napiwku',
  authorizeTransaction: 'autoryzuj<br />transakcję',
  tipsDirectlyOnAccount: 'napiwki trafiają bezpośrednio<br />na konto kelnera',
  transactionSafety: 'bezpieczeństwo transakcji',
  version_whats_new: 'Co nowego w aplikacji',
  version_whats_new_list: `
    <p>Poprawki wydajności i stabilności oraz:</p>
    <ol style="padding-left: 16px;">
      <li style="margin: 10px 0;">Dwa tryby podziału Puszki - równy i kwotowy</li>
      <li style="margin: 10px 0;">Przyjmowanie datków Apple Pay</li>      
    </ol>
  `,
  navigation: {
    settle: 'Rozlicz TipBox'
  },
  settle: {
    title: 'Rozliczenie',
    editTitle: 'Wysokość kwoty',
    editIntro: 'Wprowadź kwotę dla:',
    subTitle: 'TipBox',
    go: 'Rozlicz TipBox',
    listTitle: ' ',
    remainingTitle: ' ',
    remainingLabel: 'Pozostanie w TipBox',
    info1: 'Środki zostaną przekazane na konta indywidualne osób podłączonych do tego TipBoxa.',
    info2: 'Możesz zmienić kwotę klikając wybraną osobę z listy powyżej.',    
    error1: 'Możesz rozdzielić maksymalnie kwotę, którą zebraliście w TipBox.',
    error2: 'Kliknij w listę powyżej, aby poprawnie rozliczyć TipBox.',
    save: 'Zapisz',
    cancel: 'Anuluj',
    thankYouTitle: 'Gratulacje !',
    thankYou: 'Rozliczenie TipBox zakończone sukcesem.',

  },
  opus: {
    hello: 'Szczęść Boże',
    intro: 'niniejsza ofiara zostanie przeznaczona na utrzymanie naszego kościoła.<br/>Dziękujemy za troskę o wspólne dobro.',
    cta: 'Złóż ofiarę',
    title: 'Ofiara na utrzymanie naszego kościoła',
    amount: 'Wysokość ofiary',
    thankYou: 'Bóg zapłać za złożoną ofiarę',
    thankYou2: 'Ofiary tu składane w całości przeznaczone są na utrzymanie naszej świątyni.',
    pay: 'Składam ofiarę',
    desktop_dialog_title: 'Aplikacja zoptymalizowana dla urządzeń mobilnych',
    desktop_dialog_content: 'Dla najlepszego komfortu korzystania z aplikacji użyj przeglądarki w Twoim telefonie komórkowym.',
    desktop_dialog_content_link: 'Link do strony:',
    errMessage_maxTip: 'Maksymalna wysokość ofiary to',
    errMessage_minTip: 'Minimalna wysokość ofiary to',
    proceedToPaymentTypes: 'Dalej',
    blik_info2: 'Potwierdź kod BLIK w Twojej aplikacji bankowej.',
    blik_info_first_step: 'Prosimy o chwilę cierpliwości i nie wyłączanie aplikacji, ofiara jest przygotowywana...',    
    blikButtonAlt: 'Płatność BLIK'
  },
  activate: {
    titleSuccess: 'Gratulacje!',
    title: 'Aktywacja konta',
    email: 'Email',
    emailPlaceholder: 'Podaj swój adres email',
    code: 'Kod aktywacyjny',
    codePlaceholder: 'Wpisz kod z maila (6 cyfr)',
    proceed: 'Wyślij',
    message: 'Na twojego maila wysłaliśmy kod aktywacyjny. Sprawdź skrzynkę pocztową i wpisz otrzymany kod.',
    message2: 'Jeżeli nie otrzymasz wiadomości w ciągu kilku sekund sprawdź folder Spam.',
    messageSuccess: 'Teraz możesz w pełni korzystać z aplikacji.',
    login: 'Przejdź do logowania'
  }
};
