import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AnimatedSwitch } from 'react-router-transition';
import styles from './App.module.scss';
import { initFirebase } from 'config/Firebase';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import { BackendApp } from 'libs/App';
import Loading from 'components/loading/Loading';
import Notification from 'components/notification/Notification';
import { isMobile } from 'react-device-detect';

import AppHeader from 'components/app_header/App_header';
import { Routes } from 'config/Routes';

import { UserProvider } from 'context/user/User';
import CookiesInfo from 'components/cookiesInfo/CookiesInfo';
import { eMail } from 'utils/validators';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import dialogQRCode from './assets/dialog_qrcode.png';
import QRCode from 'qrcode';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-168920618-3');
ReactGA.pageview(window.location.pathname + window.location.search);

// Initialize firebase
initFirebase();
const backendLib = BackendApp();

const KEY = 0;
const VALUE = 1;

// Type of account
const PROFILE = `${process.env.REACT_APP_PROFILE}`;
const SLOIK = `${process.env.REACT_APP_SLOIK}`;

interface User {
  isLoggedIn: boolean;
  userId: string;
  iOS: boolean;
  email: string;
  receiverName: null | string;
  singleModule: boolean;
  as: string;
  tipBoxName: string;
}

const App = () => {
  const { t } = useTranslation();
  let stateOfModule = true;

  // Read localStorage and set up switch button depends on account type
  // If there is no LS set singleModule means Indyvidual or Sloik Mode
  // Profile - 16, Słoik - 22
  if (localStorage.getItem('sm') && localStorage.getItem('sm') === SLOIK) { stateOfModule = false; }

  const [newServiceWorker, setNewServiceWorker] = useState(false);

  const [currentUser, setCurrentUser] = useState<User>({
    isLoggedIn: false,
    userId: '',
    iOS: false,
    email: '',
    receiverName: null,
    singleModule: true,
    as: '',
    tipBoxName: '',
  });

  const [configuration, setConfiguration] = useState({
    withdrawMinValue: null,
    tipReceiptMaxValue: null,
    tipMaxValue: null,
    tipMinValue: null,
    tipReceiptMinValue: null,
    withdrawTipboxMinValue: 0,
    balanceWarningThreshold: null,
    kycVerificationValue: null,
  });
  const [responseReady, setResponseReady] = useState(false);
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const [modal, setModal] = useState(false);
  const [showCookiesInfo, setShowCookiesInfo] = useState(false);
  const [showVerificationInfo, setShowVerificationInfo] = useState(false);
  const [openDialog, setOpenDialog] = useState(!isMobile);
  const [desktopQRCode, setDesktopQRCode] = useState<string>('');

  const referralLink = localStorage.getItem('referralLink') || '';
  const url = window.location.pathname + window.location.search;

  const [opusMode, setOpusMode] = useState<boolean>(window.location.pathname.toLowerCase().includes('donatio'));

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const userAuth = (user: any) => {
    const isFacebook = user.providerData.find((provider: {providerId: string}) => provider.providerId === 'facebook.com');
    if (isFacebook || user.emailVerified) {
      setCurrentUser((d) => ({
        ...d,
        isLoggedIn: true,
        userId: user.uid,
        iOS: isIOS,
        email: user.email,
        singleModule: stateOfModule,
      }));
      localStorage.setItem('paytipUser', user.email);

      backendLib.backendLoadUser(user.uid).then((userData: any) => {
        if (userData) {
          if (userData.as) {
            getTipBoxName(user.uid, userData.as);
            setCurrentUser((d) => ({
              ...d,
              as: userData.as,
              singleModule: false,
            }));
          }
          setCurrentUser((d) => ({
            ...d,
            receiverName: userData.dn || '',
          }));
        }
        setResponseReady(true);
      });
    } else {
      console.log('Please verify your email!');
      setShowVerificationInfo(true);
      backendLib.userSignOut();
      setResponseReady(true);
      setCurrentUser({
        isLoggedIn: false,
        userId: '',
        iOS: isIOS,
        email: '',
        receiverName: null,
        singleModule: stateOfModule,
        as: '',
        tipBoxName: '',
      });
    }
  };

  // Show menu button only for logged in users
  useEffect(() => {
    backendLib.initialized();
    // Check if user is logged
    setResponseReady(false);
    firebase.auth().onAuthStateChanged((user: any) => {
      const searchParameters = window.location.search.replace('?', '').split('=');
      // If user comes from giver and has fulfilled conditions below after some time try to redirect user from sign-up page.
      // This time is required for backend operation. Backend changes emailVerified to true.
      // Redirection after some time fires onAuthStateChanged again an user check can be done one more time
      const isUserFromGiver = window.location.pathname === '/awaiting-typ' ||
        (window.location.pathname === '/sign-up' && searchParameters.length && searchParameters[KEY] === 'e' && searchParameters[VALUE].match(eMail));

      if (user) {
        setShowVerificationInfo(false);
        const isFacebook = user.providerData.find((provider: {providerId: string}) => provider.providerId === 'facebook.com');
        if (isFacebook || user.emailVerified) {
          userAuth(user);
        } else {
          if (isUserFromGiver) {
            setTimeout(() => {
              window.location.href = '/giver-sign-up-finish';
            }, 3000);
          } else {
            userAuth(user);
          }
        }
      } else {
        // No user is signed in.
        console.log('Not logged');
        // If user arrives from tipbox-invite and is not logged in then store his previous location
        storeLocation();
        setResponseReady(true);
        setCurrentUser({
          isLoggedIn: false,
          userId: '',
          iOS: isIOS,
          email: '',
          receiverName: null,
          singleModule: stateOfModule,
          as: '',
          tipBoxName: '',
        });
      }
      // TODO: Make get all config parameters method
      backendLib.loadConfig('withdraw_min_value')
        .then((val: number[]) => setConfiguration((prevState: any) => ({...prevState, withdrawMinValue: val[0]})));
      backendLib.loadConfig('tip_receipt_max_value')
        .then((val: number[]) => setConfiguration((prevState: any) => ({...prevState, tipReceiptMaxValue: val[0]})));
      backendLib.loadConfig('tip_max_value')
        .then((val: number[]) => setConfiguration((prevState: any) => ({...prevState, tipMaxValue: val[0]})));
      backendLib.loadConfig('tip_min_value')
        .then((val: number[]) => setConfiguration((prevState: any) => ({...prevState, tipMinValue: val[0]})));
      backendLib.loadConfig('tip_receipt_min_value')
        .then((val: number[]) => setConfiguration((prevState: any) => ({...prevState, tipReceiptMinValue: val[0] })));
      backendLib.loadConfig('withdraw_tipbox_min_value')
        .then((val: number[]) => setConfiguration((prevState: any) => ({...prevState, withdrawTipboxMinValue: val[0] })));
      backendLib.loadConfig('balance_warning_threshold')
        .then((val: number[]) => setConfiguration((prevState: any) => ({...prevState, balanceWarningThreshold: val[0] })));
      backendLib.loadConfig('kyc_verification_value')
        .then((val: number[]) => setConfiguration((prevState: any) => ({...prevState, kycVerificationValue: val[0] })));
    });

    // Listen to new version
    window.addEventListener('newContentAvailable', () => {
      // Then show notification
      setNewServiceWorker(true);
    });

    if (!window.matchMedia('(display-mode: standalone)').matches || !(window as any).navigator.standalone) {
      setTimeout(() => setShowCookiesInfo(!localStorage.getItem('cookieAccepted')), 1500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps    
  }, []);

  // Clear referralLink after redirection to destination location
  // TODO: Consider move to "component did mount"
  useEffect(() => {
    if (currentUser.isLoggedIn && referralLink && (window.location.pathname + window.location.search) === referralLink) {
      localStorage.removeItem('referralLink');
      console.log('Redirect to referral');
    }
  }, [currentUser, referralLink]);

  const RoutesBuilder = () => (
    // See Routes.tsx
    Routes.map((route, index) => {
      if (route.role === 3) {
        return (<Route key={index} path={route.path} exact={route.exact} component={route.component} />);
      }

      if (currentUser.isLoggedIn) {
        if (route.role === 2) {
          return (<Route key={index} path={route.path} exact={route.exact} component={route.component} />);
        } else {
          // If referral Link is set redirect user to it
          if (referralLink && window.location.pathname === '/login') {
            return (<Route key={index} path={route.path} exact={route.exact}><Redirect to={referralLink} /></Route>);
          }
          return (<Route key={index} path={route.path} exact={route.exact}><Redirect to="/welcome" /></Route>);
        }
      }

      if (!currentUser.isLoggedIn && route.role === 1) {
        return (<Route key={index} path={route.path} exact={route.exact} component={route.component} />);
      } else {
        return (<Route key={index} path={route.path} exact={route.exact}><Redirect to="/login" /></Route>);
      }
    })
  );

  const switchAccountType = () => {
    setCurrentUser((opt: any) => {
      // Display modal, because user has not sloik option activated
      if (opt.singleModule) {
        if (!currentUser.as) {
          setModal(true);
          return ({ ...opt });
        }
        localStorage.setItem('sm', SLOIK);
        return ({ ...opt, singleModule: false });
      }

      // Set up localStorage to singleModule type, and clear activeSloik field on DB when user switchOff sloik mode
      if (!opt.singleModule) {
        localStorage.setItem('sm', PROFILE);
        return ({
          ...opt,
          singleModule: true,
        });
      }
    });
  };

  // Activate 'sloik' option
  const setAS = (id: string) => setCurrentUser((d: any) => ({...d, as: id}));

  // Set 'sloik' name
  const setTipBoxName = (name: string) => setCurrentUser((d: any) => ({...d, tipBoxName: name}));

  // Set up singleModule depends on sloik option
  const setSM = (val: boolean) => setCurrentUser((d: any) => ({...d, singleModule: val}));

  const closeModal = () => setModal(false);

  // If user arrives from tipbox-invite and is not logged in then store his previous location
  const storeLocation = () => {
    if (window.location.pathname === '/tipbox-invite') {
      localStorage.setItem('referralLink', url);
    }
  };

  // Get selected TipBox name
  const getTipBoxName = (userId: string, tipBoxId: string) => {
    firebase.database().ref(`/userShared/${userId}`).orderByChild('st').on('value', (snapshot: any) => {
      if (snapshot.val()) {
        snapshot.forEach((child: any) => {
          if (child.val().s === tipBoxId) {
            setCurrentUser((d) => ({
              ...d,
              tipBoxName: child.val().l,
            }));
          }
        });
      }
    });
  };

  const showErrorNotification = React.useMemo(() => {
    if (showVerificationInfo) {
      setTimeout(() => setShowVerificationInfo(false), 6000);
      return (
        <Notification
          type="error"
          onCloseModal={() => setShowVerificationInfo(false)}
          msg={t('please_verify_email')}
          autoHideDuration={6000}
        />
      );
    }
  }
  // tslint-disable-next-line
  , [showVerificationInfo, t]);

  useEffect(() => {
    generateQRCode(window.location.origin+url);
  }, []);

  const generateQRCode = (url: string) => {
    QRCode.toDataURL(url)
      .then((code: string) => {
        setDesktopQRCode(code);
      })
      .catch(() => {
      });
  };

  return (
    <UserProvider
      value={{
        currentUser,
        setAS,
        setSM,
        setTipBoxName,
        configuration,
      }}
    >
      <Router>
        <AppHeader
          showMenu={currentUser.isLoggedIn}
          changed={switchAccountType}
          modal={modal}
          closeModal={closeModal}
        />
        <AnimatedSwitch
          atEnter={{ opacity: 0 }}
          atLeave={{ opacity: 0 }}
          atActive={{ opacity: 1 }}
          className={styles.switchWrapper}
        >
          {/* Display spinner when response is not ready yet but not when entering intro page */}
          {!responseReady && window.location.pathname !== '/' ? <Loading /> : RoutesBuilder()}
        </AnimatedSwitch>
      </Router>
      {/* Show desktop pop-up */}
       
      <Dialog
      open={openDialog}
      onClose={handleDialogClose}
      className={styles.dialogOpus}
    >
      <MuiDialogTitle className={styles.dialog_title}>
        {t('opus.desktop_dialog_title')}
        <IconButton aria-label="close" onClick={handleDialogClose}>
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
      <MuiDialogContent className={styles.dialog_content}>        
        <p>
        {t('opus.desktop_dialog_content')}        
        </p>
        <p>        
        {t('opus.desktop_dialog_content_link')}
        <br/>
        <img
          className={styles.dialog_qrcode}
          src={desktopQRCode}
          alt="Use QRCode to view page on mobile device"
        />
        <br/>
        <a
          className={styles.dialog_link}
          href={window.location.origin+url}
        >
          {window.location.origin+url}
        </a>
        </p>     
      </MuiDialogContent>
    </Dialog>
      
      
      { /* Show new service worker notification */
        newServiceWorker &&
        <Notification
          type="update"
          msg={t('new_update')}
          buttonMsg={t('reload')}
          autoHideDuration={null}
          buttonFunction={() => window.location.reload(true)}
        />
      }
      {
        showVerificationInfo && showErrorNotification
      }
      {
        showCookiesInfo && <CookiesInfo />
      }
    </UserProvider>
  );
};

export default App;
