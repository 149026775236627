import React, {useState} from 'react';
import styles from './PageDialog.module.scss';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withStyles from '@material-ui/styles/withStyles';

const StyledDialog = withStyles({
  paper: {
    'background': 'linear-gradient(#DDD7E6, #FEFEFE);',   
    'font-size' : '1.4rem',
    'text-align' : 'left'
  },
})(Dialog);

const StyledDialogTitle = withStyles({
  root: {    
    'font-size' : '1.8rem',
    'font-weight' : 'bold',
    'text-align' : 'left'
  },
})(DialogTitle);

const StyledDialogContentText = withStyles({
  root: {    
    'font-size' : '1.4rem',
    'text-align' : 'left'
  },
})(DialogContentText);

const StyledButton = withStyles({
  root: {    
    'font-size' : '1.4rem'    
  },
})(Button);



interface Props {
  open: boolean;
  onActionA?: any;
  onActionB?: any;
  children?: any;
  actionA?: string;
  actionB?: string;
  title?: string;
  intro?: string;
}
/**
 * Generic dialog with two actions - either to confirm or cancel the dialog.
 * @param props.open when true dialog is presented
 * @param props.children dialog contents
 * @param props.title dialog title
 * @param props.intro dialog introductory text above contents
 * @param props.actionA confirmation label
 * @param props.onActionA callback called when user confirms
 * @param props.actionB cancellation label
 * @param props.onActionB callback called when user cancells
 */
const PageDialog = (props: Props) => {  
  const { open, onActionA, onActionB, children, actionA, actionB, title, intro } = props;    
  
  const handleCloseA = () => {
    // notify that the dialog was closed with action A
    onActionA();
  };
  const handleCloseB = () => {
    // notify that the dialog was closed with action B
    onActionB();
  };

  return (
    <div>
      <StyledDialog  open={open} onClose={handleCloseB} aria-labelledby="form-dialog-title" maxWidth='md' fullWidth={true}>
        {title ? <><StyledDialogTitle disableTypography={true} id="form-dialog-title">{title}</StyledDialogTitle></> : null}
        <DialogContent >
          {intro ? <>
            <StyledDialogContentText>
            {intro}
          </StyledDialogContentText>
          </> : null}          
          {children}
        </DialogContent>
        <DialogActions>
          {actionB && onActionB ? <>
            <StyledButton onClick={handleCloseB} color="primary">
              {actionB}
            </StyledButton>
          </> : null}
          {actionA && onActionA ? <> 
            <StyledButton onClick={handleCloseA} color="primary">
              {actionA}
            </StyledButton>
          </> : null}
          
        </DialogActions>
      </StyledDialog>
    </div>
  );
};

export default PageDialog;
