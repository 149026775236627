import React, { useState } from 'react';

import styles from './CookiesInfo.module.scss';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import InfoIcon from '@material-ui/icons/Info';
import withStyles from '@material-ui/styles/withStyles';
import { useTranslation } from 'react-i18next';

const CookiesInfo = () => {
  const { t } = useTranslation();

  const CssSnackbar = withStyles({
    root: {
      border: `none`,
      '& .MuiTypography-root': {
        color: '#404040',
        background: 'white',
        display: 'grid',
        justifyItems: 'center',
        borderRadius: '0',
      },
      '& .MuiSnackbarContent-root': {
        fontSize: '1.25rem',
        flexWrap: 'inherit',
        padding: '3px 16px',
      },
      '& .MuiSnackbarContent-action': {
        margin: '1rem',
        padding: '0',
        width: '100%',
      },
    },
  })(Snackbar);

  const CssButton = withStyles({
    '@global': {
      '.MuiButton-textSecondary:hover': {
        backgroundColor: '#f0f0f0',
      },
    },
    root: {
      color: '#2B0096',
      fontSize: '1.4rem',
      fontWeight: 700,
      border: `2px solid #2B0096`,
      textTransform: 'inherit',
    },
  })(Button);

  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  const buttonFunction = () => {
    localStorage.setItem('cookieAccepted', 'true');
    handleClose();
  };

  return (
    <CssSnackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={open}
      onClose={handleClose}
      autoHideDuration={null}
      message={t('cookies_msg')}
      action={[
        (
          <CssButton key="button" color="secondary" fullWidth onClick={buttonFunction}>
            {t('accept')}
          </CssButton>
        )
      ]}
      ClickAwayListenerProps={{ onClickAway: () => null }}
    />
  );
};

export default CookiesInfo;
