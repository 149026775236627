import React, { useEffect, useState } from 'react';
import styles from './ApplePay.module.scss';
import ButtonBase from '@material-ui/core/ButtonBase';

import firebase from 'firebase/app';
import { BackendApp } from 'libs/App';

// Initialize backend methods
const backendLib = BackendApp();

// Props types
interface Props { 
  // logic settings
  backendLib: any; // backend communication library
  amount: number; // amount that will be charged with apple pay, in cents  
  triggerPayment: boolean; // when true then Apple Pay payment will be started
  // processing handlers
  onProcessingError?: any; // will be called when payment processing occurs with two strings, error code and error message
  onProcessingSuccess?: any; // will be called when processing succeeded, will return the apple pay token
  onProcessingCancel?: any; // will be called when processing is cancelled by user
  // presentation settings
  appleSelected: boolean ; // when true then the button indicates its selected state (outline)
  buttonIconSvgName: string; // name of the asset that will be presented on the button as icon
  buttonAltText: string; // alternate text presented on button
  buttonIconHeight?: number; // icon height
  // interaction handlers
  onSelected?: any; // function called when the apple pay button is selected by user, 'applepay' string is passed to the function
}

export const ApplePay = (props: Props) => {
  const {appleSelected, buttonIconSvgName, buttonAltText, buttonIconHeight, onSelected, amount, triggerPayment, 
    backendLib, onProcessingError, onProcessingSuccess, onProcessingCancel} = props;
  const [applePaySupported, setApplePaySupported] = useState<boolean>(false);

  const KEY = 'applepay';
  const ERRORS = {
    SESSION: {k: 'SESSION_ERROR', m: 'Error processing Apple Pay'}
  }

  /**
   * Triggered when user clicks the Apple Pay button as selected payment method.
   * It does not trigger payments. It just presents the Apple Pay payment method as selected.
   * @param key 
   */
  const handleOnSelected = (key:string)=>{
    console.log('Apple Pay selected as payment method', key);
    if(onSelected)
      onSelected(key);
    else
      console.warn('User selected apple pay but no handler configured. Check onSelected property');
    if(applePaySupported)
      handlePaymentButtonClicked();
  }

  const handleOnProcessingError = (code:string, message:string)=>{
    console.log('Encountered Apple Pay processing error', code, message);
    if(onProcessingError)
      onProcessingError(code, message);
    else
      console.warn('Apple Pay processing error but no handler configured. Check onProcessingError property');
  }

  const handleOnProcessingSuccess = (appleToken: any)=>{
    console.log('Completed Apple Pay processing with token', appleToken);
    if(onProcessingSuccess)
      onProcessingSuccess(appleToken);
    else
      console.warn('Apple Pay processing error but no handler configured. Check onProcessingError property');
  }

  /**
   * When user selects to pay with apple pay we start Apple Pay payment processing
   */
  useEffect(() => {
    console.log('Checking if should trigger payment', applePaySupported, triggerPayment)
    if(applePaySupported&&triggerPayment){
      console.log('Handling Apple Pay trigger')
      handlePaymentButtonClicked();
    }
      
  },[triggerPayment])
  /**
   * Check on page load if the user supports Apple Pay
   */
  useEffect(() => {
    checkIsApplePayIsAvailable();
  },[])

  const checkIsApplePayIsAvailable = () => { 
    console.log('Checking Apple Pay Support')   
    if ((window as any).ApplePaySession) {
      // sometimes apple pay button does not show even when later it shows
      // due to this unexpected behaviour lower level of verification will be used
      // so the button will show more often on user screen
      // const canMakePayments = (window as any).ApplePaySession.canMakePayments();
      // if (canMakePayments) {
      //   setApplePaySupported(true);
      //   console.log('Apple Pay supported')
      // }else{
      //   console.log('Apple Pay not supported')
      // }

      const  promise = (window as any).ApplePaySession.canMakePaymentsWithActiveCard(process.env.REACT_APP_APPLEPAY_MERCHANT_ID);
      promise.then((canMakePayments: any) => {
        if (canMakePayments) {
          setApplePaySupported(true);
          console.log('Apple Pay operational')
        }else{
          // this is a hack, lets try again
          (window as any).ApplePaySession.canMakePaymentsWithActiveCard(process.env.REACT_APP_APPLEPAY_MERCHANT_ID)
          .then((canMakePayments: any) => {
            if (canMakePayments) {
              setApplePaySupported(true);
              console.log('Apple Pay operational but second shot')
            }else{
              setApplePaySupported(true);
              console.log('Apple Pay available but payments not enabled - trying but may not work as expected')
            }

          })          
        }
      })
      .catch((err: any) => console.log('Apple Pay not supported due to:', err));
    }else{
      console.log('No Apple Pay Session in window');
    }
  };

  const getOnValidateMerchantHandler = (session: any) => (event: any) => {
    console.log('Apple Pay - step validating merchant')
    backendLib.paymentAppleRequestPaymentSession(event.validationURL, window.location.hostname)
        .then((response: any) => {
            const sessionObject = response.data;
            console.log('Going to complete merchant validation with session', sessionObject);
            session.completeMerchantValidation(sessionObject);
        })
        .catch((err: any) => {
            console.error('Error requesting Payment Session', err);
            handleOnProcessingError(ERRORS.SESSION.k, ERRORS.SESSION.m);
            session.abort();
        });
  };

  const getOnPaymentAuthorized = (session: any) => {
    console.log('Apple Pay - step payment authorized')
    return (event: any) => {
        console.log('Payment authorized', event);
        session.completePayment((window as any).ApplePaySession.STATUS_SUCCESS);
        console.log('Apple event received', event.payment);
        handleOnProcessingSuccess(event.payment);
    };
  };

  const getOnCancel = () => {
    console.log('Payment cancelled.')
    if(onProcessingCancel)
      onProcessingCancel();
  };

  /**
   * This will actually start Apple Pay payment processing. As a result proper
   * UI elements will be presented to the user, so one can confirm or decline payment.
   */
  const handlePaymentButtonClicked = () => {
    console.log('Apple Pay - starting session')
    const request = {
        countryCode: process.env.REACT_APP_COUNTRY_CODE,
        currencyCode: process.env.REACT_APP_CURRENCY_CODE,
        supportedNetworks: process.env.REACT_APP_APPLEPAY_SUPPORTED_NETWORKS!.split(','),
        merchantCapabilities: process.env.REACT_APP_APPLEPAY_MERCHANT_CAPABILITIES!.split(','),
        total: { label: process.env.REACT_APP_GATEWAY_MERCHANT_NAME, amount: amount/100 },
    };

    const session = new (window as any).ApplePaySession(3, request);
    session.onvalidatemerchant = getOnValidateMerchantHandler(session);
    session.onpaymentauthorized = getOnPaymentAuthorized(session);
    session.oncancel = getOnCancel();

    session.begin();
  };

  return applePaySupported ? (    
    <ButtonBase key={KEY} onClick={() => handleOnSelected(KEY)}>      
      <div className={`${styles.ApplePay_paymentItem} ${appleSelected && styles.ApplePay_paymentItem__selected}`}>
        <img
          src={require(`assets/${buttonIconSvgName}.svg`)}
          height={buttonIconHeight?buttonIconHeight:"26"}
          alt={buttonAltText}
        />
      </div>         
    </ButtonBase>
  ) : null;
};

export interface PaymentResult {
  s: string; // status OK or ERROR
  r?: string; // redirect URL when 3D Secure verification is needed
  e?: string; // error message when s==ERROR
}
/**
* Processing payment using one of token based payment methods (GPay, ApplePay)
* @param beneficiaryUserId Id of the user that will receive the tip
* @param amount Tip amount in cents 
* @param token Payment token received from payment provider
* @param provider Provider code (B, BA, A, AA, G, GA)
* @returns Promise that resolves with object holding {r: redirect url - when set the app should redirect user to this url, e: error if any, s: status OK || ERROR}
*/
export const processTokenBasedTransaction = (beneficiaryUserId:string, amount:number, token:any, provider:string):Promise<PaymentResult>=>{
 // query parameter for baseURL      
 var queryParam:string = 'b='+window.location.protocol + '//' + window.location.host + window.location.pathname;
 // var requiredParams:string = getTypURLParams(tipHeightCalc, summaryInvoice);
 var base3DSReturnURL:string = process.env.REACT_APP_3DS_NOTIFY_URL || '';
 // const urlToGPay = window.location.protocol + '//' + window.location.host + window.location.pathname;
 const url3DSecure = base3DSReturnURL+'?'+queryParam; //+'&'+requiredParams;
 console.log('URL 3DS', url3DSecure);

 return backendLib.registerPayment(amount, token, beneficiaryUserId, provider, url3DSecure)
 .then((res: any) => {
   if (res.r) {
     return backendLib.store3DSecureExtraData(res.t, beneficiaryUserId, amount)
       .then(()=>{
         return {
           rp: res
         }
       })
   } 
   return {
     rp: res
   };
 })
 .then((context:any)=>{
   if(context.rp.r){
     return {
       r: context.rp.r,
       s: 'OK'
     }
   }
   else {
     return {          
       s: 'OK'
     }        
   }
 })
 .catch((err: any) => {
   console.log('Error during token payment processing', err);
   return {
     s: 'ERROR',
     e: err
   }
 });
}



