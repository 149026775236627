import React, { useEffect, useState, useContext } from 'react';
import UserConsumer from 'context/user/User';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { BackendApp } from 'libs/App';

import styles from './Logout.module.scss';

// import { ReactComponent as Logo } from 'assets/flame_transparent.svg';
import Loading from 'components/loading/Loading';

interface ContextProps {
  currentUser: {
    isLoggedIn: boolean,
  };
}

const Logout = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  const userConsumer = useContext<Partial<ContextProps>>(UserConsumer);
  const {currentUser: { isLoggedIn }}: any = userConsumer;

  useEffect(() => {
    if (isLoggedIn) {
      BackendApp().userSignOut()
      .then(() => setLoading(false))
      .catch((error: any) => {
        console.log('Error:', error);
      });
    } else {
      setLoading(false);
    }
  }, [isLoggedIn]);

  return (
    <div className={`${styles.logout} background_gradient background_gradient__gray`}>      
      <img className={`${styles.logout_icon}`} src={require(`assets/flame_transparent.svg`)} alt=""/>
      <div className={styles.logout_title}>{t('logged_out')}</div>
      <Link className={styles.logout_logoutLink} to="/login">
        {t('login_again')}
      </Link>
      {loading && <Loading />}
    </div>
  );
};

export default Logout;
