import React, { useState, useEffect } from 'react';
import * as firebase from 'firebase/app';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { ButtonStyle } from 'components/override_styles/Button';
import styles from './Confirm_email.module.scss';
import Loading from 'components/loading/Loading';
import { innerHTMLTranslation } from 'utils/utils';

// Overridden material styles
const CssButton = ButtonStyle(Button);

interface Props {
  history: {
    push: (path: string) => void,
  };
}

const ConfirmEmail = (props: Props) => {
  const { t } = useTranslation();
  const { history } = props;
  const [loading, setLoading] = useState(true);
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  const url = new URL(window.location.href);
  const mode = url.searchParams.get('mode') || '';
  const oobCode = url.searchParams.get('oobCode') || '';

  const handleLogin = () => {
    history.push('/login');
  };

  useEffect(() => {
    if (mode === 'verifyEmail' && oobCode) {
      setLoading(true);
      firebase.auth().applyActionCode(oobCode)
      .then((response) => {
        console.log('Email verified', response);
        setSuccessMsg(t('email_verification_success'));
      })
      .catch((error) => {
        console.log('error', error);
        setErrorMsg(t('email_verification_fail'));
      })
      .finally(() => setLoading(false));
    } else {
      setLoading(false);
      setErrorMsg(t('wrong_url_no_params'));
      console.log('No params');
    }
  }, [mode, oobCode, t]);

  return (
    <div className={`${styles.confirmEmail_wrapper} background_gradient background_gradient__purpure`}>
      <div className="container">
        <div className="title_log">
          {t('verification')}
        </div>
        {
          loading
          ?
          (
            <Loading />
          )
          :
          (
            <>
              <div
                className={styles.confirmEmail_info}
                dangerouslySetInnerHTML={innerHTMLTranslation(successMsg || errorMsg)}
              />
              <CssButton
                fullWidth
                size="large"
                variant="outlined"
                type="button"
                onClick={handleLogin}
              >
                {t('login')}
              </CssButton>
            </>
          )
       }
      </div>
    </div >
  );
};

export default ConfirmEmail;
