import React from 'react';
import TextField from '@material-ui/core/TextField';
import { InputFieldStyle } from 'components/override_styles/TextFieldWhite';
import { useState } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import LockOutlined from '@material-ui/icons/LockOutlined';

const CssTextField = InputFieldStyle(TextField);

interface Props {
  name?: string;
  label?: string;
  required?: boolean;
  placeholder?: string;
  errorText?: string;
  onValueChanged?: any;
  value: any;
  disabled?: boolean;
  onValidityChanged?: any;
  minLength?:number;
}

/**
 */
const PageInputPassword = (props: Props) => {    
  const {name, label, required, placeholder, errorText, onValueChanged, value, disabled, onValidityChanged, minLength} = props

  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const [edited, setEdited] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let value = event.target.value;
    
    setEdited(true);   
    // notify only when there is a valid value
         
    var validationResult = true;
    if(edited && required && !value || value.length <= 0)
      validationResult = false;
    if(minLength){
      validationResult = value.length >= minLength;
    }

    setIsInvalid(!validationResult);

    // notify new value only when the value is valid
    
    if(onValueChanged){
      onValueChanged(value);
    } 
    
    if(onValidityChanged)
      onValidityChanged(validationResult, name);   
  }
  
  return (
    <CssTextField
      fullWidth
      autoComplete="off"
      name={name}
      label={required ? `${label}*` : label}
      placeholder={placeholder}
      helperText={isInvalid ? errorText : ''}
      margin="normal"
      variant="outlined"
      type="password"
      InputLabelProps={{shrink: true}}
      onChange={handleChange}
      InputProps={{startAdornment: (<InputAdornment position="start"><LockOutlined /></InputAdornment>)}}
      error={isInvalid}
      value={value}
      disabled={disabled}
    />    
  );
};

export default PageInputPassword;
