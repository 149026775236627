import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Giver_sign_up_finish.module.scss';
import ThankYou from 'components/thankYou/ThankYou';

interface Props {
  history: {
    push: (path: string) => void,
  };
}

const GiverSignUpFinish = (props: Props) => {
  const { t } = useTranslation();
  const { history } = props;

  const redirectToMyBalance = () => history.push('/balance');

  return (
    <>
      <div className="background_gradient background_gradient__gray">
        <div className={`container ${styles.signUp_container}`}>
        <ThankYou
          img="jam_jar.svg"
          background="jam_jar_bg.svg"
          text={`${t('congratulations')}!`}
          additionalText={t('tip_went_to_your_account')}
          clicked={redirectToMyBalance}
          link={t('actual_balance')}
        />
        </div>
      </div>
    </>
  );
};

export default GiverSignUpFinish;
