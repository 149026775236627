import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './AccountActivate.module.scss';
import { innerHTMLTranslation } from 'utils/utils';
import ConfirmEmail from './Confirm_email';

interface Props {
  history: {
    push: (path: string) => void,
  };
}

const AccountActivate = (props: Props) => {
  const { t } = useTranslation();

  const url = new URL(window.location.href);
  const providedEmail = url.searchParams.get('e') || '';
  const providedCode = url.searchParams.get('c') || '';
  

  return (
    <ConfirmEmail {...props} email={providedEmail} code={providedCode}/>
  );
};

export default AccountActivate;
