import React, { memo } from 'react';
import styles from './InfoBox.module.scss';
import { ReactComponent as IconInfo } from 'assets/icon_info.svg';
import { innerHTMLTranslation } from 'utils/utils';

interface Props {
  text?: string;
  htmlText?: string;
  style?: object;
  noIcon?: boolean;
}

const InfoBox = (props: Props) => {
  const { text, htmlText, style, noIcon} = props;

  if (text || htmlText) {
    return (
      <div className={noIcon ?  styles.InfoBox_wrapperFull : styles.InfoBox_wrapper} style={style}>
        { noIcon ? null : <IconInfo className={styles.InfoBox_img} />}
        {htmlText ? <p className={styles.InfoBox_text} dangerouslySetInnerHTML={innerHTMLTranslation(htmlText)} /> : <p className={styles.InfoBox_text}>{text}</p>}
      </div>
    );
  }
  return null;
};

export default memo(InfoBox);
