import React, { useRef, useState } from 'react';
import styles from './PageAmountInput.module.scss';
import { PriceInput } from 'components/priceInputFake/PriceInputFake';
import TextField from '@material-ui/core/TextField';
import { InputFieldStyle } from 'components/override_styles/TextField';
import { amountFromString } from 'utils/calculates';


const CssTextField = InputFieldStyle(TextField);

interface Props {
  label?: string;
  isIOs?: boolean | any;
  onChange?: any;
  initial?: string;
}
/**
 * Represents an amount input for editable amount positions in table
 * @param label Label to be displayed on top of the amount input
 * @param isIOs Must be set to true when user is using iOs device for proper keyboard type when entering input
 * @param onChange Callback that will be called each time user enters amount, following object will be passed on: { value: cents value, text: textual representation of amount}
 * @param initial Initial value that the input will be populated with, must be a valid amount i.e. 1.22
 */
const PageAmountInput = ({label, isIOs, onChange, initial}: Props) => {    
  var initialAmount = amountFromString(initial || '');  
  const [amount, setAmount] = useState(initialAmount.text);
  const [amountHolder, setAmountHolder] = useState(initialAmount);

  const divRef = useRef<HTMLDivElement>(null);
  const inputValue = useRef<HTMLInputElement>();
  
  const iOS = isIOs || false;
  const disabled = false;
  const checkingPrice = false;
  

  const handleSubmit = (e: React.FormEvent<EventTarget>) => {    
    e.preventDefault();
    // Notify owner component that new value is entered
    if(onChange){
      onChange(amountHolder);
    }
    // As we are done entering amount 
    // Remove active/selected style from amount input
    if (divRef.current) {
      divRef.current.removeAttribute('style');
    }
  };

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => { 
    var newAmount = amountFromString(e.currentTarget.value);
    setAmountHolder(newAmount);
    setAmount(e.currentTarget.value);
    // if (!e.currentTarget.value.length) {
    //   clearInputPrice();
    // }
    if(onChange){
      onChange(newAmount);
    }
  };
  const inputSummaryInvoiceHandler = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.currentTarget.style.border = '1px solid #2B0096';
    if (inputValue.current) {
      inputValue.current.focus();
    }
    
  };
  return (<>
  <form onSubmit={handleSubmit} className={`${styles.PageAmountInput_formTip}`}>
      <CssTextField
            name="invoiceAmount"
            label="label"
            placeholder="placeholder"
            onChange={(event: React.FormEvent<HTMLElement | any>): void => { handleChange(event); }}
            fullWidth
            autoComplete="off"
            margin="normal"
            variant="outlined"
            disabled={disabled}
            InputLabelProps={{ shrink: true }}
            inputRef={inputValue}
            inputProps={{
              inputMode: iOS ? 'decimal' : 'tel',
              value: amount || '',
              type: 'tel',
            }}
          />
    </form>
    <div className={styles.PageAmountInput_bottom}>
    {label ? <><h1 className="title title__marginTitle">{label}</h1></> : null}    
    {/* This field is presenting current Summary Invoice with custom styles */}
    <PriceInput clicked={inputSummaryInvoiceHandler} tipHeight={amount} checkingPrice={checkingPrice}  reference={divRef} />
    </div>
    </>
  );
};

export default PageAmountInput;
