import React, { useState, useEffect, useContext } from 'react';
import styles from './AccountDataStepper.module.scss';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import InfoBox from 'components/layout/infoBox/InfoBox';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import { BackendApp } from 'libs/App';
import UserConsumer from 'context/user/User';
import StepConnector from '@material-ui/core/StepConnector';
import { StepIconProps } from '@material-ui/core/StepIcon';
import InfoDrawer from 'components/infoDrawer/InfoDrawer';
import Button from '@material-ui/core/Button';
import { ButtonStyle } from 'components/override_styles/ButtonWhite';

// Initialize backend methods
const backendLib = BackendApp();

const CssButton = ButtonStyle(Button);

const useStyles = makeStyles(() => ({
  card: {
    width: 'calc(100% + 2.8rem)',
    margin: '1rem 0 1rem -1.4rem',
  },
  stepper: {
    padding: '0',
    margin: '3rem -3rem',
  },
  stepperBlocked: {
    '& .MuiStepLabel-iconContainer div': {
      backgroundImage: 'none',
      backgroundColor: '#AFAFAF',
    },
    '& .MuiStepConnector-line': {
      backgroundImage: 'none !important',
      backgroundColor: '#AFAFAF',
    },
    '& div:last-child': {
      'position': 'relative',
      'top': '-.2rem',
      '& .MuiStepLabel-iconContainer div': {
        backgroundImage: 'none',
        backgroundColor: '#FF0000',
        width: '2.9rem',
        height: '2.9rem',
      },
      '& .MuiStepLabel-label': {
        margin: '.8rem auto 0 auto !important',
      },
    },
  },
  stepperLabel: {
    '& span span': {
      fontSize: '1rem',
      color: '#AFAFAF !important',
      width: 'min-content',
      margin: '1rem auto 0 auto !important',
    },
  },
}));

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + .8rem)',
    right: 'calc(50% + .8rem)',
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient(90deg, #064789, #84949C)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient(90deg, #064789, #84949C)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 24,
    height: 24,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      'linear-gradient(90deg, #064789, #84949C)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient(90deg, #064789, #84949C)',
  },
});

interface ContextProps {
  currentUser: {
    userId: string,
  };
  configuration: {
    kycVerificationValue: number,
  };
}

interface FormData {
  postURL: string;
  fields: any[];
}

const AccountDataStepper = (props: RouteComponentProps) => {
  const userConsumer = useContext<Partial<ContextProps>>(UserConsumer);
  const { currentUser: { userId }, configuration: { kycVerificationValue } }: any = userConsumer;
  const { t } = useTranslation();
  const { match, history } = props;

  const [terms, setTerms] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState([t('no_account_data'), t('data_provided'), t('verificationTip'), 'OK!']);
  const [status, setStatus] = useState('');
  const [postData, setPostData] = useState<FormData>();
  const classes = useStyles();

  const ColorlibStepIcon = (restProps: StepIconProps) => {
    const iconClasses = useColorlibStepIconStyles();
    const { active, completed } = restProps;

    const icons: { [index: string]: React.ReactElement } = {
      1: <CheckOutlinedIcon />,
      2: <CheckOutlinedIcon />,
      3: <CheckOutlinedIcon />,
      4: status === 'B' ? <LockOutlinedIcon /> : <CheckOutlinedIcon />,
    };

    return (
      <div
        className={clsx(iconClasses.root, {
          [iconClasses.active]: active,
          [iconClasses.completed]: completed,
        })}
      >
        {icons[String(restProps.icon)]}
      </div>
    );
  };

  const getStepContent = (stepIndex: number, statusId: string, formData?: FormData) => {
    switch (stepIndex) {
      case 0:
        return <InfoBox htmlText={t('accountData_step1')} />;
      case 1:
        return (
          <div>
            <InfoBox htmlText={t('accountData_step2')} />
          </div>
        );
      case 2:
        return <InfoBox htmlText={t('accountData_step3')} />;
      case 3:
        return statusId === 'B' ? (
          <div>
            <InfoBox htmlText={t('accountData_step4')} />
            <CssButton
              fullWidth
              size="large"
              variant="outlined"
              type="button"
              href="&#x6D;&#x61;&#x69;&#x6C;&#x74;&#x6F;&#x3A;&#x69;&#x6E;&#x66;&#x6F;&#x40;&#x6F;&#x66;&#x69;&#x61;&#x72;&#x61;&#x2E;&#x6F;&#x72;&#x67;"
            >
              {t('Napisz do nas')}
            </CssButton>
          </div>
        ) : (
          <div>
            <InfoBox htmlText={t('accountData_step4_success')} />
          </div>
        );
      default:
        return null;
    }
  };

  const activateStep = (statusId: string) => {
    switch (statusId) {
      case 'A':
        return 3;
      case 'B':
        return 3;
      case 'D':
        return 1;
      case 'P':
        return 2;
      default:
        return 0;
    }
  };

  const stepsTextMapper = (stepIndex: number, statusId: string) => {
    switch (stepIndex) {
      case 2:
        return t('in_verification');
      case 3:
        return statusId === 'B' ? t('is_blocked') : t('approved');
      default:
        return steps[stepIndex];
    }
  };

  const toggleTerms = () => {
    terms ? history.push('/profile/account-data') : history.push('/profile/account-data/terms');
    setTerms(!terms);
  };

  useEffect(() => {
    if ((match.params as { terms: string }).terms === 'terms') {
      toggleTerms();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Uncomment this if you want to set statuses on backend
    // backendLib.updateUserVerificationStatus('A');
    backendLib.backendLoadUser(userId).then((userData: any) => {
      // You can programitacly chang statuses here
      // Available statuses
      // A - Accepted
      // B - Blocked
      // U - Unknown
      // D - Data provided
      // P - Pending

      // setStatus('A');
      setStatus(userData.v ? userData.v.s : 'U');
      setActiveStep(activateStep(status));
    });

    if ((status === 'B' || status === 'D') && kycVerificationValue) {
      backendLib.registerKYCPayment(kycVerificationValue, userId, window.location.href).then(
        (response: any) => {
          setPostData(response.data.p);
        },
      );
    }
  }, [userId, status, kycVerificationValue]);

  useEffect(() => {
    if (status === 'B' && steps[3] !== t('blocked')) {
      const tempSteps = [...steps];
      tempSteps[3] = t('blocked');
      setSteps(tempSteps);
    }
  }, [status, steps, t]);

  return (
    <>
      <Card className={classes.card}>
        <CardContent>
          <h2 className="title title__small">{t('stepperStatus')}</h2>
          <div className={`${styles.accountDataStepper_stepTitle} ${status === 'B' ? styles.accountDataStepper_stepTitle__warning : ''}`}>
            {stepsTextMapper(activeStep, status)}
          </div>
          <Stepper
            className={`${classes.stepper} ${status === 'B' ? classes.stepperBlocked : ''}`}
            activeStep={activeStep}
            alternativeLabel
            connector={<ColorlibConnector />}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel className={classes.stepperLabel} StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            <div>{getStepContent(activeStep, status, postData)}</div>
          </div>
        </CardContent>
      </Card>
      <InfoDrawer title={t('payment_why')} text={t('payment_info')} open={terms} toggleTerms={toggleTerms} />
    </>
  );
};

export default AccountDataStepper;
