import React, { useEffect, useState } from 'react';
import styles from './BLIK.module.scss';
import ButtonBase from '@material-ui/core/ButtonBase';
import InputMask from 'react-input-mask';
import TextField from '@material-ui/core/TextField';
import { InputFieldBlikStyle } from 'components/override_styles/TextFieldBlik';
import SpinnerLarge from 'components/layout/spinner/SpinnerLarge';

import firebase from 'firebase/app';
import { BackendApp } from 'libs/App';

// Initialize backend methods
const backendLib = BackendApp();

const CssTextFieldBlik = InputFieldBlikStyle(TextField);

interface BLIKSpinnerProps {
  msg: string
}
export const BLIKSpinner = (props: BLIKSpinnerProps) => {

  const { msg } = props;

  return (
    <div className={styles.BLIKSpinner}>
      <div className={styles.BLIKSpinner_wrapper}>
      <div className={styles.BLIKSpinner_blikInfoBox}>{msg}</div>
        <SpinnerLarge />
      </div>
    </div>
  );
};

interface BLIKButtonProps {
  // presentation 
  selected: boolean; // when true the button is rendered as selected
  iconAssetName?: string; // name of the icon that will be rendered
  altText?:string; // alternate text on button
  // interaction handlers
  onSelected?:any; // function(string) that will be triggered when user selects BLIK, the string 'blik' will be passed as an argument to this method  
}

export const BLIKButton = (props: BLIKButtonProps) => {
  const {onSelected, selected, iconAssetName, altText} = props;
  const KEY = 'blik';

  const handleSelected = () =>{
    if(onSelected)
      onSelected(KEY);
  }

  return (
    <ButtonBase onClick={() => handleSelected()}>
      <div className={`${styles.BLIK_paymentItem} ${selected && styles.BLIK_paymentItem__selected}`}>
        <img
          src={require(`assets/${iconAssetName?iconAssetName:KEY}.svg`)}
          height="26"
          alt={altText?altText:'blik'}
        />
      </div>
    </ButtonBase>
  )
};

// Props types
interface Props { 
  // logic settings
  isIOs: boolean; // device
  // processing handlers
  // presentation settings
  title: string; // title presented on top of BLIK Input
  placeholder: string; //
  itemReference?: any; // reference
  // interaction handlers
  onChange?: any; // function(string) called when user enters blik code, blik code is passed as an argument to this method only when all digits are entered
}

export const BLIK = (props: Props) => {
  const {title, onChange, itemReference, placeholder, isIOs} = props;
  
  const handleOnChange = (e: React.FormEvent<HTMLInputElement>)=>{
    const clearValue = e.currentTarget.value.replace(/\s|_/g, '');
    if(onChange){
      if (clearValue.length === 6){
        onChange(clearValue);
      }
    }    
  }

  return (    
    <div className={`${styles.BLIK_blikCode}`}>
      <h1 className="title title__smallBold">{title}</h1>
      <InputMask
          mask="999 999"
          onChange={handleOnChange}
      >
        {
          () => (
            <CssTextFieldBlik
              fullWidth
              ref={itemReference}
              autoComplete="off"
              name="blikCode"
              placeholder={placeholder}
              margin="normal"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              inputProps={{
                inputMode: isIOs ? 'decimal' : 'tel',
                type: 'tel',
              }}
            />
          )
        }
      </InputMask>
    </div>
  );
};

export interface PaymentResult {
  s: string; // status OK or ERROR
  r?: string; // redirect URL when 3D Secure verification is needed
  e?: string; // error message when s==ERROR
}

/**
   * Payment processing using BLIK payment method
   * @param beneficiaryUserId Id of the user that will receive the tip
   * @param amount Tip amount in cents
   * @param blikCode User entered BLIK code
   * @param provider One of B, BA
   * @param isAsync When true async mode is used and one should use asyncInProgressCallback() and asyncResultCallback()
   * @param asyncInProgressCallback Callback invoked when payment is registered and we are waiting for async confirmation of transaction
   * @param asyncResultCallback Callback invoked at the end of the process. Contains final result of the transaction
   * @returns When called in sync mode Promise<PaymentResult> is returned. When calling in async mode
   * one should listen on asyncInProgressCallback() and asyncResultCallback() calbacks for transaction result.
   */
  export const processBLIKPaymentTransaction = (beneficiaryUserId:string, amount:number, blikCode:any, provider:string, isAsync:boolean = false, asyncInProgressCallback?:any, asyncResultCallback?:any):Promise<PaymentResult>=>{
    if(isAsync){
      // async processing. As a result two callback will be invoked:
      // first after payment registration asyncInProgressCallback() will be called
      // so the UI can update message accordingly
      // then when async confirmation of tip is received final callback asyncResultCallback() is 
      // called with PaymentResult object
      return backendLib.registerPaymentAsync(amount, blikCode, beneficiaryUserId, provider)
      .then((response: any) => {
        console.log('response', response);        
        asyncInProgressCallback();

        const checkPayment = () => {
          return firebase.database().ref(`/asyncQ/${response.i}`)
          .on('value', (snapshot: any) => {
            if (snapshot.val()) {
              console.log('snapshot.val()', snapshot.val());
              if (snapshot.val().d.s === 'A') {
                asyncResultCallback({
                    s: 'OK'
                  });
              }
              if (snapshot.val().d.s === 'D') {
                asyncResultCallback({
                    s: 'ERROR',
                    e: 'Payment declined'
                  })
              }
            }else{
              asyncResultCallback({
                s: 'ERROR',
                e: 'Snapshot mismatch'
              })              
            }
          });
        };
  
        checkPayment();
        return {
          s: 'OK'
        }
      })
      .catch((err: any) => {
        console.log(`Error: ${err}`);
        return {
          s: 'ERROR',
          e: err
        }
      });
    }else{
      // sync processing      
      return backendLib.registerPayment(amount, blikCode, beneficiaryUserId, provider)
      .then(() => {
        return {
          s: 'OK'
        }
      })
      .catch((err:any) => {
        return {
          s: 'ERROR',
          e: err
        }        
      });
    }  
  };

