import React, { useContext } from 'react';
import UserConsumer from 'context/user/User';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import IconButton from '@material-ui/core/IconButton';
import Scrollbars from 'react-custom-scrollbars';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/styles';
import styles from './InfoDrawer.module.scss';
import { innerHTMLTranslation } from 'utils/utils';

const CssSwipeableDrawer = withStyles({
  root: {
    '& .MuiDrawer-paper': {
      width: '95%',
      display: 'grid',
      gridTemplateRows: 'max-content',
      gridTemplateAreas: `"title close" "terms terms"`,
      alignItems: 'start',
      overflow: 'hidden',
    },
  },
})(SwipeableDrawer);

const CssIconButton = withStyles({
  root: {
    margin: '1rem .4rem',
    color: '#404040',
    position: 'absolute',
    justifySelf: 'end',
  },
})(IconButton);

const CssCloseIcon = withStyles({
  root: {
    fontSize: '3rem',
  },
})(CloseIcon);

interface Props {
  open: boolean;
  text: string;
  title: string;
  toggleTerms: () => void;
}

interface ContextProps {
  currentUser: {
    iOS: boolean,
  };
}

const InfoDrawer = (props: Props) => {
  const { toggleTerms, open, text, title } = props;
  const userConsumer = useContext<Partial<ContextProps>>(UserConsumer);
  const { currentUser: { iOS } }: any = userConsumer;

  return (
    <CssSwipeableDrawer
      anchor="right"
      open={open}
      onClose={() => toggleTerms()}
      onOpen={() => null}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      disableSwipeToOpen
    >
      <div className={styles.infoDrawer_infoTitle}>{title}</div>
      <CssIconButton aria-label="close" style={{gridArea: 'close'}} onClick={toggleTerms}>
        <CssCloseIcon />
      </CssIconButton>
      <Scrollbars thumbSize={90} style={{width: 'auto'}} className={styles.infoDrawer_infoText}>
        <div dangerouslySetInnerHTML={innerHTMLTranslation(text)} />
      </Scrollbars>
    </CssSwipeableDrawer>
  );
};

export default InfoDrawer;
