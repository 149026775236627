import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BackendApp } from 'libs/App';
import styles from './About.module.scss';
import Scrollbars from 'react-custom-scrollbars';
import { Link } from 'react-router-dom';
import InfoDrawer from 'components/infoDrawer/InfoDrawer';
import { innerHTMLTranslation } from 'utils/utils';

// Initialize backend methods
const backendLib = BackendApp();

interface Props {
  history: {
    push: (path: string) => void,
  };
  match: {
    params: {
      terms: string,
    },
  };
}

const About = (props: Props) => {
  const { t } = useTranslation();
  const [terms, setTerms] = useState(false);
  const { match, history } = props;

  const toggleTerms = () => {
    terms ? history.push('/about') : history.push('/about/terms');
    setTerms(!terms);
  };

  useEffect(() => {
    if (match.params.terms === 'terms') {
      toggleTerms();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="background_gradient background_gradient__gray">
      <div className={`container ${styles.about}`}>
        <div className="title_log">
          {t('about_payTip')}
        </div>
        <Scrollbars thumbSize={90} style={{width: '100%', height: 'calc(100vh - 17rem)'}}>
          <div className={styles.about_smallTitle}>
            {t('version')}
          </div>
          <div className={styles.about_versionNumbers}>
            <span className={styles.about_versionNumbers__margin}>2.0.8</span>
            <span className={styles.about_versionNumbers__margin}>2020/11/04</span>
          </div>
          {/* <div className={styles.about_smallTitle}>
            {t('version_whats_new')}
          </div>
          <div dangerouslySetInnerHTML={innerHTMLTranslation(t('version_whats_new_list'))} className={styles.about_whatsNew} /> */}
          <div className={styles.about_smallTitle}>
            {t('system_requirements')}
          </div>
          <div dangerouslySetInnerHTML={innerHTMLTranslation(t('system_requirements_list'))} className={styles.about_whatsNew} />
          <div className={styles.about_smallTitle}>
            {t('terms')}
          </div>
          <Link to="/about/terms" className={styles.about_termsLink}>
            {t('terms_and_rules')}
          </Link>
        </Scrollbars>
      </div>
      <InfoDrawer title={t('terms')} text={t('terms_text')} open={terms} toggleTerms={toggleTerms} />
    </div>
  );
};

export default About;
