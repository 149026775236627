
import moment from 'moment';
import { dayFormat } from 'utils/validators';

type currentObject = {
  t: null | string,
  s: null | string
}

// Calculating height of tip by percentage, after that type of calculation is converted to number
const calcSummaryInvoiceByPercent = (tip: number | string, percent: number): string => (+tip * (percent / 100)).toFixed(2);

// Scroll to DOM element
const scrollTo = (height: number) => (
  window.scrollTo({
    behavior: 'smooth',
    top: height,
  })
);

// Convert price from cents to amount with decimals ex. '12.50'
const priceDecimalString = (price: number) => (price * 0.01).toFixed(2);

// Full day format ex '2019-01-01T12:00'
const fullDayFormat = (time: number) => moment(time).toISOString(true).substr(0, 16);

// Get days between start and end day
const rangeDates = (startDate: any, endDate: any) => {
  const now = moment(startDate);
  const dates = [];

  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format(dayFormat));
    now.add(1, 'days');
  }
  return dates;
};

// Return all types of data [2 arg] excluding status type [3 arg]
const extendedStatusType = (current: currentObject, type: string, excludedField: string) => {
  return (current.t !== type) || (current.t === type && current.s !== excludedField);
}

// Sum tips functions filtered by transaction type
const sumTipsOfAllObject = (arr: any, today: string, transaction: string[]) => {
  return arr[today].reduce((total: any, current: any) => {
    if (transaction.includes(current.t) && extendedStatusType(current, 'V', 'P')) {

      // If transaction is 'new tip' or 'shared' and these status is not confirmed by payment services dont add this transaction to sum
      if ((current.t === 'T' && current.s === 'P') || (current.t === 'S' && current.s === 'P')) {
        total += 0;
      } else {
        total += current.a;
      }
    }
    return total;
  }, 0);
};

// Count daily height of tip
const countDailyTip = (arr: any, today: string, transaction: string[]) => {
  if (arr[today]) {
    const daily = sumTipsOfAllObject(arr, today, transaction);
    return priceDecimalString(daily);
  }
  // If current day is without any Tips, display 0
  return '0,00';
};

// Sum total tips depends on dates range
const countHeightOfRangeAmount = (range: string[], data: any, transaction: string[]) => {
  const totalTip = range.map((elem: any) => {
    if (Object.keys(data).includes(elem)) {
      return sumTipsOfAllObject(data, elem, transaction);
    }
  }).filter((d: any) => d).reduce((prev: any, current: any) => prev + current, 0);

  return priceDecimalString(totalTip);
};

// filter history data passing current resultData, type of transaction ('tip', 'shared') and activate sloik ID
const filterHistoryData = (data: any, filterType: string, sloikID: string) => {
  const filteredObject = {};

  Object.entries(data).forEach((el: any) => {
    const keyObject = el[0];
    let valueObject;

    if (filterType === 'activeSloik') {
      valueObject = el[1].filter((item: any) => item.sh === sloikID);
    }
    if (filterType === 'myTip') {
      const includedTransaction = ['T', 'IT', 'W', 'TT', 'P'];
      const verificationCompleted = (data: currentObject) => data.t === 'V' && data.s === 'C' && data;

      valueObject = el[1].filter((item: any) => item.sh !== sloikID && (verificationCompleted(item) || includedTransaction.includes(item.t)));
    }

    if (valueObject.length >= 1) {
      Object.assign(filteredObject, {[keyObject]: valueObject.length >= 1 ? valueObject : null });
    }
  });
  return filteredObject;
};

const amountCentsToString = (amountCents: number) => {
  return (amountCents/100).toFixed(2)
}

/**
 * Returns object representing provided amount (that is given in string).
 * When there is a problem parsing the string or the provided value is empty then always 0 (zero) is returned.
 * @param amount decimal number as a string, i.e. 1.222
 * @returns {*} Object holding parsed values: { value: cents value, text: textual representation}, i.e. { value: 101, text: '1,01'}
 */
const amountFromString = (amount: string) => {
  var result = {
    value: 0,
    text: ''
  }
  if(!amount)
    return result;
  // replace commas
  var value = amount.replace(/[,]/g,'.').replace(/\s+/g,'').replace(/[a-zA-Z]+/g,'');  

  var floatValue = 0 ;
  
  floatValue = parseFloat(value);   
  if(Number.isNaN(floatValue))
    floatValue = 0;
  
  result.value = Math.floor(Math.round(floatValue*100));
  result.text = (result.value/100).toFixed(2)
  
  return result;
  
};

export {
  calcSummaryInvoiceByPercent,
  scrollTo,
  priceDecimalString,
  fullDayFormat,
  rangeDates,
  countDailyTip,
  countHeightOfRangeAmount,
  filterHistoryData,
  amountFromString,
  amountCentsToString
};
