import React, { useState, useEffect, useContext } from 'react';
import { useHistory, RouteComponentProps } from 'react-router-dom';
import { BackendApp } from 'libs/App';
import { useTranslation } from 'react-i18next';
import UserConsumer from 'context/user/User';
import Notification from 'components/notification/Notification';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import MailOutline from '@material-ui/icons/MailOutline';
import { InputFieldStyle } from 'components/override_styles/TextField';
import { ButtonStyle } from 'components/override_styles/Button';
import styles from './Awaiting.module.scss';
import Loading from 'components/loading/Loading';
import { eMail } from 'utils/validators';
import InfoBox from 'components/layout/infoBox/InfoBox';

const KEY = 0;
const VALUE = 1;

// Initialize backend methods
const backendLib = BackendApp();

// Overridden material styles
const CssTextField = InputFieldStyle(TextField);
const CssButton = ButtonStyle(Button);

interface IContextProps {
  currentUser: {
    email: string,
  };
}

const Awaiting = (props: RouteComponentProps) => {
  const { t } = useTranslation();
  const userConsumer = useContext<Partial<IContextProps>>(UserConsumer);
  const [email, setEmail] = useState((userConsumer.currentUser && userConsumer.currentUser.email) || localStorage.getItem('paytipUser') || '');
  const [loader, setLoader] = useState(false);
  const [receiveError, setReceiveError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [tipId, setTipId] = useState('');

  const history = useHistory();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(event.target.value);
  };

  const handleSendEmail = () => {
    setEmailError('');
    setReceiveError('');
    const isCorrectEmail = email.match(eMail);
    if (isCorrectEmail) {
      setLoader(true);

      backendLib._callAPI('matchTip', { r: tipId, e: email })
      .then(() => {
        history.push('/awaiting-typ');
      })
      .catch((error: any) => {
        setReceiveError(error.message);
        setLoader(false);
        console.log('Error:', error.message);
      });
    } else {
      setEmailError(t('type_correct_email'));
    }
  };

  useEffect(() => {
    const tipParameters = props.location.search.replace('?', '').split('=');
    if (tipParameters.length && tipParameters[KEY] === 't' && tipParameters[VALUE]) {
      setTipId(tipParameters[VALUE]);
    }
  }, [props.location.search]);

  return (
    <>
      <div className={`${styles.awaiting_wrapper} background_gradient background_gradient__purpure`}>
        { tipId ? (
          <form className={`container ${styles.awaiting_form}`}>
            <div className="title_log">
              {t('type_email_address')}
            </div>
            <CssTextField
              fullWidth
              autoComplete="off"
              name="receive_password"
              label={t('email')}
              placeholder={t('email_placeholder')}
              helperText={receiveError || emailError || ''}
              margin="normal"
              variant="outlined"
              type="email"
              value={email}
              InputLabelProps={{shrink: true}}
              InputProps={{startAdornment: (<InputAdornment position="start"><MailOutline /></InputAdornment>)}}
              onChange={handleChange}
              error={!!receiveError || !!emailError}
            />
            <div className={styles.awaiting_info}>
              <InfoBox text={email ? t('awaiting_info_logged') : t('awaiting_info')} />
            </div>
            <CssButton
              fullWidth
              size="large"
              variant="outlined"
              type="button"
              onClick={handleSendEmail}
            >
              {t('receive_tip')}
            </CssButton>
          </form>
        ) : (
          <div className="title_log">
            {t('no_required_parameters')}
          </div>
        )}
        {loader && <Loading />}
        {receiveError && (
          <Notification
            type="error"
            msg={`${t('error')}: ${receiveError}`}
            autoHideDuration={null}
          />
        )}
      </div>
    </>
  );
};

export default Awaiting;
