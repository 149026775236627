import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import * as firebase from 'firebase/app';
import 'firebase/database';
import UserConsumer from 'context/user/User';
import { useTranslation } from 'react-i18next';
import styles from './Balance.module.scss';
import { BackendApp } from 'libs/App';
import ThankYou from 'components/thankYou/ThankYou';
import { priceDecimalString } from 'utils/calculates';
import Notification from 'components/notification/Notification';

import PriceStyling from 'components/priceStyling/PriceStyling';
import Loading from 'components/loading/Loading';
import SpinnerSmall from 'components/layout/spinner/SpinnerSmall';
import InfoBox from 'components/layout/infoBox/InfoBox';

import Button from '@material-ui/core/Button';
import { ButtonStyle } from 'components/override_styles/Button';

import Fab from '@material-ui/core/Fab';
import { ButtonCircleStyle } from 'components/override_styles/ButtonCircle';
import AddIcon from '@material-ui/icons/Add';
import { RouteComponentProps } from 'react-router-dom';

// TODO: Fix typing!!!!!!!!!

// Initialize firebase
const backendLib = BackendApp();

// Overridden material styles
const CssButton = ButtonStyle(Button);
const CssButtonCircle = ButtonCircleStyle(Fab);

interface IContextProps {
  currentUser: {
    userId: string,
    singleModule: string,
    as: string,
    tipBoxName: string,
  };
  configuration: {
    withdrawMinValue: number,
    withdrawTipboxMinValue: number,
    balanceWarningThreshold: number,
  };
}

const Balance = (props: RouteComponentProps) => {
  const userConsumer = useContext<Partial<IContextProps>>(UserConsumer);
  const {
    currentUser: { userId, singleModule, as, tipBoxName },
    configuration: { withdrawMinValue, withdrawTipboxMinValue, balanceWarningThreshold },
  }: any = userConsumer;

  // The minimum withdraw value from BE configuration
  const MIN_VALUE = withdrawMinValue;

  const { t } = useTranslation();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [TYP, setTYP] = useState(false);

  const [owner, setOwner] = useState<undefined | boolean>(undefined);
  const [showWarning, setShowWarning] = useState(false);
  const [availableFunds, setAvailableFunds] = useState();
  const [availableFundsSloik, setAvailableFundsSloik] = useState();
  const [userAccountStatus, setUserAccountStatus] = useState('');

  const getUserWallet = () => {
    const data = {};
    firebase.database().ref(`/wallet/users/${userId}`).orderByChild('st').on('value', (snapshot: any) => {
      const resultObj: {} = {};
      if (snapshot.val()) {
        snapshot.forEach((child: any) => {
          Object.assign(resultObj, { [`${child.key}`]: child.val() });
        });
      }

      // @ts-ignore
      Object.assign(data, { a: resultObj.a, b: resultObj.b });
      // @ts-ignore
      setAvailableFunds(data);
    });

    return data;
  };

  // Get user status
  const getUserAccountStatus = () => backendLib.backendLoadUser(userId).then((userData: any) => {
    return userData.v ? userData.v.s : 'U';
  });

  // Check if current user is owner of current sloik
  const checkOwner = () => backendLib.checkSharedOwnership(as, userId).then((user: boolean) => user);

  useEffect(() => {
    Promise.all([checkOwner(), getUserWallet(), getUserAccountStatus()]).then((data: [boolean, object, string]) => {
      setOwner(data[0]);
      setUserAccountStatus(data[2]);
    });

    getUserWallet();

    return () => {
      firebase.database().ref(`/wallet/users/${userId}`).off();
      firebase.database().ref(`/userShared/${userId}`).off();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // @ts-ignore
    setShowWarning(singleModule && availableFunds && balanceWarningThreshold && (availableFunds.a >= balanceWarningThreshold));
  }, [singleModule, availableFunds, balanceWarningThreshold]);

  const userSharedFn = () => firebase.database().ref(`/userShared/${userId}`).orderByChild('st').on('value', (snapshot: any) => {
    const resultObj: {} = {};
    if (snapshot.val()) {
      snapshot.forEach((child: any) => {
        Object.assign(resultObj, { [`${child.key}`]: child.val() });
      });
    }
    // Store available sloik data
    Object.values(resultObj).map((el: any) => (el.s === as) && setAvailableFundsSloik(el));
  });

  
  useEffect(() => {
    // @ts-ignore
    Promise.all([checkOwner(), userSharedFn()]).then((d: [boolean, () => void]) => {
      setOwner(d[0]);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [as]);

  const renderPriceData = (singleModuleType: string) => {
    // @ts-ignore
    const priceType = singleModuleType ? availableFunds.a : availableFundsSloik ? availableFundsSloik.a : null;
    const price = priceDecimalString(priceType);

    return (
      <PriceStyling
        tip={price}
        style={{
          color: singleModuleType ? '#2B0096' : '#AA4399',
          integer: 4,
          cents: 3.2,
          currency: 2.5,
        }}
      />
    );
  };

  // Request Withdrawal
  const walletTransfer = () => {
    // @ts-ignore
    const price = singleModule ? availableFunds.a : availableFundsSloik.a;
    const sharedId = (!singleModule && as) ? (as) : null;
    
    if(singleModule){
      setLoading(true);
      // for individual balance order withdrawal
      backendLib.registerWithdrawal(price, sharedId).then(() => {
        setLoading(false);
        setTYP(true);
      }).catch(() => {
        setLoading(false);
        setError(true);
      });
    }else{
      // for tipbox withdrawal request tipbox settlement process
      props.history.push('/settle')  
    }
    

    
  };

  const payoutInforamtionData = (userStatus: string, singleModuleType: boolean) => {
    // @ts-ignore
    const priceType = singleModuleType ? availableFunds.a : availableFundsSloik && availableFundsSloik.a;
    // @ts-ignore
    const emptySloik = availableFundsSloik && availableFundsSloik.a <= withdrawTipboxMinValue;

    if (singleModuleType) {
        if (priceType < MIN_VALUE) {
          if (userStatus !== 'A') {
            if (userStatus === 'B') {
              return (
                <InfoBox htmlText={t('walletBlockInfo')} />
              );
            } else {
              return (
                <>
                  <InfoBox text={`${t('walletMoreThanValue')} ${MIN_VALUE / 100} zł.`} />
                  <InfoBox text={t('walletFillUserData')} />
                </>
              );
            }
          } else {
            return (
              <InfoBox text={`${t('walletMoreThanValue')} ${MIN_VALUE / 100} zł.`} />
            );
          }
        }

        if (priceType > MIN_VALUE) {
          if (userStatus !== 'A') {
            if (userStatus === 'B') {
              return (
                <InfoBox htmlText={t('walletBlockInfo')} />
              );
            } else {
              return (
                <>
                  <InfoBox text={t('walletFillUserData')} />
                  <Link to={'/profile'} className={styles.balance_editProfile}>{t('editProfile')}</Link>
                </>
              );
            }
          }
        }
      }

    if (typeof owner !== 'undefined' && !singleModule) {
        if (owner) {
          return (
            <>
              <CssButton
                fullWidth
                className={`${emptySloik ? 'card__disabled' : 'card__activeRotateOpus'}`}
                disabled={emptySloik}
                size="large"
                variant="outlined"
                type="button"
                onClick={walletTransfer}
              >
                {t('tipBox_transfer')}
              </CssButton>
              <div className="mt-15">
                <InfoBox text={t('walletSubmitTipBox')} />

                { emptySloik && (
                  <InfoBox
                    text={`${t('transferFunds')} ${withdrawTipboxMinValue / 100} zł.`}
                    style={{ marginTop: '1.5rem' }}
                  />
                )}
              </div>
            </>
          );
        } else {
          return <InfoBox text={t('walletOwnerTip')} />;
        }
      }
  };

  // Validate withdraw amount button when available funds is more then minimum required and verification status is Accepted
  // @ts-ignore
  const statusOfUserAccount = singleModule && (availableFunds && availableFunds.a > MIN_VALUE) && userAccountStatus === 'A';

  return (
    <div className={`${styles.balance_wrapper}`}>

      {/* Render thankyYou page, when user registered withdraw */}
      <div className={`${styles.balance_thankYou}`} style={{ display: TYP ? 'grid' : 'none' }}>
        <ThankYou
          img="flame_transparent.svg"          
          text={t('thankYou')}
        >
          <p className={`${styles.balance_thankYouAddText}`}>{t('thankYouPaymentRegister')}</p>
          <Link to={'/welcome'}>
            <CssButtonCircle
              color="primary"
              aria-label="add"
              style={{ position: 'absolute', bottom: '3rem', right: '3rem' }}
            >
              <AddIcon style={{ fontSize: '3.5rem' }} />
            </CssButtonCircle>
          </Link>
        </ThankYou>
      </div>

      <div className="container" style={{ display: !TYP ? 'grid' : 'none' }}>
        <h1 className={`title ${styles.balance_title}`}>{t('my_balance')}</h1>
        <p className={`${styles.balance_subTitle}`}>{singleModule ? t('individualMeasures') : <>{t('tipBox')}&nbsp;<strong>{tipBoxName}</strong></>}</p>

        {/* Customizing style for tip height, display content depends on current status */}
        <div className={`${styles.balance_tip} mt-15`}>
          { !error ? (
            <>
              {
                availableFunds && typeof owner !== 'undefined' ? (
                  <>
                    {renderPriceData(singleModule)}
                    <div className={`${styles.balance_infobox}`}>
                      {
                        singleModule && (
                          <>
                            <CssButton
                              fullWidth
                              className={statusOfUserAccount ? 'card__activeRotateOpus' : 'card__disabled'}
                              size="large"
                              variant="outlined"
                              type="button"
                              onClick={statusOfUserAccount ? walletTransfer : null}
                            >
                              {t('walletTransfer')}
                            </CssButton>
                            {
                              statusOfUserAccount && (
                                <div className="mt-15">
                                  <InfoBox text={t('walletSubmit')} />
                                </div>
                              )
                            }
                          </>
                        )
                      }
                      {payoutInforamtionData(userAccountStatus, singleModule)}
                    </div>
                  </>
                ) : <SpinnerSmall />
              }
            </>
            ) : error ? (<h1 className="title title__err">{t('errMessage')}</h1>) : <SpinnerSmall />
          }
        </div>

      </div>
      {loading && <Loading />}
      {
        showWarning &&
        <Notification
          type="info"
          htmlMsg={t('balance_warning')}
          autoHideDuration={null}
        />
      }
    </div>
  );
};

export default Balance;
