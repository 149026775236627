import { withStyles } from '@material-ui/core/styles';
import { ExtendButtonBase } from '@material-ui/core/ButtonBase';

export const ButtonStyle = (component: ExtendButtonBase<any>) => withStyles({
  '@global': {
    '.MuiButton-outlined.Mui-disabled': {
      color: '#CDD8EF',
      border: '2px solid #CDD8EF',
      background: 'none',
    },
  },
  root: {
    fontFamily: '"Montserrat"',
    margin: '2.5rem 0 .9rem 0',
    border: 'none',
    borderRadius: '.6rem',
    color: 'white',
    textTransform: 'inherit',
    fontSize: '1.6rem',
    fontWeight: 600,
    background: 'linear-gradient(90deg, #064789, #84949C)',
  },
})(component);
