import React, { useState, useEffect, useRef, useContext, useLayoutEffect } from 'react';
import UserConsumer from 'context/user/User';
import { RouteComponentProps } from 'react-router-dom';
import styles from './Opus.module.scss';
import { useTranslation } from 'react-i18next';
import { scrollTo } from 'utils/calculates';
import { BackendApp } from 'libs/App';


import ThankYou from 'components/thankYou/ThankYou';
import PriceStyling from 'components/priceStyling/PriceStyling';

import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';
import { InputFieldBlikStyle } from 'components/override_styles/TextFieldBlik';




import { ButtonStyle } from 'components/override_styles/Button';

import { InputFieldStyle } from 'components/override_styles/TextField';
import Loading from 'components/loading/Loading';
import GPButton from 'components/gpay/GPay';
import { innerHTMLTranslation, checkVersion } from 'utils/utils';

import InlineNotification from 'components/inline_notification/InlineNotification';
// import LoadingBlik from 'components/loading_blik/LoadingBlik';
import InfoBox from 'components/layout/infoBox/InfoBox'
import {ApplePay, processTokenBasedTransaction, PaymentResult} from 'components/apple/ApplePay'
import {BLIKButton, BLIK, BLIKSpinner, processBLIKPaymentTransaction} from 'components/blik/BLIK'

import PageAmountInput from 'components/inputs/PageAmountInput'

const KEY = 0;
const VALUE = 1;

// Initialize backend methods
const backendLib = BackendApp();

// Overridden material styles
const CssButton = ButtonStyle(Button);
const CssTextField = InputFieldStyle(TextField);
const CssTextFieldBlik = InputFieldBlikStyle(TextField);

const iOSversion = checkVersion();

const paymentMethods = ['blik', 'gpay', 'applepay'];

interface ContextProps {
  currentUser: {
    userId: string,
    isLoggedIn: boolean,
    iOS: boolean,
  };
  configuration: {
    tipMinValue: number,
    tipMaxValue: number,
  };
}

const Opus = (props: RouteComponentProps) => {
  const userConsumer = useContext<Partial<ContextProps>>(UserConsumer);
  const {
    currentUser: { iOS },
    configuration: { tipMinValue, tipMaxValue },
  }: any = userConsumer;
  const { t } = useTranslation();

  const STEPS = {
    AMOUNT: 'AMOUNT',
    PAYMENT_TYPE: 'PAYMENT_TYPE'
  }
  const getQueryParams = (queryString: string, param: string) => {
    return (new URLSearchParams(queryString)).get(param) || '';
  }

  // const [userIdParams, setUserIdParams] = useState();
  const [summaryInvoice, setSummary] = useState();
  const [blikCode, setBlikCode] = useState();
  const [tipHeightSection, setTipHeightSection] = useState(false);
  const [method, selectMethod] = useState();
  const [activePaymentButton, setActivePaymentButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingBlik, setLoadingBlik] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(t('errMessage'));
  const [step, setStep] = useState<string>(STEPS.AMOUNT); // AMOUNT -> PAYMENT_TYPE -> PROCESS_PAYMENT -> VERIFY_3DS (optional) -> TYP || ERROR || ERROR_REDIRECT
  // const [applePayIsAvailable, setApplePayIsAvailable] = useState(false);

  // 3DS verification status
  const [statusOfTransaction, setStatusOfTransaction] = useState<string>(getQueryParams(props.location.search, 's')); 
  const [transactionId, setTransactionId] = useState<string>(getQueryParams(props.location.search, 'i')); 
  const [userIdParams, setUserIdParams] = useState<string>(getQueryParams(props.location.search, 'r'));
  
  const [amountTxt3DS, setAmountTxt3DS] = useState<string>(getQueryParams(props.location.search, 'at'));
  const [amount3DS, setAmount3DS] = useState<string>(getQueryParams(props.location.search, 'a'));
  

  const [introTYP, setIntroTYP] = useState(true);
  const [paymentTYP, setPaymentTYP] = useState(false);
  const [errorTransaction, setErrorTransaction] = useState(false);
  const [amountFromRedirect, setAmountFromRedirect] = useState();

  const blikCodeRef = useRef<HTMLDivElement>(null);  
  const divRef = useRef<HTMLDivElement>(null);
  const paymentButton = useRef<HTMLButtonElement>(null);

  const [gpayClicked, setGpayClicked] = useState(false);
  const [gpayToken, setGpayToken] = useState();
  const [applepayToken, setApplepayToken] = useState();
  const [blikProcessFirstStep, setBlikProcessFirstStep] = useState(false);

  const [triggerApplePayProcessing, setTriggerApplePayProcessing] = useState<boolean>(false);
  const [userImage, setUserImage] = useState<string>(window.location.protocol + '//' + window.location.host+'/img/defaultPublicImage.png');

  useLayoutEffect(() => {
    if (paymentButton.current) {
      scrollTo(paymentButton.current.offsetTop);
    }
  }, [activePaymentButton]);

  // retrieve user image
  useEffect(() => {
    setLoading(true);
    backendLib.loadPublicBinary(userIdParams,'pi1')
    .then((imageURLBase64:string)=>{      
      if(imageURLBase64){
        setUserImage(imageURLBase64);
      }      
      setLoading(false);
    })
    .catch((error:any)=>{      
      setLoading(false);
    })
  }, [userIdParams]);
  
  
  /**
   * Handling eventuall return from 3D secure verification.
   * Trying to restore state from redirect params
   * and when all params are in place proceed to the TYP page.
   */
  useEffect(() => {
    
    // 3DS return start    
    var allParamsReady = amount3DS && amountTxt3DS && statusOfTransaction && transactionId &&  userIdParams;

    if (allParamsReady) {                  
      if (statusOfTransaction.toLowerCase() === 'failure'){
        console.log('3DS returned with error');
        setErrorTransaction(true);
      } 
      if (statusOfTransaction.toLowerCase() === 'success'){        
        setPaymentTYP(true);
        setAmountFromRedirect(amountTxt3DS);
        // proceedToTYP(Number.parseInt(amount3DS,10), amountTxt3DS, transactionId)
        // generateQRCode(transactionId);
      }
    }
    // 3DS return stop

  },[])

  

  //@TODO do something with this
  useEffect(() => {
    if (gpayToken || applepayToken) {
      setLoading(true);
      const tipHeightCalc = Math.round(summaryInvoice * 100);
      const methodType = method.charAt(0).toUpperCase();

      processTokenBasedTransaction(userIdParams, tipHeightCalc, gpayToken || applepayToken,methodType)
      .then(result=>{
        if(result.s == 'OK'){
          // check for 3D Secure redirect
          if(result.r){
            window.location.replace(result.r);
          }else{
            // no 3D Secure redirect so payment was a success
            setLoading(false);
            setTipHeightSection(false);
            setPaymentTYP(true);
          }
        }
        else {
          // error occured
          setLoading(false);
          setErrorMsg(t('errMessage'));
          setError(true);
          selectMethod(undefined);
          setGpayToken(undefined);
          setApplepayToken(undefined);
        }
      })

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applepayToken, gpayToken]);

  /**
   * Sets payment method that was selected by user
   * @param item payment method code: blik, gpay, applepay
   */
  const selectedPaymentMethod = (item: string) => {
    console.log('Selected payment: ', item);
    selectMethod(item);
    setBlikCode(null);
    // for apple pay this is a hack to overcome InvalidAccessError: Must create a new ApplePaySession from a user gesture handler. - 
    setActivePaymentButton(item === 'blik' || 'applepay'? false : true);
    setErrorMsg(t('errMessage'));
    setError(false);
    console.log('Selected payment done');
  };

  /**
   * Called when user enters tip amount
   * @param amountHolder see PageAmountInput for documentation on this object
   */
  const amountChanged = (amountHolder:any) => {
    
    setSummary(amountHolder.text); 
    
    // validate min/max tip values    
    if (amountHolder.value > tipMaxValue) {
      setErrorMsg(`${t('opus.errMessage_maxTip')} ${tipMaxValue / 100} zł`);
      setError(true);
      setStep(STEPS.AMOUNT);
    } else if (amountHolder.value < tipMinValue) {
      setErrorMsg(`${t('opus.errMessage_minTip')} ${tipMinValue / 100} zł`);
      setError(true);
      setStep(STEPS.AMOUNT);
    } else {
      setErrorMsg(t('errMessage'));
      setError(false);
    }
       
  };

  /**
   * Triggered by user after tip amount is entered. Displays payment selection step.
   * @param e 
   */
  const handleProceedToPaymentTypes = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    setStep(STEPS.PAYMENT_TYPE);
  };

  /**
   * Triggered when user enters 6 digit BLIK code
   * @param code user provided 6 digit BLIK code
   */
  const handleBLIKCodeEntered = (code:string) => {
      setBlikCode(code);
      setActivePaymentButton(true);
  };

  /**
   * Active look & feel  styling for button that triggers
   * payment
   * @param active 
   * @param type 
   */
  const buttonSubmitTip = (active: boolean, type: string) => {
    if (!error && (active || type === 'gpay')) {
      return `${styles.Opus_paymentSectionButton__active}`;
    } else {
      return `${styles.Opus_paymentSectionButton__disabled}`;
    }
  };

  /**
   * Manages enabled/disabled styling for button that triggers 
   * proceeding to the payement selection step
   * @param active 
   */
  const buttonProceedToPaymentTypeStyle = (active: boolean) => {
    if (!error && active) {
      return `${styles.Opus_paymentSectionButton__active}`;
    } else {
      return `${styles.Opus_paymentSectionButton__disabled}`;
    }
  };

  // Its remove 'active class' from summary invoice on Blur methods
  const activeClassHandler = (e: { stopPropagation: () => void; }) => {
    e.stopPropagation();
    if (divRef.current && divRef.current.getAttribute('style') !== null) { divRef.current.removeAttribute('style'); }
  };  

  /**
   * Triggered when user has entered amount, has selected either GPay or Apple Pay 
   * payment methods and clicks button to process tip payment
   * @param e 
   */
  const startTokenBasedPaymentTransaction = (e:any) => {
    console.log('Initiating token based transaction');
    
    if (method === 'gpay') {
      setGpayClicked(true);
      console.log('Gpay token based transaction initiated');
    }
    if (method === 'applepay'){
      setTriggerApplePayProcessing(true)
      console.log('Apple Pay token based transaction initiated');
    }      
  };
  

  /**
   * Triggers BLIK Payment processing
   * @todo extract backend communication logic to external method
   * @param e 
   */
  const handleBLIKPaymentTransaction = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    
    if (blikCode && blikCode.toString().length === 6) {
      
      setLoadingBlik(true);
      const tipHeightCalc = Math.round(summaryInvoice * 100);
      const methodType = method.charAt(0).toUpperCase();

      if (iOSversion && iOSversion < 1350) {
        console.log('iOS version is less then 13.5.0');
        const firstStepHandler = () => {
          setBlikProcessFirstStep(true);
        };
        const resultHandler = (result: PaymentResult) => {
          if(result.s == 'OK'){
            setLoadingBlik(false);
            setPaymentTYP(true);
            setTipHeightSection(false);
          }else{
            setLoadingBlik(false);
            setErrorMsg(t('errMessage_blik'));
            setError(true);
            selectMethod(undefined);
          }
        };

        processBLIKPaymentTransaction(userIdParams, tipHeightCalc, blikCode, methodType, true, firstStepHandler, resultHandler);
        // backendLib.registerPaymentAsync(tipHeightCalc, blikCode, userIdParams, methodType)
        // .then((response: any) => {
        //   console.log('response', response);
        //   setBlikProcessFirstStep(true);
        //   const checkPayment = () => {
        //     firebase.database().ref(`/asyncQ/${response.i}`)
        //     .on('value', (snapshot: any) => {
        //       if (snapshot.val()) {
        //         console.log('snapshot.val()', snapshot.val());
        //         if (snapshot.val().d.s === 'A') {
        //           setLoadingBlik(false);
        //           setPaymentTYP(true);
        //           setTipHeightSection(false);
        //         }
        //         if (snapshot.val().d.s === 'D') {
        //           setLoadingBlik(false);
        //           setErrorMsg(t('errMessage_blik'));
        //           setError(true);
        //           selectMethod(undefined);
        //         }
        //       }
        //     });
        //   };

        //   checkPayment();
        // })
        // .catch((err: any) => {
        //   console.log(`Error: ${err}`);
        //   setError(true);
        //   setErrorMsg(t('errMessage_blik'));
        // });
      } else {
        setBlikProcessFirstStep(true);
        processBLIKPaymentTransaction(userIdParams, tipHeightCalc, blikCode, methodType)
        .then((result: PaymentResult)=>{
          if(result.s == 'OK'){
            setLoadingBlik(false);
            setPaymentTYP(true);
            setTipHeightSection(false);
          }else{
            setLoadingBlik(false);
            setErrorMsg(t('errMessage_blik'));
            setError(true);
            selectMethod(undefined);
          }
        })
        // backendLib.registerPayment(tipHeightCalc, blikCode, userIdParams, methodType)
        // .then(() => {
        //   setLoadingBlik(false);
        //   setPaymentTYP(true);
        //   setTipHeightSection(false);
        // })
        // .catch(() => {
        //   setLoadingBlik(false);
        //   setErrorMsg(t('errMessage_blik'));
        //   setError(true);
        //   selectMethod(undefined);
        // });
      }
    }

    
  };

  return (
    <div className={`${styles.Opus_wrapper}`} onClick={activeClassHandler}>
      <div style={{ display: !paymentTYP && !errorTransaction ? 'grid' : 'none' }} className={`container ${styles.Opus_container}`}>

        {/* Render intro page, when user was scanned QRCode */}
        <div className={`${styles.Opus_thankYou}`} style={{ display: introTYP ? 'grid' : 'none' }}>
          {/* <ThankYou img="vaticon.svg" background="jam_jar_bg.svg"> */}
          <ThankYou imgURL={userImage} >
            <div>
              <h2 className={`title ${styles.Opus_thankYou__title}`}>{t('opus.hello')},</h2>
              <p className={`${styles.Opus_thankYou__text}`} dangerouslySetInnerHTML={innerHTMLTranslation(t('opus.intro'))}></p>
              <CssButton
                fullWidth
                className="card__activeRotateOpus"
                size="large"
                variant="outlined"
                type="button"
                onClick={() => setIntroTYP(false)}
              >
                {t('opus.cta')}
              </CssButton>
            </div>
          </ThankYou>
        </div>

        <div className={`${styles.Opus_thankYou}`} style={{ display: !introTYP ? 'grid' : 'none' }}>
          <h2 className="title title__small">{t('opus.title')}</h2>

          {/* Input for tip amount entering by user */}
          <PageAmountInput label={t('opus.amount')} isIOs={iOS} onChange={amountChanged}></PageAmountInput>

          {/* BLIK extra information - step PAYMENT_TYPE and blik method selected*/}
          {
            method === 'blik' && step === STEPS.PAYMENT_TYPE &&
            <div className={styles.Opus_blikInfo}>
              <InlineNotification
                title={t('information')}
                type="info"
                msg={t('blik_info1')}
              />
            </div>
          }

          {/* Continue button when user enters tip amount - button will be hidden when user proceeds to payment type section */}
          {
            step === STEPS.AMOUNT && (
              <CssButton
                fullWidth
                size="large"
                variant="outlined"
                type="button"
                onClick={handleProceedToPaymentTypes}
                className={buttonProceedToPaymentTypeStyle(summaryInvoice)}
                disabled={!summaryInvoice || error}
              >
                {t('opus.proceedToPaymentTypes')}
              </CssButton>
            )
          }
        </div>

        {/* Buttons for selecting payment type  */}
        { step === STEPS.PAYMENT_TYPE && (
          
          <div className={`${styles.Opus_card}`}>
            <h1 className="title mt-30">{t('paymentType')}</h1>
            <p className={`${styles.Opus_subTitle} mt-0`}>{t('choosePaymentType')}</p>
            <div className={`${styles.Opus_cardBoxes}`}>
            {
              paymentMethods.map((item) => {
                // if (iOS && item === 'applepay' && applePayIsAvailable) {
                if (iOS && item === 'applepay') {
                  // return paymentButtonElement(item);
                  return (
                    <ApplePay 
                      backendLib={backendLib} 
                      amount={summaryInvoice*100} 
                      appleSelected={method === 'applepay'} 
                      // appleSelected={false} 
                      buttonAltText={t('a')} 
                      buttonIconSvgName="applepay" 
                      triggerPayment={triggerApplePayProcessing}
                      onSelected={selectedPaymentMethod}
                      onProcessingError={()=>{setError(true)}}
                      onProcessingSuccess={setApplepayToken}
                      ></ApplePay>
                  )
                } else if (!iOS && item === 'gpay' && (window as any).PaymentRequest) {
                  return (
                    <GPButton
                      key={item}
                      method={method!}
                      select={selectedPaymentMethod}
                      gpayClicked={gpayClicked}
                      setGpayClicked={setGpayClicked}
                      setGpayToken={setGpayToken}
                      totalPrice={summaryInvoice!}
                    />
                  );
                } else if (item === 'blik') {
                  return (
                    <BLIKButton 
                      selected={method === 'blik'}
                      altText={t('opus.blikButtonAlt')}
                      onSelected={selectedPaymentMethod}
                      key={item}
                    ></BLIKButton>
                  )
                }
              })
            }
            </div>
          </div>          
        )}

        { step === STEPS.PAYMENT_TYPE && (
          // <form onSubmit={walletAccept} noValidate>
            <div className={`${styles.Opus_card}`}>
              
              {/* Blik Code input - when BLIK payment type is selected this additional input is presented 
              for BLIK Code */}
              {
                method === 'blik' && (
                  // <div className={`${styles.Opus_blikCode} mt-30`}>
                  <BLIK 
                    title={t('writeBlikCode')} 
                    placeholder={t('blikCodePlaceholder')}
                    itemReference={blikCodeRef}
                    isIOs={iOS}
                    onChange={handleBLIKCodeEntered}
                  ></BLIK>                  
                )
              }

              
              {/* This is the main button that shall trigger payment processing. For GPay
              and Apple Pay payment special method is  called. For BLIK */}
              {
                method && (
                  <CssButton
                    fullWidth
                    ref={paymentButton}
                    className={buttonSubmitTip(activePaymentButton, method)}
                    style={{margin: '-0.5rem 0 .9rem 0'}}
                    size="large"
                    variant="outlined"
                    type="submit"
                    disabled={method === 'blik' && !activePaymentButton}
                    // onClick={method === 'applepay' ? handlePaymentButtonClicked : null}
                    // onClick={method === 'applepay' ? setTriggerApplePayProcessing(true) : null}
                    onClick={method === 'applepay' || method === 'gpay' ? startTokenBasedPaymentTransaction: handleBLIKPaymentTransaction}
                  >
                    {t('opus.pay')}
                  </CssButton>
                )
              }
            </div>
          // </form>
        ) }
        { /* Error message */
          error && <h1 className="title title__err">{errorMsg}</h1>
        }
      </div>

      {/* Render thankyou page, when payment was successed */}
      <div style={{ display: paymentTYP ? 'grid' : 'none' }}>            
        <ThankYou imgURL={userImage} text={t('opus.thankYou')}>
          <div className={`${styles.Opus_paymentTYP}`}>
            {paymentTYP && <PriceStyling tip={amountFromRedirect || (summaryInvoice)} />}
          </div>          
        </ThankYou>
        <div className={`container ${styles.Opus_container}`}>
          <InfoBox htmlText={t('opus.thankYou2')}></InfoBox>
        </div>  

      </div>

      {/* Render error page transaction on redirection (3D Secure) */}
      {
        errorTransaction && (
          <div style={{ display: errorTransaction ? 'grid' : 'none', paddingTop: '3rem' }}>
            <h1 className="title title__err" dangerouslySetInnerHTML={innerHTMLTranslation(t('errMessage_Payment'))} />
            <CssButton
              fullWidth
              className="card__activeRotateOpus"
              style={{ width: 'calc(100% - (2.8rem * 2))', margin: '3rem auto', border: 'none' }}
              size="large"
              variant="outlined"
              onClick={() => setErrorTransaction(false)}
            >
              {t('pay')}
            </CssButton>
          </div>
        )
      }
      {/* Loading spinners/indicators */}
      {loading && <Loading />}
      {loadingBlik && <BLIKSpinner msg={blikProcessFirstStep ? t('opus.blik_info2') : t('opus.blik_info_first_step')} />}
    </div>
  );
};

export default Opus;
