import React, { useState } from 'react';
import styles from './PageList.module.scss';
import PageListItem from './PageListItem'
import PageListEdit from './PageListEdit'

interface Props {
  title?: string; // when provided title section is shown on top of the list 
  items?: any;  
  itemOnChange?: any;
}
/**
 * List component that presents list of items with amount (like transactions list, or tipbox settlement list)
 * When change callback is provided list elements are being clickable and editable on click - list item amount is editable by user.
 * After commited edit itemOnChange callback is called and the edited item is returned.
 * @param props.title when provided title is displayed on top of the list
 * @param props.items array of items to be presented in list
 * @param props.items[i].key id of the item on the list, must be unique on the list
 * @param props.items[i].shortLabel short label to be presented in first column
 * @param props.items[i].label long label to be presented in second column
 * @param props.items[i].amount amount to be presented in third column (should be formatted)
 * @param props.itemOnChange callback that will enable the list to be editable. When item is edited callback will be called with new item data (after user edit) or undefined (when user requested edit but has not changed the item)
 */
const PageList = (props: Props) => {
  const { title, items, itemOnChange} = props;

  const [showEdit, setShowEdit] = useState(false);
  const [currentlyEdited, setCurrentlyEdited] = useState();
  const [amount, setAmount] = useState();
  const [label, setLabel] = useState();

  /**
   * When user clicks on the list item edit popup will appear
   * @param key itemId which edit popup is triggered
   */
  const handleClick = (key: any) => {    
    if(itemOnChange){
      var clickedItem = items.find((element: any)=>{
        return element.key == key
      })            
      setCurrentlyEdited(clickedItem);
      setAmount(clickedItem.amount);
      setLabel(clickedItem.label);
      setShowEdit(true); 
    }
      
  };
  /**
   * Called when user requests list item edit but cancels the edit
   */
  const cancel = () => {    
    setShowEdit(false);      
  };

  /**
   * When user commits his edit, newly changed item is passed
   * on into change callback.
   * @param value item with modified values or undefined, when the item was edited but nothing was changed
   */
  const saveEdit = (value: any) => {    
    setShowEdit(false);       
    itemOnChange(value)   
  };
  
  return (    
    <div className={styles.PageList_dateList}>
      {/* show title only when provided */}
      {title ? <p className={styles.PageList_dateTitle}>{title}</p> : null}
      
      <table className={styles.PageList_dateTable}>
        <tbody>
          {            
          items.map((item: any) => {            
            return <PageListItem key={item.key} itemId={item.key} txt1={item.shortLabel} txt2={item.label} txt3={item.amount} onClick={handleClick}/>
          })
          }
          
        </tbody>
      </table>    
      <PageListEdit show={showEdit} item={currentlyEdited} amount={amount} label={label} onCancel={cancel} onCommit={saveEdit}></PageListEdit>
    </div>
    
    
  );
};

export default PageList;
