import React from 'react';
import styles from './PageCta.module.scss';

interface Props {
  children?: any;
}
/**
 * Main page CTA section (usually single CTA button) wrapper
 * @param props.children One should pass children Components to be displayed within section
 */
const PageCta = (props: Props) => {    
  return (
    <div className={`${styles.PageCta_tip} mt-15`}>
      <div className={`${styles.PageCta_infobox}`}>
        {props.children}      
      </div>          
    </div>       
  );
};

export default PageCta;
