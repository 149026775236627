import React, { useEffect, useState, RefObject } from 'react';
import styles from './PriceInputFake.module.scss';

type Props = {
  clicked?: (e: React.MouseEvent<HTMLElement>) => void,
  tipHeight: string | null,
  checkingPrice: boolean | any,
  reference?: RefObject<HTMLDivElement>,
};

export const PriceInput = ({clicked, tipHeight, checkingPrice, reference}: Props) => {  
  const [price, setPrice] = useState('');
  const [priceCent, setPriceCent] = useState('');
  const [separator, setSeparator] = useState(false);

  useEffect(() => {    
    checkPrice();
  });

  const checkPrice = () => {
    
    if (!checkingPrice && tipHeight) {
      const dotSummary = tipHeight.replace(',', '.');
      
      if (!dotSummary.includes('.')) {
        setPrice(dotSummary);
        setSeparator(false);
      } else if (dotSummary.includes('.')) {
        setPrice(dotSummary.split('.')[0]);
        setPriceCent(dotSummary.split('.')[1]);
        setSeparator(true);
      }      
    } else if (tipHeight === '') {
      setPrice('');
      setPriceCent('');
      setSeparator(false);      
    }
  };

  return (
    <div
      ref={reference}
      onClick={clicked}
      className={`${styles.priceInputFake_titleTip}`}
    >
      {/* Price integer */}
      <span className="inputValue inputValue__int">
        {price}
      </span>

      {/* Price separator */}
      {separator && (
        <span className="inputValue">,</span>
      )}

      {/* Cent's value */}
      {priceCent && (
        <span className="inputValue inputValue__cent">{priceCent}</span>
      )}

      {/* Current currency */}
      {price && (
        <span className="inputValue inputValue__currency">zł</span>
      )}
    </div>
  );
};
