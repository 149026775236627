import { withStyles } from '@material-ui/core/styles';
import { TextFieldProps } from '@material-ui/core/TextField';

export const InputFieldStyle = (component: React.ComponentType<TextFieldProps>) => withStyles({
  root: {
    marginTop: '1rem',
    '& .MuiInputLabel-formControl': {
      position: 'relative',
      fontFamily: '"Montserrat"',
      fontSize: '1.4rem',
      color: 'white',
      marginBottom: '.8rem',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'none',
    },
    '& .Mui-focused input': {
      fontWeight: 700,
    },
    '& .Mui-focused fieldset': {
      border: '2px solid white',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '2px',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '2px solid white',
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      border: '2px solid #ff0000',
      color: 'red',
    },
    '& .MuiOutlinedInput-root': {
      color: '#404040',
      fontFamily: '"Montserrat"',
      fontSize: '1.4rem',
      background: 'white',
      borderRadius: '.6rem',
      height: '4.5rem',
      fontWeight: 700,
      '& legend': {
        display: 'none',
      },
      '& fieldset': {
        border: '1px solid white',
        borderRadius: '.6rem',
        transition: 'none',
        top: 0,
      },
      '&:hover fieldset': {
        border: '2px solid white',
      },
      '& .Mui-focused fieldset': {
        border: '2px solid white',
      },
      '& .MuiOutlinedInput-input': {
        padding: '1.2rem .5rem',
      },
      '& .MuiSvgIcon-root': {
        fontSize: '2rem',
      },
    },
    '& .MuiFormHelperText-contained': {
      // position: 'absolute',
      // right: 0,
      fontFamily: '"Montserrat"',
      margin: '.5rem 0 0 0',
      fontSize: '1.2rem',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: '#ffc107',
    },
  },
})(component);
