// TODO: Refactor this file. It is to long!
import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import UserConsumer from 'context/user/User';
import TextField from '@material-ui/core/TextField';
import { InputFieldStyle } from 'components/override_styles/TextFieldWhite';
import Button from '@material-ui/core/Button';
import { ButtonStyle } from 'components/override_styles/ButtonWhite';
import InputMask from 'react-input-mask';
import { iban, validatePolish, passportNumberRegex } from 'utils/validators';
import Notification from 'components/notification/Notification';
import Select from '@material-ui/core/Select';
import { SelectStyle } from 'components/override_styles/SelectProfile';
import countries from 'i18n-iso-countries';
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import Loading from 'components/loading/Loading';
import Compressor from 'compressorjs';
import moment from 'moment';
import styles from './Payback_data.module.scss';
import { BackendApp } from 'libs/App';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import InlineNotification from 'components/inline_notification/InlineNotification';
import { toBase64 } from 'utils/toBase64';

// Initialize backend methods
const backendLib = BackendApp();

// TODO: This is the better way for overriding styles in MUI
// we should refactor all code to this solution in the future

const useStyles = makeStyles(() => ({
  formControl: {
    'margin': '0 0 2rem 0',
    '& fieldset': {
      border: '1px solid #CDD8EF',
      transition: 'none',
      borderRadius: '.6rem',
    },
  },
  selectLabel: {
    transform: 'none',
    color: '#404040',
    position: 'relative',
    fontSize: '1.4rem',
    fontFamily: 'Montserrat',
    marginBottom: '.8rem',
  },
  fileButton: {
    'fontFamily': '"Montserrat"',
    'margin': '2.5rem 0 .9rem 0',
    'border': '2px solid #c3b9de',
    'borderRadius': '.6rem',
    'color': '#8772BD',
    'textTransform': 'inherit',
    'fontSize': '1.4rem',
    'background': 'transparent',
    '&:hover, &:focus, &:active': {
      background: 'transparent',
    },
  },
  fileButtonError: {
    color: '#E9649B',
    border: '2px solid #E9649B',
  },
  rotateIconActive: {
    color: '#2B0096',
    transform: 'rotate(30deg)',
  },
  rotateIconInactive: {
    color: '#a5a5a5',
    transform: 'rotate(30deg)',
  },
}));

// Overridden material styles
const CssTextField = InputFieldStyle(TextField);
const CssButton = ButtonStyle(Button);
const CssSelect = SelectStyle(Select);

interface ContextProps {
  currentUser: {
    userId: string,
  };
}

interface DocumentAndActivity {
  name: string;
  code: string;
}

// TODO: Can by changed when BE was ready
countries.registerLocale(require('i18n-iso-countries/langs/pl.json'));
const nationalities = Object.entries(countries.getNames('pl'));

// TODO: All this text should be translated
const documentTypes = [
  {
    name: 'Dowód osobisty',
    code: 'I',
  },
  {
    name: 'Paszport',
    code: 'P',
  },
];

const activities = [
  {
    name: 'Restauracja',
    code: 'T13',
  },
  {
    name: 'Kawiarnia',
    code: 'T6',
  },
  {
    name: 'Dostawa jedzenia',
    code: 'T3',
  },
  {
    name: 'Salon urody',
    code: 'T14',
  },
  {
    name: 'Fryzjer',
    code: 'T4',
  },
  {
    name: 'Obsługa hotelowa',
    code: 'T11',
  },
  {
    name: 'Recepcja',
    code: 'T12',
  },
  {
    name: 'Szatnia',
    code: 'T15',
  },
  {
    name: 'Myjnia samochodowa',
    code: 'T10',
  },
  {
    name: 'Autodetailing',
    code: 'T2',
  },
  {
    name: 'Kurier',
    code: 'T9',
  },
  {
    name: 'Kasyno',
    code: 'T5',
  },
  {
    name: 'Kierowca Taxi',
    code: 'T8',
  },
  {
    name: 'Kierowca autobusu',
    code: 'T7',
  },
  {
    name: 'Artysta uliczny',
    code: 'T1',
  },
];

const acceptFiles = '.jpg, .png';
const maxFileSize = 20; // Max file size in MB

const alphabetic = (a: string, b: string) => a.localeCompare(b);

documentTypes.sort((a: DocumentAndActivity, b: DocumentAndActivity) => alphabetic(a.name, b.name));

nationalities.sort((a: any, b: any) => alphabetic(a[1], b[1]));
// Move Polska to first position
// TODO: Optimization
nationalities.forEach((country, index) => {
  if (country[0] === 'PL') {
    nationalities.splice(index, 1);
    nationalities.unshift(country);
  }
});

// Make Polska uppercase
nationalities[0][1] = nationalities[0][1].toUpperCase();

activities.sort((a: DocumentAndActivity, b: DocumentAndActivity) => alphabetic(a.name, b.name));
activities.push({
  name: 'Inne',
  code: 'T0',
});

const PaybackData = () => {
  const classes = useStyles();
  const dateUtcOffset = moment().utcOffset();
  const { t } = useTranslation();
  const time = 6000;
  const userConsumer = useContext<Partial<ContextProps>>(UserConsumer);
  const { currentUser: { userId } }: any = userConsumer;
  const initialFormData = {
    accountNumber: '',
    receiverName: '',
    parishName: '',
    parishNIP: '',
    parishRegon: '',
    pesel: '',
    street: '',
    buildingNumber: '',
    zipCode: '',
    city: '',
    documentType: '',
    documentNumber: '',
    // activity: '',
    nationality: 'PL',
    verificationStatus: '',
    d1: {
      name: '',
      data: '',
    },
    d2: {
      name: '',
      data: '',
    },
    d3: {
      name: '',
      data: '',
    },
    d4: {
      name: '',
      data: '',
    },
    d5: {
      name: '',
      data: '',
    }
  };
  const [showSuccessNotification, setShowSuccessNotification] = useState(false);
  const [showFailNotification, setShowFailNotification] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [backendData, setBackendData] = useState(initialFormData);
  const [loading, setLoading] = useState(true);

  // Validation states
  const [accountNumberError, setAccountNumberError] = useState('');
  const [receiverNameError, setReceiverNameError] = useState('');
  const [peselError, setPeselError] = useState('');
  const [streetError, setStreetError] = useState('');
  const [parishNameError, setParishNameError] = useState('');
  const [parishNIPError, setParishNIPError] = useState('');
  const [parishRegonError, setParishRegonError] = useState('');
  const [buildingNumberError, setBuildingNumberError] = useState('');
  const [zipCodeError, setZipCodeError] = useState('');
  const [cityError, setCityError] = useState('');
  const [documentTypeError, setDocumentTypeError] = useState('');
  const [documentNumberError, setDocumentNumberError] = useState('');
  const [activityError, setActivityError] = useState('');
  const [nationalityError, setNationalityError] = useState('');
  const [filesError, setFilesError] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let value = event.target.value;
    // Remove spaces and mask characters from data
    if (event.target.name === 'accountNumber' || event.target.name === 'zipCode' || event.target.name === 'pesel' || event.target.name === 'documentNumber') {
      value = value.replace(/\s|_/g, '');
    }

    if (event.target.name === 'documentNumber') {
      value = value.toUpperCase();
    }

    setFormData({...formData, [event.target.name]: value});
  };

  const handleSelectChange = (event: React.ChangeEvent<{value: unknown }>): void => {
    const value = event.target.value;

    // Clear photos when document type was changed
    if ((event.target as HTMLSelectElement).name === 'documentType' && value) {
      setFormData({
        ...formData,
        [(event.target as HTMLSelectElement).name]: value,
        d1: {
          name: '',
          data: '',
        },
        d2: {
          name: '',
          data: '',
        },
        d3: {
          name: '',
          data: '',
        },
      });
    } else {
      setFormData({...formData, [(event.target as HTMLSelectElement).name]: value});
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const fileSize = (event.target as HTMLInputElement).files![0].size / 1024 / 1024;
    const file = event.target.files![0];
    if (fileSize > maxFileSize) {
      (event.target.parentNode!.parentNode as HTMLElement).style.cssText = 'color: #E9649B; border: 2px solid #E9649B;';
      (event.target.parentNode!.parentNode!.nextSibling as HTMLElement).style.color = '#E9649B';
    } else {
      const compress = () => new Compressor(file, {
        quality: 0.6,
        maxWidth: 800,
        success(result) {
          toBase64(result).then((data) => {
            setFormData({...formData, [event.target.name]: {
              name: file.name,
              data,
            }});
          });
        },
        error(err) {
          console.log(err.message);
        },
      });
      compress();
    }
  };

  const isDocumentValid = (whatToValidate: string, documentNumber: string): boolean => {
    if (whatToValidate === 'I') {
      return validatePolish.identityCard(documentNumber);
    }
    if (whatToValidate === 'P') {
      return !!documentNumber.match(passportNumberRegex);
    }
    return true;
  };

  const handleSubmit = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();

    // Validate inputs
    setAccountNumberError('');
    setReceiverNameError('');
    setZipCodeError('');
    setPeselError('');
    setStreetError('');
    setBuildingNumberError('');
    setCityError('');
    setDocumentNumberError('');
    setDocumentTypeError('');
    setActivityError('');
    setNationalityError('');
    setFilesError('');

    let readyToSend = true;
    if (formData.accountNumber.length) {
      const plAccountNumber = `PL${formData.accountNumber}`;
      if (!iban.isValid(plAccountNumber)) {
        setAccountNumberError(t('wrong_account_number'));
        readyToSend = false;
      }
    } else {
      setAccountNumberError(t('required'));
      readyToSend = false;
    }

    if (!formData.receiverName.length) {
      setReceiverNameError(t('required'));
      readyToSend = false;
    } else if (formData.receiverName.trim().indexOf(' ') < 0) {
      setReceiverNameError(t('type_name_and_surname'));
      readyToSend = false;
    }

    if (formData.pesel.length) {
      if (!validatePolish.pesel(formData.pesel)) {
        setPeselError(t('wrong_data'));
        readyToSend = false;
      }
    }

    if (formData.nationality === 'PL') {
      if (formData.pesel.length) {
        if (!validatePolish.pesel(formData.pesel)) {
          setPeselError(t('wrong_data'));
          readyToSend = false;
        }
      } else {
        setPeselError(t('required'));
        readyToSend = false;
      }
    }

    if (!formData.documentType.length) {
      setDocumentTypeError(t('required'));
      readyToSend = false;
    }

    if (!formData.parishName.length) {
      setParishNameError(t('required'));
      readyToSend = false;
    }

    if (formData.parishNIP.length!=10) {
      setParishNIPError(t('required'));
      readyToSend = false;
    }
    if (formData.parishRegon.length!=9) {
      setParishRegonError(t('required'));
      readyToSend = false;
    }

    if (formData.documentNumber.length) {
      if (!isDocumentValid(formData.documentType, formData.documentNumber)) {
        setDocumentNumberError(t('wrong_data'));
        readyToSend = false;
      }
    } else {
      setDocumentNumberError(t('required'));
      readyToSend = false;
    }

    if (!formData.nationality.length) {
      setNationalityError(t('required'));
      readyToSend = false;
    }

    if (!formData.street.length) {
      setStreetError(t('required'));
      readyToSend = false;
    }

    if (!formData.buildingNumber.length) {
      setBuildingNumberError(t('required'));
      readyToSend = false;
    }

    if (formData.zipCode.length) {
      if (formData.zipCode.length !== 6) {
        setZipCodeError(t('wrong_data'));
        readyToSend = false;
      }
    } else {
      setZipCodeError(t('required'));
      readyToSend = false;
    }

    if (!formData.city.length) {
      setCityError(t('required'));
      readyToSend = false;
    }

    // disable validation
    // if (!formData.activity.length) {
    //   setActivityError(t('required'));
    //   readyToSend = false;
    // }

    if (formData.documentType === 'P') {
      if (!formData.d1.data || !formData.d2.data || !formData.d4.data || !formData.d5.data) {
        readyToSend = false;
        setFilesError(t('required'));
      }
    } else {
      if (!formData.d1.data || !formData.d2.data || !formData.d3.data || !formData.d4.data || !formData.d5.data) {
        readyToSend = false;
        setFilesError(t('required'));
      }
    }

    if (readyToSend) {
      console.log('No errors. Sending the data.');

      const nameAndSurname = formData.receiverName.trim().replace(/ +(?= )/g,'');

      const mappedDataToSend = {
        dn: nameAndSurname,
        n: nameAndSurname.substring(0, formData.receiverName.lastIndexOf(' ')),
        sn: nameAndSurname.substring(formData.receiverName.lastIndexOf(' ') + 1),
        v: {
          i1: formData.pesel,
          i2: formData.parishNIP,
          i3: formData.parishRegon,
          idt1: formData.documentType,
          id1: formData.documentNumber,
          s: 'P', // After CTA set status to P - pending
          st: moment().add(dateUtcOffset, 'm').valueOf(),
        },
        bn: formData.accountNumber,
        s: formData.street,
        sno: formData.buildingNumber,
        z: formData.zipCode,
        c: formData.city,
        e1: 'T16',  // religious place
        e2: formData.parishName,
        cs : formData.nationality,
        mt: moment().add(dateUtcOffset, 'm').valueOf(),
      };
      setLoading(true);

      backendLib.backendUpdateUserEntity('userData', userId, mappedDataToSend)
        .then(() => {
          
          backendLib._callAPI('userUpdateBinaries', {
            d1: formData.d1.data,
            d2: formData.d2.data,
            d3: formData.d3.data,
            d4: formData.d4.data,
            d5: formData.d5.data,
          })
          .then(() => {
            setShowSuccessNotification(true);
          })
          .catch(() => setShowFailNotification(true))
          .finally(() => setLoading(false));
        });
    } else {
      console.log('There is some errors in form!');
    }
  };

  const handleFileDelete = (fileId: string) => {
    setFormData({...formData, [fileId]: {
      name: '',
      data: '',
    }});
  };

  useEffect(() => {
    setLoading(true);
    backendLib.backendLoadUser(userId).then((userData: any) => {
      const emptyImageData = userData.v && userData.v.s ? userData.v.s === 'U' ? '' : 'empty' : '';
      const dataModel = {
        accountNumber: userData.bn || '',
        receiverName: userData.dn || '',
        parishName: userData.e2 ? userData.e2 || '' : '',
        parishNIP: userData.v ? userData.v.i2 || '' : '',
        parishRegon: userData.v ? userData.v.i3 || '' : '',
        pesel: userData.v ? userData.v.i1 || '' : '',
        street: userData.s || '',
        buildingNumber: userData.sno || '',
        zipCode: userData.z || '',
        city: userData.c || '',
        documentType: userData.v ? userData.v.idt1 || '' : '',
        documentNumber: userData.v ? userData.v.id1 || '' : '',
        // activity: userData.e1 || '',
        nationality: userData.cs || 'PL',
        verificationStatus: userData.v && userData.v.s ? userData.v.s : 'U',
        d1: {
          name: '',
          data: emptyImageData,
        },
        d2: {
          name: '',
          data: emptyImageData,
        },
        d3: {
          name: '',
          data: emptyImageData,
        },
        d4: {
          name: '',
          data: emptyImageData,
        },
        d5: {
          name: '',
          data: emptyImageData,
        },
      };
      setLoading(false);
      setBackendData(dataModel);
      setFormData(dataModel);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSuccessNotification]);

  useEffect(() => {
    if (formData.nationality && formData.nationality !== 'PL') {
      setFormData({...formData, documentType: 'P'});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.nationality]);

  const disableButtonWithoutPeselFilled = (Object.values(backendData).length - 1) === Object.values(backendData).filter((a) => a).length;
  const isDisabled = backendData.verificationStatus !== 'U';

  return (
    <>
      <div>
        {backendData.verificationStatus === 'U' || backendData.verificationStatus === 'B' ? <InlineNotification title={t('warning')} type="info" htmlMsg={t('payback_data_warning')} /> : null}
        {
          loading ? <Loading /> :
          (
            <form onSubmit={handleSubmit}>              
              <div className={`${styles.paybackData_label} ${styles.paybackData_label__bold}`}>
                {t('representant_data')}                
              </div>
              <CssTextField
                fullWidth
                autoComplete="off"
                name="receiverName"
                label={`${t('receiver_name')}*`}
                placeholder={t('name_and_surname')}
                helperText={receiverNameError}
                margin="normal"
                variant="outlined"
                type="text"
                InputLabelProps={{shrink: true}}
                onChange={handleChange}
                error={!!receiverNameError}
                value={formData.receiverName}
                disabled={isDisabled}
              />
              <FormControl
                fullWidth
                className={classes.formControl}
              >
                <InputLabel
                  shrink
                  htmlFor="nationality-native-label-placeholder"
                  className={classes.selectLabel}
                >
                  {`${t('nationality')}*`}
                </InputLabel>
                <CssSelect
                  native
                  value={formData.nationality}
                  onChange={handleSelectChange}
                  fullWidth
                  variant="outlined"
                  inputProps={{
                    name: 'nationality',
                    id: 'nationality-native-label-placeholder',
                  }}
                  disabled={isDisabled}
                  error={!!nationalityError}
                >
                  {
                    nationalities.map((element: [string, string]) => (
                      <option key={element[0]} value={element[0]}>{element[1]}</option>
                    ))
                  }
                </CssSelect>
                {nationalityError && <FormHelperText error>{nationalityError}</FormHelperText>}
              </FormControl>
              <InputMask
                mask="9 9 9 9 9 9 9 9 9 9 9"
                onChange={handleChange}
                value={formData.pesel}
                disabled={isDisabled}
              >
                {
                  () => (
                    <CssTextField
                      fullWidth
                      autoComplete="off"
                      name="pesel"
                      label={`${t('pesel')}${formData.nationality === 'PL' ? '*' : ''}`}
                      placeholder={t('pesel')}
                      helperText={peselError}
                      margin="normal"
                      variant="outlined"
                      type="tel"
                      InputLabelProps={{ shrink: true }}
                      error={!!peselError}
                      disabled={isDisabled}
                    />
                  )
                }
              </InputMask>
              <FormControl
                fullWidth
                className={classes.formControl}
              >
                <InputLabel
                  shrink
                  htmlFor="documentType-native-label-placeholder"
                  className={classes.selectLabel}
                >
                  {`${t('document_type')}*`}
                </InputLabel>
                <CssSelect
                  native
                  value={formData.documentType}
                  onChange={handleSelectChange}
                  fullWidth
                  variant="outlined"
                  inputProps={{
                    name: 'documentType',
                    id: 'documentType-native-label-placeholder',
                  }}
                  disabled={isDisabled || !!(formData.nationality && formData.nationality !== 'PL')}
                  error={!!documentTypeError}
                >
                  <option value="">{t('please_choose')}</option>
                  {
                    documentTypes.map((element: DocumentAndActivity, index: number) => (
                      <option key={index} value={element.code}>{element.name}</option>
                    ))
                  }
                </CssSelect>
                {documentTypeError && <FormHelperText error>{documentTypeError}</FormHelperText>}
              </FormControl>
              <InputMask
                mask={formData.documentType === 'P' ? '********************' : 'aaa 999999'}
                maskChar={formData.documentType === 'P' ? ' ' : '_'}
                onChange={handleChange}
                value={formData.documentNumber}
                disabled={isDisabled}
              >
                {
                  () => (
                    <CssTextField
                      fullWidth
                      autoComplete="off"
                      name="documentNumber"
                      label={`${t('document_number')}*`}
                      placeholder={t('document_number')}
                      helperText={documentNumberError}
                      margin="normal"
                      variant="outlined"
                      type="text"
                      InputLabelProps={{ shrink: true }}
                      error={!!documentNumberError}
                      disabled={isDisabled}
                    />
                  )
                }
              </InputMask>
              {formData.documentType && (
                <div>
                  <div className={styles.paybackData_label}>{t('add_photo_or_scan_of_document')}*</div>
                  {
                    formData.d1.data
                    ?
                    (
                      <div className={styles.paybackData_filesControl}>
                        <AttachFileOutlinedIcon className={formData.d1.name ? classes.rotateIconActive : classes.rotateIconInactive} />
                        <span
                          className={formData.d1.name ? styles.paybackData_filesControl__fileNameActive : styles.paybackData_filesControl__fileNameInactive}
                        >
                          {formData.d1.name || `${t('file')} 1`}
                        </span>
                        {formData.d1.name && (
                          <span
                            className={styles.paybackData_filesControl__action}
                            onClick={() => handleFileDelete('d1')}
                          >
                            {t('delete')}
                          </span>
                        )}
                      </div>
                    )
                    :
                    (
                      <div>
                        <Button
                          className={`${classes.fileButton} ${!!filesError ? classes.fileButtonError : null}`}
                          variant="contained"
                          component="label"
                          fullWidth
                          disableElevation
                        >
                          {formData.documentType === 'P' ? t('add_passport_document') : t('add_front_of_document')}
                          <input
                            type="file"
                            style={{ display: 'none' }}
                            accept={acceptFiles}
                            name="d1"
                            onChange={handleFileChange}
                          />
                        </Button>
                        <div className={styles.paybackData_fileInfo}>
                          {t('allowed_files')}: {acceptFiles}.<br />{t('max_file_weight')} {maxFileSize.toString().replace('.', ',')} MB.
                        </div>
                      </div>
                    )
                  }
                  {
                    formData.d2.data
                    ?
                    (
                      <div className={styles.paybackData_filesControl}>
                        <AttachFileOutlinedIcon className={formData.d2.name ? classes.rotateIconActive : classes.rotateIconInactive} />
                        <span
                          className={formData.d2.name ? styles.paybackData_filesControl__fileNameActive : styles.paybackData_filesControl__fileNameInactive}
                        >
                          {formData.d2.name || `${t('file')} 2`}
                        </span>
                        {formData.d2.name && (
                          <span
                            className={styles.paybackData_filesControl__action}
                            onClick={() => handleFileDelete('d2')}
                          >
                            {t('delete')}
                          </span>
                        )}
                      </div>
                    )
                    :
                    (
                      <div>
                        <Button
                          className={`${classes.fileButton} ${!!filesError ? classes.fileButtonError : null}`}
                          variant="contained"
                          component="label"
                          fullWidth
                          disableElevation
                        >
                          {formData.documentType === 'P' ? t('add_selfie') : t('add_back_of_document')}
                          <input
                            type="file"
                            style={{ display: 'none' }}
                            accept={acceptFiles}
                            name="d2"
                            onChange={handleFileChange}
                          />
                        </Button>
                        <div className={styles.paybackData_fileInfo}>
                          {t('allowed_files')}: {acceptFiles}.<br />{t('max_file_weight')} {maxFileSize.toString().replace('.', ',')} MB.
                        </div>
                      </div>
                    )
                  }
                  {formData.documentType === 'I' && (
                    <>
                      {
                        formData.d3.data
                        ?
                        (
                          <div className={styles.paybackData_filesControl}>
                            <AttachFileOutlinedIcon className={formData.d3.name ? classes.rotateIconActive : classes.rotateIconInactive} />
                            <span
                              className={formData.d3.name ? styles.paybackData_filesControl__fileNameActive : styles.paybackData_filesControl__fileNameInactive}
                            >
                              {formData.d3.name || `${t('file')} 3`}
                            </span>
                            {formData.d3.name && (
                              <span
                                className={styles.paybackData_filesControl__action}
                                onClick={() => handleFileDelete('d3')}
                              >
                                {t('delete')}
                              </span>
                            )}
                          </div>
                        )
                        :
                        (
                          <div>
                            <Button
                              className={`${classes.fileButton} ${!!filesError ? classes.fileButtonError : null}`}
                              variant="contained"
                              component="label"
                              fullWidth
                              disableElevation
                            >
                              {t('add_selfie')}
                              <input
                                type="file"
                                style={{ display: 'none' }}
                                accept={acceptFiles}
                                name="d3"
                                onChange={handleFileChange}
                              />
                            </Button>
                            <div className={styles.paybackData_fileInfo}>
                              {t('allowed_files')}: {acceptFiles}.<br />{t('max_file_weight')} {maxFileSize.toString().replace('.', ',')} MB.
                            </div>
                          </div>
                        )
                      }
                    </>
                  )}
                  {
                    formData.d4.data
                    ?
                    (
                      <div className={styles.paybackData_filesControl}>
                        <AttachFileOutlinedIcon className={formData.d3.name ? classes.rotateIconActive : classes.rotateIconInactive} />
                        <span
                          className={formData.d4.name ? styles.paybackData_filesControl__fileNameActive : styles.paybackData_filesControl__fileNameInactive}
                        >
                          {formData.d4.name ? formData.d4.name : formData.documentType === 'I' ? `${t('file')} 4` : `${t('file')} 3`}
                        </span>
                        {formData.d4.name && (
                          <span
                            className={styles.paybackData_filesControl__action}
                            onClick={() => handleFileDelete('d4')}
                          >
                            {t('delete')}
                          </span>
                        )}
                      </div>
                    )
                    :
                    (
                      <div>
                        <Button
                          className={`${classes.fileButton} ${!!filesError ? classes.fileButtonError : null}`}
                          variant="contained"
                          component="label"
                          fullWidth
                          disableElevation
                        >
                          {t('add_bank_document')}
                          <input
                            type="file"
                            style={{ display: 'none' }}
                            accept={acceptFiles}
                            name="d4"
                            onChange={handleFileChange}
                          />
                        </Button>
                        <div className={styles.paybackData_fileInfo}>
                          {t('allowed_files')}: {acceptFiles}.<br />{t('max_file_weight')} {maxFileSize.toString().replace('.', ',')} MB.
                        </div>
                      </div>
                    )
                  }
                  {
                    formData.d5.data
                    ?
                    (
                      <div className={styles.paybackData_filesControl}>
                        <AttachFileOutlinedIcon className={formData.d3.name ? classes.rotateIconActive : classes.rotateIconInactive} />
                        <span
                          className={formData.d5.name ? styles.paybackData_filesControl__fileNameActive : styles.paybackData_filesControl__fileNameInactive}
                        >
                          {formData.d5.name ? formData.d5.name : formData.documentType === 'I' ? `${t('file')} 5` : `${t('file')} 4`}
                        </span>
                        {formData.d5.name && (
                          <span
                            className={styles.paybackData_filesControl__action}
                            onClick={() => handleFileDelete('d5')}
                          >
                            {t('delete')}
                          </span>
                        )}
                      </div>
                    )
                    :
                    (
                      <div>
                        <Button
                          className={`${classes.fileButton} ${!!filesError ? classes.fileButtonError : null}`}
                          variant="contained"
                          component="label"
                          fullWidth
                          disableElevation
                        >
                          {t('add_parish_document')}
                          <input
                            type="file"
                            style={{ display: 'none' }}
                            accept={acceptFiles}
                            name="d5"
                            onChange={handleFileChange}
                          />
                        </Button>
                        <div className={styles.paybackData_fileInfo}>
                          {t('allowed_files')}: {acceptFiles}.<br />{t('max_file_weight')} {maxFileSize.toString().replace('.', ',')} MB.
                        </div>
                      </div>
                    )
                  }
                </div>
              )}
              <div className={`${styles.paybackData_label} ${styles.paybackData_label__bold}`}>
                {t('address')}
                <span className={styles.paybackData_label__normal}> ({t('assigned_to_bank_account')})</span>
              </div>
              <CssTextField
                fullWidth
                autoComplete="off"
                name="parishName"
                label={`${t('parishName')}*`}
                placeholder={t('parishName')}
                helperText={streetError}
                margin="normal"
                variant="outlined"
                type="text"
                InputLabelProps={{shrink: true}}
                onChange={handleChange}
                value={formData.parishName}
                error={!!parishNameError}
                disabled={isDisabled}
              />
              <InputMask
                mask="99 9999 9999 9999 9999 9999 9999"
                onChange={handleChange}
                value={formData.accountNumber}
                disabled={isDisabled}
              >
                {
                  () => (
                    <CssTextField
                      fullWidth
                      autoComplete="off"
                      name="accountNumber"
                      label={`${t('bank_account_number')}*`}
                      placeholder={t('account_number')}
                      helperText={accountNumberError}
                      margin="normal"
                      variant="outlined"
                      type="tel"
                      InputLabelProps={{ shrink: true }}
                      error={!!accountNumberError}
                      disabled={isDisabled}
                    />
                  )
                }
              </InputMask>
              
              <CssTextField
                fullWidth
                autoComplete="off"
                name="parishNIP"
                label={`${t('parishNIP')}*`}
                placeholder={t('parishNIP')}
                helperText={streetError}
                margin="normal"
                variant="outlined"
                type="text"
                InputLabelProps={{shrink: true}}
                onChange={handleChange}
                value={formData.parishNIP}
                error={!!parishNIPError}
                disabled={isDisabled}
              />
              <CssTextField
                fullWidth
                autoComplete="off"
                name="parishRegon"
                label={`${t('parishRegon')}*`}
                placeholder={t('parishRegon')}
                helperText={streetError}
                margin="normal"
                variant="outlined"
                type="text"
                InputLabelProps={{shrink: true}}
                onChange={handleChange}
                value={formData.parishRegon}
                error={!!parishRegonError}
                disabled={isDisabled}
              />
              <CssTextField
                fullWidth
                autoComplete="off"
                name="street"
                label={`${t('street')}*`}
                placeholder={t('street')}
                helperText={streetError}
                margin="normal"
                variant="outlined"
                type="text"
                InputLabelProps={{shrink: true}}
                onChange={handleChange}
                value={formData.street}
                error={!!streetError}
                disabled={isDisabled}
              />
              <CssTextField
                fullWidth
                autoComplete="off"
                name="buildingNumber"
                label={`${t('building_number')}*`}
                placeholder={t('building_number')}
                helperText={buildingNumberError}
                margin="normal"
                variant="outlined"
                type="text"
                InputLabelProps={{shrink: true}}
                onChange={handleChange}
                value={formData.buildingNumber}
                error={!!buildingNumberError}
                disabled={isDisabled}
              />
              {formData.nationality !== 'PL' ? (
                <CssTextField
                  fullWidth
                  autoComplete="off"
                  name="zipCode"
                  label={`${t('zip_code')}*`}
                  placeholder={t('zip_code')}
                  helperText={zipCodeError}
                  margin="normal"
                  variant="outlined"
                  type="tel"
                  InputLabelProps={{shrink: true}}
                  onChange={handleChange}
                  value={formData.zipCode}
                  error={!!zipCodeError}
                  disabled={isDisabled}
                />
              ) : (
                <InputMask
                  mask="99-999"
                  onChange={handleChange}
                  value={formData.zipCode}
                  disabled={isDisabled}
                >
                  {
                    () => (
                      <CssTextField
                        fullWidth
                        autoComplete="off"
                        name="zipCode"
                        label={`${t('zip_code')}*`}
                        placeholder={t('zip_code')}
                        helperText={zipCodeError}
                        margin="normal"
                        variant="outlined"
                        type="tel"
                        InputLabelProps={{shrink: true}}
                        error={!!zipCodeError}
                        disabled={isDisabled}
                      />
                    )
                  }
                </InputMask>
              )}
              <CssTextField
                fullWidth
                autoComplete="off"
                name="city"
                label={`${t('city')}*`}
                placeholder={t('city')}
                helperText={cityError}
                margin="normal"
                variant="outlined"
                type="text"
                InputLabelProps={{shrink: true}}
                onChange={handleChange}
                value={formData.city}
                error={!!cityError}
                disabled={isDisabled}
              />
              {/* <FormControl
                fullWidth
                className={classes.formControl}
              >
                <InputLabel
                  shrink
                  htmlFor="activities-native-label-placeholder"
                  className={classes.selectLabel}
                >
                  {`${t('where_collect')}*`}
                </InputLabel>
                <CssSelect
                  native
                  value={formData.activity}
                  onChange={handleSelectChange}
                  fullWidth
                  variant="outlined"
                  inputProps={{
                    name: 'activity',
                    id: 'activities-native-label-placeholder',
                  }}
                  disabled={isDisabled}
                  error={!!activityError}
                >
                  <option value="">{t('please_choose')}</option>
                  {
                    activities.map((element: DocumentAndActivity, index: number) => (
                      <option key={index} value={element.code}>{element.name}</option>
                    ))
                  }
                </CssSelect>
                {activityError && <FormHelperText error>{activityError}</FormHelperText>}
              </FormControl> */}
              <CssButton
                fullWidth
                size="large"
                variant="outlined"
                type="submit"
                disabled={disableButtonWithoutPeselFilled || isDisabled}
              >
                {t('send_to_verification')}
              </CssButton>
            </form>
          )
        }
        {
          showFailNotification &&
          <Notification
            type="error"
            msg={t('save_error')}
            autoHideDuration={time}
          />
        }
        {
          showSuccessNotification &&
          <Notification
            type="save"
            msg={t('save_success')}
            autoHideDuration={time}
          />
        }
      </div>
    </>
  );
};

export default PaybackData;
