import React, { useState, useEffect, useRef, useContext, useLayoutEffect } from 'react';
import UserConsumer from 'context/user/User';
import { RouteComponentProps } from 'react-router-dom';
import styles from './TipCodeGive.module.scss';
import { useTranslation } from 'react-i18next';
import { scrollTo } from 'utils/calculates';
import { BackendApp } from 'libs/App';
import { amount, ALLOWED_SIGN } from 'utils/validators';

import ThankYou from 'components/thankYou/ThankYou';
import PriceStyling from 'components/priceStyling/PriceStyling';

import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import TextField from '@material-ui/core/TextField';
import { InputFieldBlikStyle } from 'components/override_styles/TextFieldBlik';
import InputMask from 'react-input-mask';

import { PriceInput } from 'components/priceInputFake/PriceInputFake';

import { ButtonStyle } from 'components/override_styles/Button';

import { InputFieldStyle } from 'components/override_styles/TextField';
import Loading from 'components/loading/Loading';
import GPButton from 'components/gpay/GPay';
import { innerHTMLTranslation, checkVersion } from 'utils/utils';
import firebase from 'firebase/app';
import InlineNotification from 'components/inline_notification/InlineNotification';
import LoadingBlik from 'components/loading_blik/LoadingBlik';

const KEY = 0;
const VALUE = 1;

// Initialize backend methods
const backendLib = BackendApp();

// Overridden material styles
const CssButton = ButtonStyle(Button);
const CssTextField = InputFieldStyle(TextField);
const CssTextFieldBlik = InputFieldBlikStyle(TextField);

const iOSversion = checkVersion();

const paymentMethods = ['blik', 'gpay', 'applepay'];

interface ContextProps {
  currentUser: {
    userId: string,
    isLoggedIn: boolean,
    iOS: boolean,
  };
  configuration: {
    tipMinValue: number,
    tipMaxValue: number,
  };
}

const TipCode = (props: RouteComponentProps) => {
  const userConsumer = useContext<Partial<ContextProps>>(UserConsumer);
  const {
    currentUser: { iOS },
    configuration: { tipMinValue, tipMaxValue },
  }: any = userConsumer;
  const { t } = useTranslation();

  const [userIdParams, setUserIdParams] = useState();
  const [summaryInvoice, setSummary] = useState();
  const [blikCode, setBlikCode] = useState();
  const [tipHeightSection, setTipHeightSection] = useState(false);
  const [method, selectMethod] = useState();
  const [activePaymentButton, setActivePaymentButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingBlik, setLoadingBlik] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(t('errMessage'));
  const [applePayIsAvailable, setApplePayIsAvailable] = useState(false);

  const [introTYP, setIntroTYP] = useState(true);
  const [paymentTYP, setPaymentTYP] = useState(false);
  const [errorTransaction, setErrorTransaction] = useState(false);
  const [amountFromRedirect, setAmountFromRedirect] = useState();

  const [checkingPrice, stopCheckingPrice] = useState(false);

  const blikCodeRef = useRef<HTMLDivElement>(null);
  const inputValue = useRef<HTMLInputElement>();
  const divRef = useRef<HTMLDivElement>(null);
  const paymentButton = useRef<HTMLButtonElement>(null);

  const [gpayClicked, setGpayClicked] = useState(false);
  const [gpayToken, setGpayToken] = useState();
  const [applepayToken, setApplepayToken] = useState();
  const [blikProcessFirstStep, setBlikProcessFirstStep] = useState(false);

  useLayoutEffect(() => {
    if (paymentButton.current) {
      scrollTo(paymentButton.current.offsetTop);
    }
  }, [activePaymentButton]);

  useEffect(() => {
    checkIsApplePayIsAvailable();

    const parameters = props.location.search.replace('?', '').split('&');

    if (window.document.referrer && window.document.referrer.includes('cashierui')) {
      const statusOfTransaction = new URLSearchParams(window.document.referrer).get('status');

      if (statusOfTransaction === 'ERROR') { setErrorTransaction(true); }
      if (statusOfTransaction === 'SUCCESS') {
        const amount = new URLSearchParams(window.document.referrer).get('amount');
        setPaymentTYP(true);
        setAmountFromRedirect(amount);
      }
    }

    // Assign to variables destructing URL's data
    parameters.forEach((item) => {
      const data = item.split('=');
      if (data[KEY] === 'r') {
        setUserIdParams(data[VALUE]);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (gpayToken || applepayToken) {
      setLoading(true);
      const tipHeightCalc = Math.round(summaryInvoice * 100);
      const methodType = method.charAt(0).toUpperCase();
      // Get location without parameters
      const urlToGPay = window.location.protocol + '//' + window.location.host + window.location.pathname;

      backendLib.registerPayment(tipHeightCalc, gpayToken || applepayToken, userIdParams, methodType, urlToGPay)
      .then((res: any) => {
        if (res.r) {
          window.location.replace(res.r);
        } else {
          setLoading(false);
          setTipHeightSection(false);
          setPaymentTYP(true);
        }
      })
      .catch((err: any) => {
        console.log('Error: ', err);
        setLoading(false);
        setErrorMsg(t('errMessage'));
        setError(true);
        selectMethod(undefined);
        setGpayToken(undefined);
        setApplepayToken(undefined);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applepayToken, gpayToken]);

  useEffect(() => {
    if (summaryInvoice) {
      const checkValue = Math.round(summaryInvoice.toString().replace(',', '.') * 100);
      if (checkValue > tipMaxValue) {
        setErrorMsg(`${t('errMessage_maxTip')} ${tipMaxValue / 100} zł`);
        setError(true);
      } else if (checkValue < tipMinValue) {
        setErrorMsg(`${t('errMessage_minTip')} ${tipMinValue / 100} zł`);
        setError(true);
      } else {
        setErrorMsg(t('errMessage'));
        setError(false);
      }

      // Validate of input price only if it's a string
      if (typeof summaryInvoice === 'string') {

        // Find the latest character passed in input
        const lastChar = summaryInvoice.charAt(summaryInvoice.length - 1);

        // Replace dot on comma '15.22' -> '15,22'
        setSummary(summaryInvoice.replace(/\./g, ','));

        // Check if character exist in allowed sign
        const existLastCharInAllowedSign = ALLOWED_SIGN.filter((char) => lastChar === char);
        const removeLastChar = summaryInvoice.substr(0, summaryInvoice.length - 1);

        const validateRestValue = () => {
          const rest = summaryInvoice.split(',')[1];
          if (rest && rest.length >= 3) {
            setSummary(removeLastChar);
          }
        };

        // If comma is existing two times, remove last one
        if (summaryInvoice.split(',').length - 1 >= 2) {
          setSummary(removeLastChar);
        }

        if (!existLastCharInAllowedSign.length || summaryInvoice.length === 8) {
          setSummary(removeLastChar);
        }

        // Allow to pass only 2 decimals rest value if price including rest
        if (summaryInvoice.includes(',') || (summaryInvoice.length === 5 && summaryInvoice.includes('.'))) {
          validateRestValue();
        } else if (summaryInvoice.length === 5 && !summaryInvoice.includes(',')) {
          setSummary(removeLastChar);
        }
      }
    }
  }, [summaryInvoice, t, tipMaxValue, tipMinValue]);

  const selectedPaymentMethod = (item: string) => {
    selectMethod(item);
    setBlikCode(null);
    setActivePaymentButton(item === 'blik' ? false : true);
    setErrorMsg(t('errMessage'));
    setError(false);
  };

  // Clear input value on Invoice Summary, when user remove price
  const clearInputPrice = () => setSummary('');

  // Dont display section "Tip Height", only 'approved' value is displaying content
  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    setTipHeightSection(false);
    setSummary(e.currentTarget.value);

    if (!e.currentTarget.value.length) {
      clearInputPrice();
    }
  };

  // Submit form with currentValue and display next step
  const handleSubmit = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();

    if (typeof summaryInvoice !== 'undefined' && summaryInvoice.length && summaryInvoice.match(amount)) {
      setTipHeightSection(true);
      setSummary(+summaryInvoice.replace(',', '.'));
      stopCheckingPrice(true);

      // Remove style from Invoice Summary input
      if (divRef.current) {
        divRef.current.removeAttribute('style');
      }

    } else {
      setTipHeightSection(false);
    }
  };

  // TODO: This function should be called only when Payment gateway success
  const walletAccept = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();

    if (blikCode && blikCode.toString().length === 6) {
      setLoadingBlik(true);
      const tipHeightCalc = Math.round(summaryInvoice * 100);
      const methodType = method.charAt(0).toUpperCase();

      if (iOSversion && iOSversion < 1350) {
        console.log('iOS version is less then 13.5.0');
        backendLib.registerPaymentAsync(tipHeightCalc, blikCode, userIdParams, methodType)
        .then((response: any) => {
          console.log('response', response);
          setBlikProcessFirstStep(true);
          const checkPayment = () => {
            firebase.database().ref(`/asyncQ/${response.i}`)
            .on('value', (snapshot: any) => {
              if (snapshot.val()) {
                console.log('snapshot.val()', snapshot.val());
                if (snapshot.val().d.s === 'A') {
                  setLoadingBlik(false);
                  setPaymentTYP(true);
                  setTipHeightSection(false);
                }
                if (snapshot.val().d.s === 'D') {
                  setLoadingBlik(false);
                  setErrorMsg(t('errMessage_blik'));
                  setError(true);
                  selectMethod(undefined);
                }
              }
            });
          };

          checkPayment();
        })
        .catch((err: any) => {
          console.log(`Error: ${err}`);
          setError(true);
          setErrorMsg(t('errMessage_blik'));
        });

      } else {
        setBlikProcessFirstStep(true);
        backendLib.registerPayment(tipHeightCalc, blikCode, userIdParams, methodType)
        .then(() => {
          setLoadingBlik(false);
          setPaymentTYP(true);
          setTipHeightSection(false);
        })
        .catch(() => {
          setLoadingBlik(false);
          setErrorMsg(t('errMessage_blik'));
          setError(true);
          selectMethod(undefined);
        });
      }
    }

    if (method === 'gpay') {
      setGpayClicked(true);
    }
  };

  const inputSummaryInvoiceHandler = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    if (!tipHeightSection) {
      e.currentTarget.style.border = '1px solid #2B0096';
      if (inputValue.current) {
        inputValue.current.focus();
      }
    }
  };

  const handleChangeBlik = (e: React.FormEvent<HTMLInputElement>) => {
    const clearValue = e.currentTarget.value.replace(/\s|_/g, '');

    if (clearValue.length === 6) {
      setBlikCode(clearValue);
      setActivePaymentButton(true);
    } else {
      setActivePaymentButton(false);
    }
  };

  const buttonSubmitTip = (active: boolean, type: string) => {
    if (!error && (active || type === 'gpay')) {
      return `${styles.tipCodeGive_paymentSectionButton__active}`;
    } else {
      return `${styles.tipCodeGive_paymentSectionButton__disabled}`;
    }
  };

  // Its remove 'active class' from summary invoice on Blur methods
  const activeClassHandler = (e: { stopPropagation: () => void; }) => {
    e.stopPropagation();
    if (divRef.current && divRef.current.getAttribute('style') !== null) { divRef.current.removeAttribute('style'); }
  };

  const paymentButtonElement = (item: string) => (
    <ButtonBase key={item} onClick={() => selectedPaymentMethod(item)}>
      <div className={`${styles.tipCodeGive_paymentItem} ${item === method && styles.tipCodeGive_paymentItem__selected}`}>
        <img
          src={require(`assets/${item}.svg`)}
          height="26"
          alt="Payment method button"
        />
      </div>
    </ButtonBase>
  );

  const checkIsApplePayIsAvailable = () => {
    if ((window as any).ApplePaySession) {
      const  promise = (window as any).ApplePaySession.canMakePaymentsWithActiveCard(process.env.REACT_APP_APPLEPAY_MERCHANT_ID);
      promise.then((canMakePayments: any) => {
        if (canMakePayments) {
          setApplePayIsAvailable(true);
        }
      })
      .catch((err: any) => console.log('Error:', err));
    }
  };

  const getOnValidateMerchantHandler = (session: any) => (event: any) => {
    backendLib.paymentAppleRequestPaymentSession(event.validationURL, window.location.hostname)
        .then((response: any) => {
            const sessionObject = response.data;
            console.log('Going to complete merchant validation with session', sessionObject);
            session.completeMerchantValidation(sessionObject);
        })
        .catch((err: any) => {
            console.error('Error requesting Payment Session', err);
            setError(true);
            session.abort();
        });
  };

  const getOnCancel = () => console.log('Payment cancelled.');

  const getOnPaymentAuthorized = (session: any) => {
    return (event: any) => {
        console.log('Payment authorized', event);
        session.completePayment((window as any).ApplePaySession.STATUS_SUCCESS);
        console.log('Apple event received', event.payment);
        setApplepayToken(event.payment);
    };
  };

  const handlePaymentButtonClicked = () => {
    const request = {
        countryCode: process.env.REACT_APP_COUNTRY_CODE,
        currencyCode: process.env.REACT_APP_CURRENCY_CODE,
        supportedNetworks: process.env.REACT_APP_APPLEPAY_SUPPORTED_NETWORKS!.split(','),
        merchantCapabilities: process.env.REACT_APP_APPLEPAY_MERCHANT_CAPABILITIES!.split(','),
        total: { label: process.env.REACT_APP_GATEWAY_MERCHANT_NAME, amount: summaryInvoice },
    };

    const session = new (window as any).ApplePaySession(3, request);
    session.onvalidatemerchant = getOnValidateMerchantHandler(session);
    session.onpaymentauthorized = getOnPaymentAuthorized(session);
    session.oncancel = getOnCancel();

    session.begin();
  };

  return (
    <div className={`${styles.tipCodeGive_wrapper}`} onClick={activeClassHandler}>
      <div style={{ display: !paymentTYP && !errorTransaction ? 'grid' : 'none' }} className={`container ${styles.tipCodeGive_container}`}>

        {/* Render intro page, when user was scanned QRCode */}
        <div className={`${styles.tipCodeGive_thankYou}`} style={{ display: introTYP ? 'grid' : 'none' }}>
          <ThankYou img="tipcode_give.svg" background="jam_jar_bg.svg">
            <div>
              <h2 className={`title ${styles.tipCodeGive_thankYou__title}`}>{t('hello')},</h2>
              <p className={`${styles.tipCodeGive_thankYou__text}`}>{t('tipCodeContentGive')}</p>
              <CssButton
                fullWidth
                className="card__activeRotate"
                size="large"
                variant="outlined"
                type="button"
                onClick={() => setIntroTYP(false)}
              >
                {t('giveTipButton')}
              </CssButton>
            </div>
          </ThankYou>
        </div>

        <div className={`${styles.tipCodeGive_thankYou}`} style={{ display: !introTYP ? 'grid' : 'none' }}>
          <h2 className="title title__small">{t('giveTip')}</h2>

          <form onSubmit={handleSubmit} className={`${styles.tipCodeGive_formTip}`}>
            <CssTextField
              name="invoiceAmount"
              label={t('invoice_amount')}
              placeholder={t('tipPlaceholder')}
              onChange={(event: React.FormEvent<HTMLElement | any>): void => { handleChange(event); }}
              fullWidth
              autoComplete="off"
              margin="normal"
              variant="outlined"
              disabled={tipHeightSection}
              InputLabelProps={{ shrink: true }}
              inputRef={inputValue}
              inputProps={{
                inputMode: iOS ? 'decimal' : 'tel',
                value: summaryInvoice || '',
                type: 'tel',
              }}
            />
          </form>

          <h1 className="title title__marginTitle mt-30">{t('valueOfTipHeight')}</h1>

          {/* This field is presenting current Summary Invoice with custom styles */}
          <PriceInput clicked={inputSummaryInvoiceHandler} tipHeight={summaryInvoice} checkingPrice={checkingPrice} reference={divRef} />
          {
            method === 'blik' &&
            <div className={styles.tipCodeGive_blikInfo}>
              <InlineNotification
                title={t('information')}
                type="info"
                msg={t('blik_info1')}
              />
            </div>
          }
          {/* Display Button when tip height is not confirm */}
          {
            !tipHeightSection && (
              <CssButton
                fullWidth
                size="large"
                variant="outlined"
                type="button"
                onClick={handleSubmit}
                className={buttonSubmitTip(summaryInvoice, method)}
                disabled={!summaryInvoice || tipHeightSection || error}
              >
                {t('entry')}
              </CssButton>
            )
          }
        </div>

        { tipHeightSection && (
          <form onSubmit={walletAccept} noValidate>
            <div className={`${styles.tipCodeGive_card}`}>
              <h1 className="title mt-30">{t('paymentType')}</h1>
              <p className={`${styles.tipCodeGive_subTitle} mt-0`}>{t('choosePaymentType')}</p>

              <div className={`${styles.tipCodeGive_cardBoxes}`}>
              {
                paymentMethods.map((item) => {
                  if (iOS && item === 'applepay' && applePayIsAvailable) {
                    return paymentButtonElement(item);
                  } else if (!iOS && item === 'gpay' && (window as any).PaymentRequest) {
                    return (
                      <GPButton
                        key={item}
                        method={method!}
                        select={selectMethod}
                        gpayClicked={gpayClicked}
                        setGpayClicked={setGpayClicked}
                        setGpayToken={setGpayToken}
                        totalPrice={summaryInvoice!}
                      />
                    );
                  } else if (item === 'blik') {
                    return paymentButtonElement(item);
                  }
                })
              }
              </div>

              {
                method === 'blik' && (
                  <div className={`${styles.tipCodeGive_blikCode} mt-30`}>
                    <h1 className="title title__smallBold">{t('writeBlikCode')}</h1>
                    <InputMask
                        mask="999 999"
                        onChange={handleChangeBlik}
                    >
                      {
                        () => (
                          <CssTextFieldBlik
                            fullWidth
                            ref={blikCodeRef}
                            autoComplete="off"
                            name="blikCode"
                            placeholder={t('blikCodePlaceholder')}
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            inputProps={{
                              inputMode: iOS ? 'decimal' : 'tel',
                              type: 'tel',
                            }}
                          />
                        )
                      }
                    </InputMask>
                  </div>
                )
              }

              {/* Button is active when payment method is blik and there is passed correct blik code or user selected gpay method */}
              {
                method && (
                  <CssButton
                    fullWidth
                    ref={paymentButton}
                    className={buttonSubmitTip(activePaymentButton, method)}
                    style={{margin: '-0.5rem 0 .9rem 0'}}
                    size="large"
                    variant="outlined"
                    type="submit"
                    disabled={method === 'blik' && !activePaymentButton}
                    onClick={method === 'applepay' ? handlePaymentButtonClicked : null}
                  >
                    {t('pay')}
                  </CssButton>
                )
              }
            </div>
          </form>
        ) }
        { /* Error message */
          error && <h1 className="title title__err">{errorMsg}</h1>
        }
      </div>

      {/* Render thankyou page, when payment was successed */}
      <div style={{ display: paymentTYP ? 'grid' : 'none' }}>
        <ThankYou img="jam_jar.svg" background="jam_jar_bg.svg" text={t('thankYouFortTIP')}>
          <div className={`${styles.tipCodeGive_paymentTYP}`}>
            {paymentTYP && <PriceStyling tip={amountFromRedirect || (summaryInvoice.toFixed(2))} />}
          </div>
        </ThankYou>
      </div>

      {/* Render error page transaction */}
      {
        errorTransaction && (
          <div style={{ display: errorTransaction ? 'grid' : 'none', paddingTop: '3rem' }}>
            <h1 className="title title__err" dangerouslySetInnerHTML={innerHTMLTranslation(t('errMessage_Payment'))} />
            <CssButton
              fullWidth
              className="card__activeRotate"
              style={{ width: 'calc(100% - (2.8rem * 2))', margin: '3rem auto', border: 'none' }}
              size="large"
              variant="outlined"
              onClick={() => setErrorTransaction(false)}
            >
              {t('pay')}
            </CssButton>
          </div>
        )
      }

      {loading && <Loading />}
      {loadingBlik && <LoadingBlik msg={blikProcessFirstStep ? t('blik_info2') : t('blik_info_first_step')} />}
    </div>
  );
};

export default TipCode;
