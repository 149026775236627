import React, { memo } from 'react';
import styles from './PageListItem.module.scss';
import { ReactComponent as IconInfo } from 'assets/icon_info.svg';
import { innerHTMLTranslation } from 'utils/utils';

interface Props {
  onClick?: any;  // callback that is triggered when item is clicked, item id is passed to the callback function
  txt1?: string;  // text to be displayed in first column
  txt2?: string;  // text to be displayed in second column
  txt3?: string;  // text to be displayed in third column
  icon?: string; // icon to be used for edit mode
  itemId?: any; // id of the item, will be returned when item is clicked
}

/**
 * Single row/item in list component.
 * When click callback is provided the item is also clickable
 * @param props.itemId   id of the item, will be returned when item is clicked
 * @param props.onClick   callback that is triggered when item is clicked, item id is passed to the callback function
 * @param props.txt1   text to be displayed in first column - when not provided two columns will be displayed only
 * @param props.txt2   text to be displayed in second column
 * @param props.txt3   text to be displayed in third column
 * @param props.icon   icon to be displayed in third column (appended after txt3)
 */
const PageListItem = (props: Props) => {
  const { txt1, txt2, txt3, icon, onClick, itemId } = props;
  
  const handleClick = () => {        
    if(onClick)
      onClick(itemId);    
  };  
  return (<>
    <tr className={styles.PageListItem_dateTableRow} onClick={handleClick}>
      {txt1 ? <><td className={styles.PageListItem_hourCellSingle}>{txt1}</td></> : null}
      <td>
        {txt2}
      </td>
      <td className={[styles.PageListItem_dateTableValue, styles.PageListItem_dateTableValue__newTip].join(' ')}>
        {txt3}{icon ? <i className="fas fa-edit"></i> : null}
      </td>
      
    </tr>                          
    </>
  );
};

export default PageListItem;
