import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EnvelopeImg } from 'assets/envelope.svg';
import styles from './Awaiting_typ.module.scss';

const AwaitingTyp = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className={`${styles.awaitingTyp_wrapper} background_gradient background_gradient__purpure`}>
        <div className={`container ${styles.awaitingTyp_container}`}>
          <EnvelopeImg className={styles.awaitingTyp_envelope} />
          <div className="title_log">
            {t('tip_was_send')}
          </div>
          <div className={styles.awaitingTyp_info}>
            {t('check_your_mailbox_and_receive_tip')}
          </div>
        </div>
      </div>
    </>
  );
};

export default AwaitingTyp;
