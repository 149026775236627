import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BackendApp } from 'libs/App';
import UserConsumer from 'context/user/User';

import styles from './Profile.module.scss';
import PaybackData from './payback_data/Payback_data';
import { Link, RouteComponentProps } from 'react-router-dom';
import AccountData from './account_data/Account_data';

// Initialize backend methods
const backendLib = BackendApp();

interface IContextProps {
  currentUser: {
    userId: string,
  };
}

const links = [
  { to: '/profile/account-data', name: 'account_data' },
  { to: '/profile/payback-data', name: 'payback_data' },
];

const Profile = (props: RouteComponentProps) => {
  const userConsumer = useContext<Partial<IContextProps>>(UserConsumer);
  const { currentUser: { userId } }: any = userConsumer;
  const { location } = props;
  const [warning, setWarning] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    backendLib.backendLoadUser(userId).then((userData: any) => {
      if (userData.v && userData.v.s) {
        if (userData.v.s === 'B' || userData.v.s === 'U') {
          setWarning(true);
        }
      } else {
        setWarning(true);
      }
    });
  }, [userId]);

  return (
    <div className={`${styles.profile_wrapper}`}>
      <div className={`container ${styles.profile_container}`}>
        <h1 className={`title ${styles.profile_title}`}>{t('profile.menu')}</h1>
        <div className={`${styles.profile_links}`}>
          {
            links.map(({to, name}, index) => (
              <div key={name}>
                <Link
                  to={to}
                  className={[styles.profile_linkItem, location.pathname === to ? `${styles.profile_linkItem__active}` : (location.pathname === '/profile' &&  !index) ? `${styles.profile_linkItem__active}` : `${styles.profile_linkItem__inactive}`].join(' ')}
                >
                  {t(name)}
                </Link>
              </div>
            ))
          }
          {warning ? (<span className={styles.profile_warning}>!</span>) : ''}
        </div>
        {location.pathname === '/profile/payback-data' ? <PaybackData /> : <AccountData {...props} />}
      </div>
    </div>
  );
};

export default Profile;
