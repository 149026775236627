import React, { useState, useEffect, useContext, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import UserConsumer from 'context/user/User';
import { useTranslation } from 'react-i18next';
import {
  amountCentsToString
} from 'utils/calculates';
import styles from './SettleTipboxComponent.module.scss';
import PageTitle from 'components/layout/pagetitle/PageTitle';
import PageList from 'components/layout/list/PageList';
import PageCta from 'components/layout/ctaBox/PageCta';
import Button from '@material-ui/core/Button';
import { ButtonStyle } from 'components/override_styles/Button';
import InfoBox from 'components/layout/infoBox/InfoBox';
import PriceStyling from 'components/priceStyling/PriceStyling';
import * as firebase from 'firebase/app';
import 'firebase/database';
import SpinnerSmall from 'components/layout/spinner/SpinnerSmall';
import ThankYou from 'components/thankYou/ThankYou';
import { ButtonCircleStyle } from 'components/override_styles/ButtonCircle';
import Fab from '@material-ui/core/Fab';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import { innerHTMLTranslation } from 'utils/utils';

import { BackendApp } from 'libs/App';

// Initialize backend methods
const backendLib = BackendApp();

// Overridden material styles
const CssButton = ButtonStyle(Button);
const CssButtonCircle = ButtonCircleStyle(Fab);

interface ContextProps {
  currentUser: {
    userId: string,
    as: string,
    tipBoxName: string,
    singleModule: boolean,
  };
}
/**
 * TipBox Settlement View. Allows tipbox owner to split tipbox money between participants.
 * @param props 
 */
const SettleTipboxComponent = (props: RouteComponentProps) => {
  const userConsumer = useContext<Partial<ContextProps>>(UserConsumer);
  const { currentUser: { userId, as, singleModule, tipBoxName } }: any = userConsumer;
  const { t } = useTranslation();
    
  const [balance, setBalance] = useState({value: 0, text: '0.00'});  // tipbox balance
  
  // items in settlement table, item structure: {key: user id, label: user description, 
  // amount: textual representation of amount, c5: cents representation of amount}
  const [items, setItems] = useState<any[]>([]); 

  const [isProcessing, setProcessing] = useState(true); // busy indicator when true
  const [showTyp, setShowTyp] = useState(false);  // thank you page presented when true
  const [isError, setError] = useState(false);  // error info presented when true
  
  
  // load balance in the tipbox
  useEffect(() => {
    var currentBalance = 0;
    firebase.database().ref(`/userShared/${userId}`).orderByChild('st').once('value', (snapshot: any) => {
      // load current active tipbox balance
      const resultObj: {} = {};
      if (snapshot.val()) {
        snapshot.forEach((child: any) => {
          Object.assign(resultObj, { [`${child.key}`]: child.val() });
        });
      }      
      Object.values(resultObj).forEach((el:any)=>{
        if(el.s === as){
          currentBalance = el.a
        }
      })   
      // make sure that we take active tipbox from potentially many user's tipboxes   
      Object.values(resultObj).map((el: any) => (el.s === as) && setBalance({value: el.a, text: amountCentsToString(el.a)}));
    })
    .then(()=>{
      // when balance is loaded load participants and assign equal amount to each participant (as this is
      // a default proposal)
      return backendLib.loadActiveSharedParticipants();
    })
    .then((participants: any)=>{
      var itemsArray:any[] = [];
      if(participants.length>0){
        // by default / on start each receives the same amount
        var participantAmount = Math.floor(currentBalance/participants.length);        
        participants.forEach((participant:any)=>{
          itemsArray.push({
            key: participant.i,
            label: participant.n,
            amount: ('- '+amountCentsToString(participantAmount)+' zł').replace(/\./g,','),
            c5: participantAmount
          })
        });
        console.log(itemsArray)
        setItems(itemsArray);
      }
      setProcessing(false);      
    })
  },[])
  
  /**
   * Helper method that recalculates how much is remaining given withdrawal split
   * entered by user. 
   * @param items Split table items
   */
  const calculateRemaining = (items:any) => {
    var sum = 0;
    items.forEach((item:any)=>{
      sum += Math.abs(item.c5)
    })

    var remainingValue = Math.abs(balance.value)-Math.abs(sum);
    console.log('Remaining', remainingValue);
    var remainingItem = {
      key: "k1",
      label: t('settle.remainingLabel'), 
      amount: remainingValue >= 0 ? ('+ '+amountCentsToString(remainingValue)+' zł') : (amountCentsToString(remainingValue)+' zł').replace(/\-/g,'- '),
      c5: remainingValue
    }
    remainingItem.amount = remainingItem.amount.replace(/\./g,',');
    return remainingItem;
  }
  /**
   * States that holds data for the remaining table/row
   */
  const [remaining, setRemaining] = useState([{
    key: "k1",
    label: t('settle.remainingLabel'), 
    amount: "+ 0,00 zł",
    c5: 0
  }]);

  /**
   * Fires when users edits table item amount. As a result new amount entered by user is provided and model is updated
   * accordingly
   * @param value Object holding previous value of item and the new value: {item: previous item record, value: new value entered by user}
   */
  const onClick = (value: any) => {
    // when there is no change (i.e. user clicked item in table but did not change its value)
    // we don't modify anything  
    if(value.value){
      var newItems = items.slice();
      var toBeUpdated:any = newItems.find((item:any)=>{
        return item.key == value.item.key;
      });
  
      if(toBeUpdated){        
        //TODO ahhhh crappy formatting , should be part of PageListItem internal implementation
        toBeUpdated.c5 = -value.value.value;
        toBeUpdated.amount = value.value.value >= 0 ? '- '+value.value.text.replace(/\s/g,'')+' zł' : (value.value.text.replace(/\s/g,'').replace(/\-/g,'- '))+' zł';
        if(!toBeUpdated.amount.startsWith('-'))            
          toBeUpdated.amount = '+ '+toBeUpdated.amount;
        toBeUpdated.amount = toBeUpdated.amount.replace(/\./g,',')
        setItems(newItems)  
        setRemaining([calculateRemaining(items)]);
      }
    }
  };

  /**
   * Fires when user has splitted the withdrawal between
   * participants.
   */
  const onSettle = () => {
    var withdrawalSpecification:any[] = [];
    var totalAmount = 0;
    // make withdrawal specification 
    items.forEach((item:any)=>{
      // key - userId
      // c5 - amount in cents
      withdrawalSpecification.push({
        u: item.key,  // userId
        a: Math.floor(Math.abs(item.c5)) // make sure that positive values are used
      })
      totalAmount += Math.floor(Math.abs(item.c5)); // make sure that positive values are used
    })

    console.log('Going to withdraw', totalAmount, items, withdrawalSpecification);
    setProcessing(true);
    backendLib.registerWithdrawal(totalAmount, as, withdrawalSpecification).then(() => {
      setProcessing(false);
      setShowTyp(true);
    }).catch(() => {
      setProcessing(false);
      setError(true);
    });    
  };


  return (
    <div className={`${styles.settle_wrapper}`}>

      {showTyp ? <> 
      <div className={`${styles.settle_thankYou}`}>
        <ThankYou
          img="withdraw_balance.svg"
          background="jam_jar_bg.svg"
          text={t('settle.thankYouTitle')}
        >
          <p className={`${styles.settle_thankYouAddText}`}>
            <span dangerouslySetInnerHTML={innerHTMLTranslation(t('settle.thankYou'))}/>
          </p>
          <Link to={'/welcome'}>
            <CssButtonCircle
              color="primary"
              aria-label="add"
              style={{ position: 'absolute', bottom: '3rem', right: '3rem' }}
            >
              <AddIcon style={{ fontSize: '3.5rem' }} />
            </CssButtonCircle>
          </Link>
        </ThankYou>
      </div>
      </> : null}

      {showTyp ? null :
      <>
      <div className="container">
        <PageTitle title={t('settle.title')} subTitle={t('settle.subTitle')} subTitleExtra={tipBoxName}/>
        
        { isProcessing? 
        <SpinnerSmall /> :
        <> 
        <div className={`${styles.settle_tip} mt-15`}>
          <PriceStyling
            tip={balance.text}
            style={{
              color: '#AA4399',
              integer: 4,
              cents: 3.2,
              currency: 2.5,
            }}
          />
        </div>
        
        
        <PageList title={t('settle.listTitle')} items={items} itemOnChange={onClick}/>
        <PageList title={t('settle.remainingTitle')} items={remaining} />
        {isError ? (<h1 className="title title__err">{t('errMessage')}</h1>) : null }
        <PageCta>
            <CssButton
              fullWidth              
              className={`${remaining[0].c5<0 ? 'card__disabled' : 'card__activeRotate'}`}
              size="large"
              variant="outlined"
              type="button" 
              disabled={remaining[0].c5<0}         
              onClick={onSettle}>
              {t('settle.go')}
            </CssButton>
            
              {remaining[0].c5<0 ? <> 
              <InfoBox htmlText={t('settle.error1')} />
              <InfoBox htmlText={t('settle.error2')} />
              </> : <><InfoBox htmlText={t('settle.info1')} />
              <InfoBox htmlText={t('settle.info2')} /></>}
              
            
        </PageCta>                 
        </>}

        
      </div>
      </>}
      
    </div>
  );
};

export default SettleTipboxComponent;
