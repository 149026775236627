import React from 'react';
import styles from './PageTitle.module.scss';

interface Props {
  title?: string;
  subTitle?: string;
  subTitleExtra?: string;
}

const PageTitle = (props: Props) => {
  const { title, subTitle, subTitleExtra } = props;
  
  return (
        <div>
            <h1 className={`title ${styles.PageTitle_title}`}>{title}</h1>
            <p className={`${styles.PageTitle_subTitle}`}>            
            {subTitleExtra ? <>{subTitle}&nbsp;<strong>{subTitleExtra}</strong> </>: <>{subTitle}</>}
            </p>
        </div>        
  );
};

export default PageTitle;
