import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { BackendApp } from 'libs/App';

import { useTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import MailOutline from '@material-ui/icons/MailOutline';
import { InputFieldStyle } from 'components/override_styles/TextField';
import { ButtonStyle } from 'components/override_styles/Button';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import { innerHTMLTranslation } from 'utils/utils';
import InfoBox from 'components/layout/infoBox/InfoBox'

import styles from './Reset_password.module.scss';
import Loading from 'components/loading/Loading';

// Initialize backend methods
const backendLib = BackendApp();

// Overridden material styles
const CssTextField = InputFieldStyle(TextField);
const CssButton = ButtonStyle(Button);

const CssArrowBackIos = withStyles({
  root: {
    fontSize: '1.2rem',
    verticalAlign: 'middle',
  },
})(ArrowBackIos);

const ResetPassword = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [loader, setLoader] = useState(false);
  const [resetError, setResetError] = useState(false);

  const history = useHistory();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setEmail(event.target.value);
  };

  const handleResetPassword = () => {
    setLoader(true);

    backendLib._callAPI('userRequestPasswordReset', { email })
    .then(() => {
      // Email sent.
      console.log('Reset password requested');
      history.push('/reset-password-finish');
    })
    .catch((error: any) => {
      // An error happened.
      setResetError(true);
      setLoader(false);
      console.log('Error:', error);
    });
  };

  return (
    <>
      <div className={`${styles.reset_wrapper} background_gradient background_gradient__purpure`}>
        <form className={`container ${styles.reset_form}`}>
          <div className="title_log">
            {t('reset_password_title')}
          </div>
          <div className={styles.reset_info_left} dangerouslySetInnerHTML={innerHTMLTranslation(t('reset_password_help'))}>            
          </div>
          <div className={styles.reset_info_email}>
            <a className={styles.reset_info_email_ling}href="mailto:info@ofiara.org">{t('reset_password_info_mail')}</a>
          </div>
          <div className={styles.reset_info_left} >   
            <InfoBox htmlText={t('reset_password_info_extra1')}></InfoBox>                     
          </div>
          <div className={styles.reset_info_left} >
            <InfoBox htmlText={t('reset_password_info_extra2')}></InfoBox>         
          </div>
          {/* <CssTextField
            fullWidth
            autoComplete="off"
            name="reset_password"
            label={t('email')}
            placeholder={t('email_placeholder')}
            helperText={resetError ? t('wrong_data') : ''}
            margin="normal"
            variant="outlined"
            type="email"
            InputLabelProps={{shrink: true}}
            InputProps={{startAdornment: (<InputAdornment position="start"><MailOutline /></InputAdornment>)}}
            onChange={handleChange}
            error={resetError}
          /> */}
          {/* <CssButton
            fullWidth
            size="large"
            variant="outlined"
            type="button"
            onClick={handleResetPassword}
          >
            {t('reset_password')}
          </CssButton> */}
          <Link className={styles.reset_loginLink} to="/login">
            <CssArrowBackIos />{t('login_title')}
          </Link>
        </form>
        {loader && <Loading />}
      </div>
    </>
  );
};

export default ResetPassword;
