import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './User_mgmt.module.scss';
import { innerHTMLTranslation } from 'utils/utils';
import ConfirmEmail from './confirm_email/Confirm_email';
import ResetPasswordFirebase from './reset_password_firebase/Reset_password_firebase';

interface Props {
  history: {
    push: (path: string) => void,
  };
}

const UserMgmt = (props: Props) => {
  const { t } = useTranslation();

  const url = new URL(window.location.href);
  const mode = url.searchParams.get('mode') || '';

  return (
    <>
      {mode === 'verifyEmail' && <ConfirmEmail {...props} />}
      {mode === 'resetPassword' && <ResetPasswordFirebase {...props} />}
      {mode !== 'verifyEmail' && mode !== 'resetPassword' && (
        <div className={`${styles.userMgmt_wrapper} background_gradient background_gradient__purpure`}>
          <div className="container">
            <div
              className={styles.userMgmt_info}
              dangerouslySetInnerHTML={innerHTMLTranslation(t('no_parameters'))}
            />
          </div>
        </div >
      )}
    </>
  );
};

export default UserMgmt;
