import React, { useState, useEffect, useRef } from 'react';
import * as firebase from 'firebase/app';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { ButtonStyle } from 'components/override_styles/Button';
import styles from './Confirm_email.module.scss';
import Loading from 'components/loading/Loading';
import { innerHTMLTranslation } from 'utils/utils';
import TextField from '@material-ui/core/TextField';
import { InputFieldStyle } from 'components/override_styles/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import MailOutline from '@material-ui/icons/MailOutline';
import LockOutlined from '@material-ui/icons/LockOutlined';
import InfoBox from 'components/layout/infoBox/InfoBox';
import { BackendApp } from 'libs/App';

const backendLib = BackendApp();

// Overridden material styles
const CssButton = ButtonStyle(Button);
const CssTextField = InputFieldStyle(TextField);

interface Props {
  history: {
    push: (path: string) => void,
  };
  email?:string;
  code?:string;
}

const ConfirmEmail = (props: Props) => {
  const { t } = useTranslation();
  const { history, email, code } = props;
  const [loading, setLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [activateEmail, setActivateEmail] = useState<string>(email || '');
  const [activateCode, setActivateCode] = useState<string>(code || '');
  const [isValid, setIsValid] = useState<boolean>((email||'').includes('@')&&(code||'').length==6);
  const [activated, setActivated] = useState<boolean>(false);

  const url = new URL(window.location.href);
  const mode = url.searchParams.get('mode') || '';
  const oobCode = url.searchParams.get('oobCode') || '';
  const scrollToRef = useRef<HTMLDivElement>(null);
  
  const handleLogin = () => {
    history.push('/login');
  };

  // Scroll view when keyboard appear
  const scrollToButton = () => {
    const scrollAmount = 130;
    // Add a little timeout. We need this because keyboard has a little animation on appear.
    setTimeout(() => {
      // Scroll only when button is not fully visible
      if (scrollToRef.current && scrollToRef.current.scrollTop < scrollAmount) {
        scrollToRef.current.scrollTo({
          top: scrollAmount,
          left: 0,
          behavior: 'smooth',
        });
      }
    }, 500);
  };
  const handleActivate = (event: React.FormEvent<EventTarget>) => {
    event.preventDefault();
    setLoading(true);
    return backendLib.userActivate(activateEmail, activateCode).then(()=>{
      setLoading(false);
      setActivated(true);
    })
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    
    var isValid = true;        
    var field = event.target.name;
    if(field=='email'){
      isValid = isValid && event.target.value.includes('@') && activateCode.length == 6;
      setActivateEmail(event.target.value);    
    }else{
      isValid = isValid && activateEmail.includes('@') && event.target.value.length == 6;
      setActivateCode(event.target.value)
    }   
    setIsValid(isValid); 
  };

  return (
    <div className={`${styles.confirmEmail_wrapper} background_gradient background_gradient__purpure`}>
      <form className={`container ${styles.signUp_form}`} onSubmit={handleActivate}>
        <div className="title_log">
          {activated ? t('activate.titleSuccess') : t('activate.title') }
        </div>
        {activated ? <><InfoBox htmlText={t('activate.messageSuccess')} noIcon={true}/>
        <CssButton
        fullWidth
        size="large"
        variant="outlined"
        type="button"
        onClick={handleLogin}
      >
        {t('activate.login')}
      </CssButton></>
         : 
        <>                  
          {email ? 
          null
          : 
        <CssTextField
          fullWidth
          autoComplete="off"
          name="email"
          label={t('activate.email')}
          placeholder={t('activate.emailPlaceholder')}
          // helperText={emailError ? t('error_email') : ''}
          margin="normal"
          variant="outlined"
          value={activateEmail}
          type="email"
          InputLabelProps={{shrink: true}}
          InputProps={{startAdornment: (<InputAdornment position="start"><MailOutline /></InputAdornment>)}}
          onChange={handleChange}
          // error={emailError}
          onFocus={scrollToButton}
        />
        }
        <CssTextField
            fullWidth
            autoComplete="off"
            name="code"
            label={t('activate.code')}
            placeholder={t('activate.codePlaceholder')}
            // helperText={passwordError ? t('error_password') : ''}
            margin="normal"
            variant="outlined"
            value={activateCode}
            type="input"
            InputLabelProps={{shrink: true}}
            InputProps={{startAdornment: (<InputAdornment position="start"><LockOutlined /></InputAdornment>)}}
            onChange={handleChange}
            // error={passwordError}
            onFocus={scrollToButton}
        />
        <CssButton
          fullWidth
          size="large"
          variant="outlined"
          type="submit"  
          disabled={!isValid}>
          {t('activate.proceed')}
        </CssButton>
        <InfoBox htmlText={t('activate.message')} />
        <InfoBox htmlText={t('activate.message2')} />
        </>
      }
      </form>     
      {loading && <Loading />} 
    </div >
  );
};

export default ConfirmEmail;
