import React, { useState, useEffect, useRef, useContext, useLayoutEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import UserConsumer from 'context/user/User';
import { useTranslation } from 'react-i18next';
import { scrollTo } from 'utils/calculates';
import { BackendApp } from 'libs/App';
import styles from './Give.module.scss';
import TextField from '@material-ui/core/TextField';
import { InputFieldBlikStyle } from 'components/override_styles/TextFieldBlik';
import InputMask from 'react-input-mask';
import PriceStyling from 'components/priceStyling/PriceStyling';

import ButtonBase from '@material-ui/core/ButtonBase';
import ThankYou from 'components/thankYou/ThankYou';

import Button from '@material-ui/core/Button';
import { ButtonStyle } from 'components/override_styles/Button';
import Loading from 'components/loading/Loading';
import GPButton from 'components/gpay/GPay';
import { innerHTMLTranslation, removeParam, checkVersion } from 'utils/utils';
import firebase from 'firebase/app';
import InlineNotification from 'components/inline_notification/InlineNotification';
import LoadingBlik from 'components/loading_blik/LoadingBlik';

// Initialize backend methods
const backendLib = BackendApp();

// Overridden material styles
const CssTextFieldBlik = InputFieldBlikStyle(TextField);
const CssButton = ButtonStyle(Button);

const iOSversion = checkVersion();

const paymentMethods = ['blik', 'gpay', 'applepay'];
const KEY = 0;
const VALUE = 1;

interface ContextProps {
  currentUser: {
    iOS: boolean,
  };
}

const Give = (props: RouteComponentProps) => {
  const userConsumer = useContext<Partial<ContextProps>>(UserConsumer);
  const { currentUser: { iOS } }: any = userConsumer;
  const { t } = useTranslation();
  const [method, selectMethod] = useState();
  const [blikCode, setBlikCode] = useState();
  const [activePaymentButton, setActivePaymentButton] = useState(false);

  // Naming for decoding URL parameters
  const [tipPrice, setTipPrice] = useState('0');
  const [userID, setUserId] = useState();
  const [name, setName] = useState();
  const [ff, setFlag] = useState();

  const [loading, setLoading] = useState(false);
  const [loadingBlik, setLoadingBlik] = useState(false);
  const [error, setError] = useState(false);
  const [thankYouPage, setThankYouPage] = useState(false);
  const [errorPage, setErrorPage] = useState(false);
  const [applePayIsAvailable, setApplePayIsAvailable] = useState(false);

  // Reference to DOM object
  const blikCodeRef = useRef<HTMLInputElement>(null);
  const paymentButton = useRef<HTMLButtonElement>(null);

  const [gpayClicked, setGpayClicked] = useState(false);
  const [gpayToken, setGpayToken] = useState();
  const [applepayToken, setApplepayToken] = useState();
  const [blikProcessFirstStep, setBlikProcessFirstStep] = useState(false);

  useLayoutEffect(() => {
    if (paymentButton.current) {
      scrollTo(paymentButton.current.offsetTop);
    }
  }, [activePaymentButton]);

  useEffect(() => {
    checkIsApplePayIsAvailable();

    const parameters = props.location.search.replace('?', '').split('&');

    if (window.document.referrer && window.document.referrer.includes('cashierui')) {
      const statusOfTransaction = new URLSearchParams(window.document.referrer).get('status');

      if (statusOfTransaction === 'ERROR') { setErrorPage(true); }
      if (statusOfTransaction === 'SUCCESS') { setThankYouPage(true); }
    }

    // Assign to variables destructing URL's data
    parameters.forEach((item) => {
      const data = item.split('=');

      switch (data[KEY]) {
        case 'a':
          setTipPrice(data[VALUE]);
          break;
        case 'r':
          setUserId(data[VALUE]);
          break;
        case 'n':
          setName(data[VALUE]);
          break;
        case 'f':
          setFlag(data[VALUE]);
          break;
        default:
          return;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Clear blikCode value, when payment method will be changed
  useEffect(() => {
    if (blikCodeRef.current) {
      blikCodeRef.current.value = '';
    }
  }, [method]);

  const selectedPaymentMethod = (item: string) => {
    if (blikCodeRef.current) {
      blikCodeRef.current.value = '';
    }
    selectMethod(item);
    setBlikCode(null);
    setActivePaymentButton(false);
    setError(false);
  };

  useEffect(() => {
    if (gpayToken || applepayToken) {
      setLoading(true);
      const tipHeightCalc = Math.round(+tipPrice * 100);
      const methodType = method.charAt(0).toUpperCase();

      const urlToGPay = removeParam('i', window.location.href);

      backendLib.registerPayment(tipHeightCalc, gpayToken || applepayToken, userID, methodType, urlToGPay)
        .then((res: any) => {
          if (res.r) {
            window.location.replace(res.r);
          } else {
            setThankYouPage(true);
            setLoading(false);
          }
        })
        .catch((err: any) => {
          console.log('Error: ', err);
          setLoading(false);
          setError(true);
          selectMethod(undefined);
          setGpayToken(undefined);
          setApplepayToken(undefined);
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gpayToken, applepayToken]);

  const handleChangeBlik = (e: React.FormEvent<HTMLInputElement>) => {
    const clearValue = e.currentTarget.value.replace(/\s|_/g, '');

    if (clearValue.length === 6) {
      setBlikCode(clearValue);
      setActivePaymentButton(true);
    } else {
      setActivePaymentButton(false);
    }
  };

  const walletAccept = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();

    if (blikCode && blikCode.toString().length === 6) {
      setLoadingBlik(true);
      const amount = Math.round(+tipPrice * 100);
      const methodType = method.charAt(0).toUpperCase();

      if (iOSversion && iOSversion < 1350) {
        console.log('iOS version is less then 13.5.0');
        backendLib.registerPaymentAsync(amount, blikCode, userID, methodType)
        .then((response: any) => {
          console.log('response', response);
          setBlikProcessFirstStep(true);
          const checkPayment = () => {
            firebase.database().ref(`/asyncQ/${response.i}`)
            .on('value', (snapshot: any) => {
              if (snapshot.val()) {
                console.log('snapshot.val()', snapshot.val());
                if (snapshot.val().d.s === 'A') {
                  setLoadingBlik(false);
                  setThankYouPage(true);
                }
                if (snapshot.val().d.s === 'D') {
                  setLoadingBlik(false);
                  setError(true);
                  selectMethod(undefined);
                }
              }
            });
          };

          checkPayment();
        })
        .catch((err: any) => {
          console.log(`Error: ${err}`);
          setError(true);
        });

      } else {
        setBlikProcessFirstStep(true);
        backendLib.registerPayment(amount, blikCode, userID, methodType)
        .then(() => {
          setLoadingBlik(false);
          setThankYouPage(true);
        })
        .catch(() => {
          setLoadingBlik(false);
          setError(true);
          selectMethod(null);
        });
      }
    }

    if (method === 'gpay') {
      setGpayClicked(true);
    }
  };

  const paymentButtonElement = (item: string) => (
    <ButtonBase
      key={item}
      onClick={() => selectedPaymentMethod(item)}
    >
      <div className={`${styles.give_paymentItem} ${item === method && styles.give_paymentItem__selected}`}>
        <img
          src={require(`assets/${item}.svg`)}
          height="26"
          alt="Payment method button"
        />
      </div>
    </ButtonBase>
  );

  const checkIsApplePayIsAvailable = () => {
    if ((window as any).ApplePaySession) {
      const  promise = (window as any).ApplePaySession.canMakePaymentsWithActiveCard(process.env.REACT_APP_APPLEPAY_MERCHANT_ID);
      promise.then((canMakePayments: any) => {
        if (canMakePayments) {
          setApplePayIsAvailable(true);
        }
      })
      .catch((err: any) => console.log('Error:', err));
    }
  };

  const getOnValidateMerchantHandler = (session: any) => (event: any) => {
    backendLib.paymentAppleRequestPaymentSession(event.validationURL, window.location.hostname)
        .then((response: any) => {
            const sessionObject = response.data;
            console.log('Going to complete merchant validation with session', sessionObject);
            session.completeMerchantValidation(sessionObject);
        })
        .catch((err: any) => {
            console.error('Error requesting Payment Session', err);
            setError(true);
            session.abort();
        });
  };

  const getOnCancel = () => console.log('Payment cancelled.');

  const getOnPaymentAuthorized = (session: any) => {
    return (event: any) => {
        console.log('Payment authorized', event);
        session.completePayment((window as any).ApplePaySession.STATUS_SUCCESS);
        console.log('Apple event received', event.payment);
        setApplepayToken(event.payment);
    };
  };

  const handlePaymentButtonClicked = () => {
    const request = {
        countryCode: process.env.REACT_APP_COUNTRY_CODE,
        currencyCode: process.env.REACT_APP_CURRENCY_CODE,
        supportedNetworks: process.env.REACT_APP_APPLEPAY_SUPPORTED_NETWORKS!.split(','),
        merchantCapabilities: process.env.REACT_APP_APPLEPAY_MERCHANT_CAPABILITIES!.split(','),
        total: { label: process.env.REACT_APP_GATEWAY_MERCHANT_NAME, amount: tipPrice },
    };

    const session = new (window as any).ApplePaySession(3, request);
    session.onvalidatemerchant = getOnValidateMerchantHandler(session);
    session.onpaymentauthorized = getOnPaymentAuthorized(session);
    session.oncancel = getOnCancel();

    session.begin();
  };

  return (
    <div className={`${styles.give_wrapper}`}>

      {/* Render thankyou page, when payment was successed */}
      {
        thankYouPage && (
          <div style={{ display: thankYouPage ? 'grid' : 'none' }}>
            <ThankYou img="jam_jar.svg" background="jam_jar_bg.svg" text={t('thankYouFortTIP')}>
              <div className={`${styles.give_thankYouPage}`}>
                <PriceStyling tip={tipPrice} />
              </div>
            </ThankYou>
          </div>
        )
      }

      {/* Render error page */}
      {
        errorPage && (
          <div style={{ display: errorPage ? 'grid' : 'none', paddingTop: '3rem' }}>
            <h1 className="title title__err" dangerouslySetInnerHTML={innerHTMLTranslation(t('errMessage_Payment'))} />
            <CssButton
              fullWidth
              className={`${styles.give_paymentSectionButton__active}`}
              style={{ width: 'calc(100% - (2.8rem * 2))', margin: '3rem auto' }}
              size="large"
              variant="outlined"
              onClick={() => setErrorPage(false)}
            >
              {t('pay')}
            </CssButton>
          </div>
        )
      }

      <div className="container" style={{ display: !thankYouPage && !errorPage ? 'grid' : 'none' }}>
      <h2 className={`title title__small`}>{t('giveTip')}</h2>

        {/* Customizing style for tip height */}
        <div className={`${styles.give_tip}`}>
          <h1 className={`title`}>{t('tip_height')}</h1>
          <PriceStyling tip={tipPrice} />
        </div>
        {
          method === 'blik' &&
          <div className={styles.give_blikInfo}>
            <InlineNotification
              title={t('information')}
              type="info"
              msg={t('blik_info1')}
            />
          </div>
        }
        {/* Select payment method */}
        <div>
          <h1 className="title mt-30">{t('paymentType')}</h1>
          <p className={`${styles.give_subTitle}`}>{t('choosePaymentType')}</p>

          <div className={`${styles.give_payment}`}>
            {
              paymentMethods.map((item) => {
                if (iOS && item === 'applepay' && applePayIsAvailable) {
                  return paymentButtonElement(item);
                } else if (!iOS && item === 'gpay' && (window as any).PaymentRequest) {
                  return (
                    <GPButton
                      key={item}
                      method={method!}
                      select={selectMethod}
                      gpayClicked={gpayClicked}
                      setGpayClicked={setGpayClicked}
                      setGpayToken={setGpayToken}
                      totalPrice={tipPrice}
                    />
                  );
                } else if (item === 'blik') {
                  return paymentButtonElement(item);
                }
              })
            }
          </div>

          {
            (method === 'blik' || !error) ? (
              <form onSubmit={walletAccept} noValidate>
                <div style={{ display: method === 'blik' ? 'grid' : 'none' }}>
                  <div className={`${styles.give_blikCode} mt-30`}>
                    <h1 className="title title__smallBold">{t('writeBlikCode')}</h1>
                    <InputMask
                      mask="999 999"
                      onChange={handleChangeBlik}
                    >
                      {
                        () => (
                          <CssTextFieldBlik
                            fullWidth
                            autoComplete="off"
                            name="blikCode"
                            placeholder={t('blikCodePlaceholder')}
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            inputProps={{
                              inputMode: iOS ? 'decimal' : 'tel',
                              type: 'tel',
                            }}
                          />
                        )
                      }
                    </InputMask>
                  </div>

                  <CssButton
                    fullWidth
                    ref={paymentButton}
                    className={`${activePaymentButton ? styles.give_paymentSectionButton__active : styles.give_paymentSectionButton__disabled}`}
                    size="large"
                    variant="outlined"
                    type="submit"
                    disabled={!activePaymentButton}
                  >
                    {t('pay')}
                  </CssButton>
                </div>
              </form>
            ) : <h1 className="title title__err">{t('errMessage_blik')}</h1>
          }
          {
            (method === 'gpay' || method === 'applepay') && (
              <form onSubmit={walletAccept} noValidate>
                <CssButton
                  fullWidth
                  ref={paymentButton}
                  className={styles.give_paymentSectionButton__active}
                  style={{margin: '4.5rem 0 .9rem 0'}}
                  size="large"
                  variant="outlined"
                  type="submit"
                  onClick={method === 'applepay' ? handlePaymentButtonClicked : null}
                >
                  {t('pay')}
                </CssButton>
              </form>
            )
          }
        </div>

      </div>
      {loading && <Loading />}
      {loadingBlik && <LoadingBlik msg={blikProcessFirstStep ? t('blik_info2') : t('blik_info_first_step')} />}
    </div>
  );
};

export default Give;
